import { Cancel, Delete } from "@mui/icons-material";
import { Avatar, Box, Button, CircularProgress, Divider, IconButton, TextField, ThemeProvider, Typography } from "@mui/material";
import { addDoc, collection, deleteDoc, doc, onSnapshot, query, serverTimestamp, where } from "firebase/firestore";
import { getStorage, ref, uploadBytes } from "firebase/storage";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { EventContext } from "../../../contexts/EventContextProvider";
import { useAuth } from "../../../contexts/UserContext";
import { privateLog } from "../../../dev/PrivateConsole";
import Dialog from "../../../dialog/Dialog";
import { db, getStorageURL } from "../../../firebase";
import AddButton from "../../../shared/AddButton";
import { CommentPost } from "../../../types/bll";

const Comments = () => {
    const { brightTheme, darkTheme, event } = useContext(EventContext);

    const [isUploading, setIsUploading] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [selectedFile, setSelectedFile] = useState<string | null>(null);
    const [comment, setComment] = useState<string>("");
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const userContext = useAuth();
    const [comments, setComments] = useState<CommentPost[]>([]);

    useEffect(() => {
        if (!isUploading && event) {
            const commentsQuery = query(collection(db, "events", event.id, "comments"), where("eventId", "==", event.id));
            const unsubscribe = onSnapshot(commentsQuery, async (snapshot) => {
                let newComments: CommentPost[] = snapshot.docs.map(
                    (doc) =>
                        ({
                            id: doc.id,
                            ...doc.data(),
                        } as CommentPost)
                );

                newComments = await Promise.all(
                    newComments.map(async (comment) => {
                        if (comment.path) {
                            comment.path = await getStorageURL(comment.path);
                        }
                        return comment;
                    })
                );

                setComments(newComments);
            });

            return unsubscribe;
        }
    }, [isUploading, event]);

    const handleDelete = async (commentId: string) => {
        if (event) {
            const commentRef = doc(db, "events", event.id, "comments", commentId);
            await deleteDoc(commentRef);
        }
    };

    const handleUpload = async () => {
        if (userContext?.user?.uid && event) {
            const uploadFile = async (path: string): Promise<string | undefined> => {
                privateLog("log", "upload started");
                const storage = getStorage();
                // Create a reference to the file in Firebase Storage
                const uuid = uuidv4();
                const filePath = `${event.id}/${uuid}`;
                const fileRef = ref(storage, filePath);
                // Fetch the blob from the URL
                const response = await fetch(path);
                const blob = await response.blob();
                // Upload the blob
                const uploadResult = await uploadBytes(fileRef, blob);
                if (!uploadResult) {
                    privateLog("error", "could not upload video at path: ", path);
                    return;
                }
                privateLog("log", "upload file finished", uploadResult.ref.fullPath);
                return uploadResult.ref.fullPath;
            };
            setProcessing(true);

            let uploadedAt = serverTimestamp();
            let commentPost: any = {
                uploadedAt: uploadedAt,
                userId: userContext.user.uid,
                eventId: event.id,
                text: comment,
                ...(selectedFile !== null && { path: await uploadFile(selectedFile) }),
            };

            privateLog("log", "uploading", commentPost);

            const postsCollectionRef = collection(db, "events", event.id, "comments");
            const docRef = await addDoc(postsCollectionRef, commentPost).catch((e) => {
                privateLog("erorr", "Error: ", e.message);
                setError(e);
                return null;
            });
            if (docRef) {
                privateLog("debug", "uploaded post successful", docRef?.id);
                setProcessing(false);
                setIsUploading(false);
            } else {
                privateLog("error", "could not upload");
                setIsUploading(false);
            }
        }
    };

    return (
        <ThemeProvider theme={brightTheme}>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
                <ThemeProvider theme={darkTheme}>
                    {userContext.backstagePassVerified && (
                        <AddButton width={40} height={40} onClick={() => setIsUploading(true)} text={t("make a comment")}></AddButton>
                    )}
                </ThemeProvider>
                {isUploading ? (
                    <Dialog sx={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
                        <ThemeProvider theme={brightTheme}>
                            <Box
                                flexDirection="column"
                                position="relative"
                                sx={{
                                    display: "flex",
                                    height: "100%",
                                    flex: 1,
                                    width: "100%",
                                    p: 2,
                                    pt: 0,
                                    zIndex: 4,
                                }}
                            >
                                <Box
                                    sx={{
                                        position: "sticky",
                                        top: 0,
                                        pt: 2,
                                        width: "100%",
                                        backgroundColor: "background.default",
                                        zIndex: 5,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            width: "100%",
                                            flex: 1,
                                        }}
                                    >
                                        <Box></Box>
                                        <Typography variant="h2" sx={{ alignSelf: "center" }}>
                                            Upload Content
                                        </Typography>
                                        <IconButton onClick={() => setIsUploading(false)}>
                                            <Cancel color="primary"></Cancel>
                                        </IconButton>
                                    </Box>
                                    <Divider sx={{ backgroundColor: "primary.main", mt: 1, mb: 1 }}></Divider>
                                </Box>

                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        width: "100%",
                                        flex: 1,
                                        gap: 2,
                                    }}
                                >
                                    <Box>
                                        <input
                                            type="file"
                                            accept="image/*,video/*"
                                            style={{ display: "none" }}
                                            ref={fileInputRef}
                                            onChange={(event) => {
                                                const file = event.target.files?.[0];
                                                if (file) {
                                                    const url = URL.createObjectURL(file);
                                                    setSelectedFile(url);
                                                }
                                            }}
                                        />
                                        <Button color="primary" onClick={() => fileInputRef.current?.click()} variant="outlined">
                                            Select Image or Video
                                        </Button>
                                    </Box>
                                    {selectedFile && (
                                        <Box position={"relative"}>
                                            <img src={selectedFile} alt="Selected" style={{ width: "auto", height: "40vh" }} />
                                            <IconButton
                                                onClick={() => {
                                                    URL.revokeObjectURL(selectedFile);
                                                    setSelectedFile(null);
                                                }}
                                                style={{ position: "absolute", top: 0, right: 0 }}
                                            >
                                                <Delete />
                                            </IconButton>
                                        </Box>
                                    )}
                                    <TextField value={comment} fullWidth label={"Text"} onChange={(e) => setComment(e.target.value)}></TextField>
                                    <Button sx={{ mt: "auto" }} variant="contained" fullWidth onClick={handleUpload}>
                                        {processing ? <CircularProgress></CircularProgress> : "save"}
                                    </Button>
                                    <Typography color={"red"} variant="body2">
                                        {error}
                                    </Typography>
                                </Box>
                            </Box>
                        </ThemeProvider>
                    </Dialog>
                ) : (
                    <Box gap={2} sx={{ display: "flex", flexDirection: "column" }}>
                        {comments.map((comment, key) => (
                            <Box
                                key={key}
                                sx={{
                                    backgroundColor: "background.default",
                                    borderRadius: 2,
                                }}
                            >
                                {comment.path && (
                                    <Box>
                                        {comment.path.endsWith(".mp4") ? (
                                            <video controls src={comment.path} style={{ width: "100%" }} />
                                        ) : (
                                            <img src={comment.path} alt="Comment" style={{ width: "100%" }} />
                                        )}
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        p: 1,
                                    }}
                                >
                                    <Avatar sx={{ mr: 1 }}>{userContext?.user?.uid === comment.userId ? "You" : comment.userId.slice(0, 2)}</Avatar>
                                    <Typography>{comment.text}</Typography>
                                    {(userContext.isAdmin || comment.userId === userContext?.user?.uid) && (
                                        <IconButton sx={{ ml: "auto" }} onClick={() => handleDelete(comment.id)}>
                                            <Delete></Delete>
                                        </IconButton>
                                    )}
                                    {/* Display other properties of the comment */}
                                </Box>
                            </Box>
                        ))}
                    </Box>
                )}
            </Box>
        </ThemeProvider>
    );
};
export default Comments;
