import { CircularProgress } from "@mui/material";
import React, { useContext, useState } from "react";
import { EventContext } from "../../../contexts/EventContextProvider";
import { privateLog } from "../../../dev/PrivateConsole";
import { ArtegrityEventData } from "../../../types/dto";
import { TicketShop } from "./ArtegrityTicketShop";

const ArtegrityTicketShopWrapper = () => {
    const [ticketShopEventData, setTicketShopEventData] = useState<ArtegrityEventData | undefined>(undefined);

    const { event } = useContext(EventContext);

    React.useEffect(() => {
        if (event) {
            privateLog("log", "Calling pilot be: ", process.env.REACT_APP_PILOT_BE_URL + "/v0/event/artegrityId?artegrityId=" + event.id);
            fetch(process.env.REACT_APP_PILOT_BE_URL + "/v0/event/artegrityId?artegrityId=" + event.id)
                .then((response) => {
                    if (response.status !== 200) {
                        throw new Error(response.status + ": " + response.statusText);
                    } else {
                        return response.json();
                    }
                })
                .then((_eventData: any) => {
                    privateLog("debug", "response event data: ", _eventData);
                    setTicketShopEventData(_eventData as ArtegrityEventData);
                })
                .catch((error) => {});
        }
    }, [event]);

    if (!event || !ticketShopEventData) {
        return <CircularProgress></CircularProgress>;
    }
    return <TicketShop artegrityEvent={ticketShopEventData} artegrityId={event.id} pixel={event.pixel !== undefined}></TicketShop>;
};

export default ArtegrityTicketShopWrapper;
