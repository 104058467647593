import { Button, ThemeProvider, useMediaQuery, useTheme } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { purpleTheme } from "../../themes/purpleTheme";

const SignInButton = () => {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const { eventId } = useParams();
    const ticketCode = searchParams.get("ticketCode");
    const url = window.location.pathname;
    const theme = useTheme();

    const isXSmall = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <ThemeProvider theme={purpleTheme}>
            <Button
                color="primary"
                variant="contained"
                sx={{ maxWidth: 250 }}
                onClick={() =>
                    navigate(`/v0/${eventId}/account`, {
                        state: {
                            eventId: eventId,
                            ticketCode: ticketCode,
                            route: url.includes("eventRoom") ? "eventRoom" : "backstage",
                        },
                    })
                }
            >
                {!isXSmall ? "Sign In | Sign Up" : "Sign In"}
            </Button>
        </ThemeProvider>
    );
};

export default SignInButton;
