import { ButtonBase, Typography } from "@mui/material";
import EventData from "../../../types/EventData";
import { useTranslation } from "react-i18next";
type Props = {
    event: EventData;
};

const BuyTicketsButton = ({ event }: Props) => {
    const { t } = useTranslation();

    if (event.ticketProvider && event.ticketProvider.shopUrl && event.onlyEventRoom !== true)
        return (
            <ButtonBase onClick={() => window.open(event.ticketProvider?.shopUrl, "_blank")}>
                <Typography
                    sx={(theme) => ({
                        fontWeight: "bold",
                        p: 1,
                        border: `solid 1px ${theme.palette.text.primary}`,
                        "&:hover": {
                            backgroundColor: theme.palette.primary.main,
                        },
                    })}
                >
                    {t("buy tickets")}
                </Typography>
            </ButtonBase>
        );
    else return null;
};
export default BuyTicketsButton;
