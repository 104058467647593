import { Box, ThemeProvider } from "@mui/material";
import React, { ReactElement } from "react";
import { Carousel } from "react-responsive-carousel";
import { EventContext } from "../../../contexts/EventContextProvider";
import ContentContainerNavBar from "./components/ContentContainerNavBar";
import "./CarouselStyles.css";

export interface NavItem {
    label: string;
    value: string;
    icon: React.ElementType;
    component: ReactElement;
    navColor?: string;
}

interface ContentContainerProps {
    navItems: NavItem[];
    initIndex: number;
}

const ContentContainer: React.FC<ContentContainerProps> = ({ navItems, initIndex }) => {
    const [navIndex, setNavIndex] = React.useState<number>(initIndex);
    const { brightTheme } = React.useContext(EventContext);

    return (
        <Box>
            <ContentContainerNavBar navIndex={navIndex} navItems={navItems} initIndex={initIndex} setNavIndex={setNavIndex}></ContentContainerNavBar>
            <Box sx={{ mr: -2, ml: -2 }}>
                <Carousel
                    preventMovementUntilSwipeScrollTolerance
                    swipeable={false}
                    showArrows={false}
                    showIndicators={false}
                    showStatus={false}
                    showThumbs={false}
                    selectedItem={navIndex}
                    onChange={(value) => setNavIndex(value)}
                >
                    {navItems.map((item, index) => (
                        <ThemeProvider key={index} theme={brightTheme}>
                            <Box
                                mt={2}
                                paddingX={2}
                                sx={{
                                    height: navIndex !== index ? `calc(100vh - 60px)` : null,
                                }}
                            >
                                {item.component}
                            </Box>
                        </ThemeProvider>
                    ))}
                </Carousel>
            </Box>
        </Box>
    );
};

export default ContentContainer;
