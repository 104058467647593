// UserContext.tsx
import { User, UserCredential, onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { httpsCallable } from "firebase/functions";

import React, { ReactNode, createContext, useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { v4 as uuidv4 } from "uuid";
import { privateLog } from "../dev/PrivateConsole";
import { auth, functions } from "../firebase";
import { TicketProviderName } from "../types/dto";
import { useEvent } from "./EventContextProvider";
import { addTicketCodeToUser, checkIfUserHasBackstagePassConnected, checkIfUserIsAdmin, checkIfUserIsMember } from "./ticketVerification";

interface UserContextType {
    user: User | null;
    isAdmin: boolean | undefined;
    member: string | undefined;
    backstagePassVerified: boolean | BackstageTicketCodeEvent | undefined;
}

interface UserRoles {
    backstagePassVerified: boolean | BackstageTicketCodeEvent | undefined;
    isAdmin: boolean | undefined;
    member: string | undefined;
}

export const UserContext = createContext<UserContextType | undefined>(undefined);

export type BackstageTicketCodeEvent = {
    startDate: Date;
    endDate: Date;
};

interface UserProviderProps {
    children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
    const [user, setUser] = useState<User | null>(auth.currentUser);
    const [userRoles, setUserRoles] = useState<UserRoles>({
        backstagePassVerified: undefined,
        isAdmin: undefined,
        member: undefined,
    });

    const event = useEvent();
    //ticket Verified check
    const verifyTicket = async (eventId: string, ticketCode: string): Promise<string | null> => {
        privateLog("info", "Verifiying TicktCode: ", ticketCode);
        const ticketVerification = httpsCallable(functions, "ticketVerification-ticketVerification");
        privateLog("log", "TicketVerification EventId: ", eventId, " TicketCode: ", ticketCode);
        let res = await ticketVerification({
            ticketCode: ticketCode,
            eventId: eventId,
        })
            .then((result) => {
                privateLog("info", "Verifiying TicktCode result: ", result);
                if (result.data as string) {
                    let token = result.data as string;
                    return token;
                }
                return null;
            })
            .catch((error) => {
                privateLog("info", "Verifiying TicktCode error: ", error);
                return null;
            });
        return res;
    };
    const attemptTokenSignIn = (token: string) => {
        privateLog("info", "Attempting token sign-in.");
        signInWithCustomToken(auth, token)
            .then((userCredential: UserCredential) => {
                setUser(userCredential.user);
                privateLog("info", "User signed in with custom token.");
            })
            .catch((error) => {
                privateLog("error", "Sign-in with custom token failed:", error);
            });
    };
    // sign user in if a token exist and he is not already signed in
    //useEffect(() => {
    //if (ticketVerified === true && token) {
    //if (!user?.displayName) {
    //attemptTokenSignIn(token);
    //}
    //} // Cleanup subscription on unmount
    //}, [event, ticketVerified, token]);

    //update the User
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (_user) => {
            privateLog("log", "Firebase auth state change triggered with user: ", _user);

            const resolveUser = async (_user: User | null) => {
                let urlSearchParams = new URLSearchParams(window.location.search);
                let ticketCode = urlSearchParams.get("ticketCode");
                let eventId = urlSearchParams.get("eventId");
                //TODO
                if (ticketCode) {
                    if (ticketCode?.includes("QR21ee2a4c") || ticketCode?.includes("QR422b196e")) {
                        ticketCode = ticketCode + "-" + uuidv4();
                    } else if (ticketCode?.includes("EM-e79a265a")) {
                        let ticketCodeDate = urlSearchParams.get("date");
                        let ticketCodeCsid = urlSearchParams.get("csid");
                        if (ticketCodeDate && ticketCodeCsid) {
                            ticketCode = ticketCode + "#" + ticketCodeCsid + "#" + ticketCodeDate;
                        } else {
                            privateLog("error", "Em TicketCode does not include date: ", ticketCodeDate, "or: csid: ", ticketCodeCsid);
                        }
                    } else if (ticketCode) {
                        ticketCode = ticketCode + window.location.hash;
                    }
                }
                if (_user) {
                    //set the user roles and add ticket to user if necessary
                    privateLog("info", "User signed in:", _user);
                    checkUserRoles(_user);
                    setUser(_user);
                } else {
                    //user signed out
                    if (user !== null) {
                        privateLog("info", "User not signed in, old user: ", user);
                        setUser(null);
                        setUserRoles({
                            isAdmin: false,
                            member: undefined,
                            backstagePassVerified: false,
                        });
                        return;
                    }
                    //verify ticket state
                    let token = null;
                    if (eventId && ticketCode) {
                        token = await verifyTicket(eventId, ticketCode);
                    }
                    // if token sign in possible
                    if (token === null) {
                        setUserRoles({
                            isAdmin: false,
                            member: undefined,
                            backstagePassVerified: false,
                        });
                        return;
                    } else {
                        attemptTokenSignIn(token);
                        return;
                    }
                }
            };
            resolveUser(_user);
        });
        return () => unsubscribe();
        // Cleanup subscription on unmount
    }, []);

    //useEffect(() => {
    //privateLog("info", "Ticket Ticket Verified: ", ticketVerified);
    //}, [user, ticketVerified]);

    // some printing of user Roles changed
    useEffect(() => {
        privateLog("info", "Backstage Pass Verified:", userRoles);
    }, [userRoles]);

    const askToAddTicketCodeToUser = async (user: User, ticketCode: string, eventId: string) => {
        //Check if user wants to connect his backstage pass
        //TODO: translation
        if (event.event?.ticketProvider?.name === TicketProviderName.PUBLIC) {
            let success = await addTicketCodeToUser(ticketCode, eventId);
            if (success) {
                return true;
            }
        }
        const result = await Swal.fire({
            title: "You are logged in with account: " + user.email,
            text: "Do you want to add your Backstage Pass to your acount?",
            icon: "question",
            showCancelButton: true,
            confirmButtonText: "Yes, add the BackstagePass!",
            cancelButtonText: "No, continue without Login.",
        });
        if (result.value) {
            //case valid
            let success = await addTicketCodeToUser(ticketCode, eventId);
            if (success) {
                return true;
            }
        }
        if (result.dismiss) {
            privateLog("debug", "User does not want to connect TicketCode");
            return false;
        }
        return false;
    };

    const checkUserRoles = async (user: User) => {
        if (event.event) {
            let _isAdmin = await checkIfUserIsAdmin(user, event.event.id);
            let _member = await checkIfUserIsMember(user, event.event.members);
            let _backstagePassVerified = false;
            if (_isAdmin || _member) {
                _backstagePassVerified = true;
                setUserRoles({
                    backstagePassVerified: _backstagePassVerified,
                    isAdmin: _isAdmin,
                    member: _member,
                });
                return;
            }

            let res = await checkIfUserHasBackstagePassConnected(user, event.event.id, event.event.multiEvent);
            let isPublicEvent = event.event.ticketProvider?.name === TicketProviderName.PUBLIC;

            privateLog(
                "log",
                "User Context",
                "Has BackstagePass connected: ",
                res,
                "TicketCode: ",
                event.event.ticketCode,
                "TicketProvider Check: ",
                isPublicEvent,
                "ProviderData:",
                user.providerData
            );
            if (res === false && user.providerData.length !== 0 && (event.event.ticketCode || isPublicEvent)) {
                let intTicketCode = isPublicEvent ? "public-" + uuidv4() : event.event.ticketCode;
                privateLog("log", "User Context", "Checking with TicketCode: ", intTicketCode);
                if (intTicketCode) {
                    const result = await askToAddTicketCodeToUser(user, intTicketCode, event.event.id);
                    if (result) {
                        res = true;
                    }
                }
            }
            setUserRoles({
                backstagePassVerified: res,
                isAdmin: _isAdmin,
                member: _member,
            });
        }
    };

    return <UserContext.Provider value={{ user, ...userRoles }}>{children}</UserContext.Provider>;
};

export const useAuth = (): UserContextType => {
    const context = useContext(UserContext);
    if (context === undefined) {
        throw new Error("useAuth must be used within a UserProvider");
    }
    return context;
};
