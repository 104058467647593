import { CircularProgress } from "@mui/material";
import { addDoc, collection, getDocs, limit, orderBy, query, serverTimestamp, where } from "firebase/firestore";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { EventContext } from "../../../contexts/EventContextProvider";
import { useAuth, UserContext } from "../../../contexts/UserContext";
import { privateLog } from "../../../dev/PrivateConsole";
import { db } from "../../../firebase";
import { getLanguageText } from "../../../languagesupport";
import { ContentComponent, ContentElementWireframe, ImagesElement, LanguageText, UploadState, VideoElement } from "../../../types/bll";
import ContentUploadContainerFrame from "./ContentUploadContainerFrame";
import UploadComponentPage from "./components/UploadComponentPage";
import UploadPreviewPage from "./components/UploadPreviewPage";
import UploadPropertiesPage from "./components/UploadPropertiesPage";

type Props = {
    setOpenUpload: (isOpen: boolean) => void;
    targetMember?: string;
};

export const getLanguageValue = (value: LanguageText | undefined, language: string): string => {
    return value ? getLanguageText(value, language) : "";
};

export const updateLanguageValue = (value: LanguageText | undefined, language: string, newValue: string): LanguageText => {
    return typeof value === "object"
        ? {
              ...value,
              [language]: newValue,
          }
        : {
              [language]: newValue,
          };
};

export const getL = (c: any) => new Intl.DisplayNames([], { type: "language" }).of(c);
console.log(getL("ru"));
const ContentUploadContainer = ({ setOpenUpload, targetMember }: Props) => {
    const { event } = useContext(EventContext);
    const userContext = useContext(UserContext);
    const [uploadState, setUploadState] = useState<UploadState>(UploadState.PROPERTIES);
    const { member } = useAuth();

    const [uploading, setUploading] = useState(false);
    const [error, setError] = useState<string>("");

    // Initialize contentElement with default values
    const [contentElement, setContentElement] = useState<ContentElementWireframe>({
        eventId: event?.id ?? "",
        userId: member ? member : userContext?.user?.uid || "",
        visibility: "public",
        members: targetMember ? [targetMember] : [event?.id ? event.id : ""],
        component: ContentComponent.IMAGES,
        data: undefined,
    });

    if (!event || !(userContext && userContext.user)) {
        return <CircularProgress></CircularProgress>;
    }

    const goToNextPage = () => {
        if (uploadState < UploadState.PREVIEW) {
            setUploadState(uploadState + 1);
        }
    };

    const goBackPage = () => {
        if (uploadState > 0) {
            setUploadState(uploadState - 1);
        }
    };

    // Function to update the contentElement state
    const updateContentElement = (updates: Partial<ContentElementWireframe>) => {
        setContentElement({ ...contentElement, ...updates });
    };
    const uploadVideo = async (videoElement: VideoElement) => {
        if (!uploading && videoElement) {
            privateLog("log", "upload started");
            const storage = getStorage();
            // Create a reference to the file in Firebase Storage
            const uuid = uuidv4();
            const path = `${event.id}/${uuid}_${videoElement.name}`;
            const videoRef = ref(storage, path);
            // Fetch the blob from the URL
            const response = await fetch(videoElement.path);
            const blob = await response.blob();
            // Upload the blob
            const uploadResult = await uploadBytes(videoRef, blob);
            if (!uploadResult) {
                privateLog("error", "could not upload video at path: ", path);
                return;
            }
            privateLog("log", "upload finished", uploadResult.ref.fullPath);
            return await getDownloadURL(uploadResult.ref);
        }
    };

    const uploadImages = async (imagesElement: ImagesElement): Promise<string | null> => {
        if (!uploading && imagesElement) {
            privateLog("log", "upload started");
            const storage = getStorage();

            // Generate a unique ID for the new folder
            const folderId = uuidv4();

            // Upload the images to a new folder in Firebase Storage with the unique ID
            if (imagesElement.paths) {
                for (let i = 0; i < imagesElement.paths.length; i++) {
                    const path = `${folderId}/${i + 1}.png`;
                    const imageRef = ref(storage, path);

                    // Fetch the blob from the URL
                    const response = await fetch(imagesElement.paths[i]);
                    const blob = await response.blob();

                    // Upload the blob
                    const uploadResult = await uploadBytes(imageRef, blob);
                    if (!uploadResult) {
                        privateLog("error", "could not upload image at path: ", path);
                        return null;
                    }
                    privateLog("log", "upload finished", uploadResult.ref.fullPath);
                }
                return folderId;
            }
        }
        return null;
    };

    const handleUpload = async () => {
        privateLog("log", "uploading post with content: ", contentElement);
        setUploading(true);

        if (contentElement.supportedLanguages === undefined) {
            delete contentElement.supportedLanguages;
        }

        contentElement.uploadedAt = serverTimestamp();

        switch (contentElement.component) {
            case ContentComponent.VIDEO:
                let newPath = await uploadVideo(contentElement.data as VideoElement);
                if (newPath) {
                    (contentElement.data as VideoElement).path = newPath;
                }
                break;
            case ContentComponent.IMAGES:
                let folderId = await uploadImages(contentElement.data as ImagesElement);
                let dataElement = contentElement.data as ImagesElement;
                dataElement.baseUrl = "gs://artegrity-backstage.appspot.com/" + folderId;
                dataElement.size = dataElement.paths?.length ?? 0;
                delete dataElement.paths;
        }
        privateLog("log", "ContentUploadContainer Uploading Element", contentElement);

        const postCollection = collection(db, "Posts");

        const w = where("eventId", "==", event.id);

        const w2 = where("visibility", "in", ["public", "exclusive"]);

        const q = query(postCollection, w, w2, orderBy("position", "desc"), limit(1));

        const querySnapshot = await getDocs(q);

        let position = 1;
        if (!querySnapshot.empty) {
            position = Number(await querySnapshot.docs[0].data().position) + 1;
        }

        const postsCollectionRef = collection(db, "Posts");
        const docRef = await addDoc(postsCollectionRef, { ...contentElement, position: position }).catch((e) => {
            privateLog("erorr", "Error: ", e);
            setError(e);
            return null;
        });
        if (docRef) {
            privateLog("debug", "uploaded post successful", docRef?.id);
            setUploading(false);
            setOpenUpload(false);
        } else {
            privateLog("error", "could not upload");
            setUploading(false);
        }
    };

    return (
        <ContentUploadContainerFrame
            error={error}
            uploading={uploading}
            upload={handleUpload}
            goToNextPage={goToNextPage}
            goBackPage={goBackPage}
            uploadState={uploadState}
            setOpenUpload={setOpenUpload}
        >
            {(() => {
                switch (uploadState) {
                    case UploadState.PROPERTIES:
                        return (
                            <UploadPropertiesPage
                                event={event}
                                contentElement={contentElement}
                                updateContentElement={updateContentElement}
                                targetMember={targetMember}
                            ></UploadPropertiesPage>
                        );
                    case UploadState.COMPONENT:
                        return (
                            <UploadComponentPage contentElement={contentElement} updateContentElement={updateContentElement}></UploadComponentPage>
                        );
                    case UploadState.PREVIEW:
                        return <UploadPreviewPage contentElement={contentElement} event={event}></UploadPreviewPage>;
                    default:
                        return null;
                }
            })()}
        </ContentUploadContainerFrame>
    );
};

export default ContentUploadContainer;
