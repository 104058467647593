import { Box, CircularProgress, ThemeProvider, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { privateLog } from "../../../../dev/PrivateConsole";
import { functions } from "../../../../firebase";

interface DataItem {
    label: string;
    count: number;
    breakdown_value: string;
    action: { id: string; type: string; order: number };
    data: number[];
    days: string[];
    filter: { breakdown: string };
}

const PageAnalytics = () => {
    const { event, brightTheme } = React.useContext(EventContext);
    const [chartDataTotal, setChartDataTotal] = useState<(string | number)[][]>([]);
    const [, setChartDataUnique] = useState<(string | number)[][]>([]);

    const [avgTimeSpendBackstage, setAvgTimeSpendBackstage] = useState<number>(0);
    const [avgTimeSpendEventRoom, setAvgTimeSpendEventRoom] = useState<number>(0);

    useEffect(() => {
        const initData = async () => {
            if (event) {
                const getAnalyticsData = httpsCallable(functions, "postHogAnalytics-getPostHogAnalytics");
                let pageCountTotal = (
                    await getAnalyticsData({
                        eventId: event.id,
                        analyticsFunc: "last30DaysPageCountEventRoom",
                        isUnique: false,
                    })
                ).data as DataItem[];

                pageCountTotal = pageCountTotal.filter((item) => item.breakdown_value !== "$$_posthog_breakdown_null_$$");

                privateLog("debug", "pageCountTotal", pageCountTotal);

                const formattedDataTotal: (string | number)[][] = [
                    ["Date", ...pageCountTotal.map((item) => item.breakdown_value)],
                    ...pageCountTotal[0].days.map((day, i) => [
                        new Date(day).toLocaleDateString(undefined, {
                            month: "short",
                            day: "numeric",
                        }),
                        ...pageCountTotal.map((item) => item.data[i]),
                    ]),
                ];

                setChartDataTotal(formattedDataTotal);

                let pageCountUnique = (
                    await getAnalyticsData({
                        eventId: event.id,
                        analyticsFunc: "last30DaysPageCountEventRoom",
                        isUnique: true,
                    })
                ).data as DataItem[];

                pageCountUnique = pageCountTotal.filter((item) => item.breakdown_value !== "$$_posthog_breakdown_null_$$");

                const formattedDataUnique: (string | number)[][] = [
                    ["Date", ...pageCountUnique.map((item) => item.breakdown_value)],
                    ...pageCountUnique[0].days.map((day, i) => [
                        new Date(day).toLocaleDateString(undefined, {
                            month: "short",
                            day: "numeric",
                        }),
                        ...pageCountUnique.map((item) => item.data[i]),
                    ]),
                ];

                setChartDataUnique(formattedDataUnique);
                try {
                    let avgTimeSpendBackstage = (
                        await getAnalyticsData({
                            eventId: event.id,
                            analyticsFunc: "avgTimeSpendEventRoom",
                            isBackstage: true,
                        })
                    ).data;
                    privateLog("debug", "avgTimeSpendBackstage", avgTimeSpendBackstage);
                    if (avgTimeSpendBackstage && (avgTimeSpendBackstage as number[][]).length > 0) {
                        setAvgTimeSpendBackstage((avgTimeSpendBackstage as number[][])[0][0]);
                    }
                } catch (e) {}

                try {
                    let avgTimeSpendEventRoom = (
                        await getAnalyticsData({
                            eventId: event.id,
                            analyticsFunc: "avgTimeSpendEventRoom",
                            isBackstage: false,
                        })
                    ).data;
                    if (avgTimeSpendEventRoom && (avgTimeSpendEventRoom as number[][]).length > 0) {
                        setAvgTimeSpendEventRoom((avgTimeSpendEventRoom as number[][])[0][0]);
                    }
                } catch (e) {}
            }
        };
        initData();
    }, [event]);
    return (
        <ThemeProvider theme={brightTheme}>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    p: 2,
                    borderRadius: 5,
                    overflow: "hidden",
                }}
            >
                <Typography variant="h2">Page View Analytics</Typography>
                <Box
                    sx={{
                        mt: 2,
                        display: "flex",
                        flexDirection: "row",
                        width: "100%",
                        justifyContent: "space-around",
                    }}
                >
                    <Box>
                        <Typography>Avg Time Spend EventRoom (7day)</Typography>
                        <Typography fontWeight={"bold"}>{(avgTimeSpendEventRoom ? avgTimeSpendEventRoom : 0).toFixed(2)} min</Typography>
                    </Box>
                    <Box>
                        <Typography>Avg Time Spend Backstage (7day)</Typography>
                        <Typography fontWeight={"bold"}>{(avgTimeSpendBackstage ? avgTimeSpendBackstage : 0).toFixed(2)} min</Typography>
                    </Box>
                </Box>
                <Typography variant="h2" sx={{ fontSize: 24, mt: 5 }}>
                    Total Page Views
                </Typography>
                <Chart
                    chartType="LineChart"
                    loader={<CircularProgress></CircularProgress>}
                    width={"100%"}
                    height={"300px"}
                    data={chartDataTotal}
                    options={{
                        curveType: "function",
                        legend: { position: "bottom" },
                        hAxis: {
                            title: "Date",
                            format: "dd/MM/yy",
                            showTextEvery: 5,
                        },
                    }}
                />
                {/*<Typography variant="h2" sx={{ fontSize: 24, mt: 5 }}>
          Unique Visitors
        </Typography>
        <Chart
          chartType="LineChart"
          width={"100%"}
          height={"300px"}
          data={chartDataUnique}
          options={{
            curveType: "function",
            legend: { position: "bottom" },
            hAxis: {
              title: "Date",
              format: "dd/MM/yy",
              showTextEvery: 5,
            },
          }}
        />*/}
            </Box>
        </ThemeProvider>
    );
};

export default PageAnalytics;
