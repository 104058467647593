import { ArrowBackIos, ArrowForwardIos, Cancel, ConfirmationNumber, Delete, Favorite, FavoriteBorder } from "@mui/icons-material";
import { Avatar, Box, ButtonBase, ThemeProvider, Typography } from "@mui/material";
import { deleteDoc, doc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { UserContext, useAuth } from "../../../../contexts/UserContext";
import { privateLog } from "../../../../dev/PrivateConsole";
import Dialog from "../../../../dialog/Dialog";
import { db, functions } from "../../../../firebase";
import { getLanguageText } from "../../../../languagesupport";
import { purpleTheme } from "../../../../themes/purpleTheme";
import { Member } from "../../../../types/bll";
import { Story } from "../../../../types/dto";

interface StoriesDisplayProps {
    eventId: string;
    members: Member[];
    setOpenMemberStory: (member: Member | undefined) => void;
    stories: Story[];
    initMember: Member;
}

const StoriesDisplay: React.FC<StoriesDisplayProps> = ({ setOpenMemberStory, initMember, members, stories }) => {
    const getInitialIndex = (): number => {
        let index = -1;
        stories.forEach((s, ind) => {
            if (s.uploadedBy === initMember.id) {
                if (ind !== -1) {
                    index = ind;
                }
            }
        });
        return index === -1 ? 0 : index;
    };
    const [storyIndex, setStoryIndex] = useState<number>(getInitialIndex());
    const [openMember, setOpenMember] = useState<Member>(initMember);
    const userContext = useContext(UserContext);
    const { event, darkTheme } = useContext(EventContext);
    const { isAdmin, member, backstagePassVerified } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        let member = members.filter((m) => m.id === stories[storyIndex].uploadedBy)[0];
        if (member) {
            privateLog("New Member: ", member);
            setOpenMember(member);
        }
    }, [storyIndex]);

    const story = stories[storyIndex];

    const handleLike = async () => {
        // Get the userId. Replace this line with the actual way to get the userId.
        const userId = userContext?.user?.uid;
        const docRef = story.docRef;

        if (userId && docRef.id && event?.id) {
            if (story.likedBy?.includes(userContext.user?.uid || "")) {
                const likeContent = httpsCallable(functions, "modifyLikes-removeStoryLike");
                await likeContent({ contentId: docRef.id, eventId: event?.id });
            } else {
                const likeContent = httpsCallable(functions, "modifyLikes-likeStory");
                await likeContent({ contentId: docRef.id, eventId: event?.id });
            }
        }
    };

    const handleDelete = async () => {
        if (!event) {
            return;
        }
        try {
            const storyDocRef = doc(db, `events/${event.id}/stories/${stories[storyIndex].id}`);
            deleteDoc(storyDocRef);
            privateLog("debug", `Story with ID ${stories[storyIndex].id} deleted successfully.`);
            //TODO: might be a crazy ass race condition
            setOpenMemberStory(undefined);
            // Optionally, you can update the UI or state to reflect the deletion
        } catch (error) {
            privateLog("error", "Error deleting story: ", error);
            // Handle the error appropriately
        }
    };

    return (
        <Dialog sx={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
            <ThemeProvider theme={darkTheme}>
                <Box
                    flexDirection="column"
                    position="relative"
                    sx={{ display: "flex", flex: 1, height: "100%", width: "100%", backgroundColor: "background.default" }}
                >
                    {/* Media Container */}
                    <Box flex={7} width="100%" display="flex" overflow={"hidden"}>
                        {story.url?.endsWith(".mp4") || story.url?.endsWith(".webm") ? (
                            <Box component="video" controls sx={{ width: "100%", objectFit: "cover" }}>
                                <source src={story.url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </Box>
                        ) : (
                            <Box component="img" src={story.url} alt={story.filename} sx={{ width: "100%", height: "100%", objectFit: "cover" }} />
                        )}
                        {/* Close button */}
                        <ButtonBase
                            onClick={() => setOpenMemberStory(undefined)}
                            sx={{
                                position: "absolute",
                                top: 8, // Adjust as needed
                                right: 8, // Adjust as needed
                            }}
                        >
                            <Cancel color="primary" sx={{ height: 30, width: 30 }} />
                        </ButtonBase>
                        {isAdmin || member === stories[storyIndex].uploadedBy ? (
                            <ButtonBase
                                onClick={() => handleDelete()}
                                sx={{
                                    position: "absolute",
                                    top: 8, // Adjust as needed
                                    left: 8, // Adjust as needed
                                }}
                            >
                                <Delete color="primary" sx={{ height: 30, width: 30 }}></Delete>
                            </ButtonBase>
                        ) : null}
                    </Box>

                    {/* Description Container */}
                    <Box flex={1} sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                }}
                            >
                                {story.visibility === "exclusive" ? (
                                    <ThemeProvider theme={purpleTheme}>
                                        <ConfirmationNumber sx={{ color: "primary.main", mr: 2 }}></ConfirmationNumber>
                                    </ThemeProvider>
                                ) : null}
                                <Typography variant="h6">{getLanguageText(story.description)}</Typography>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
                                {backstagePassVerified ? (
                                    <ButtonBase onClick={() => handleLike()}>
                                        {story.likedBy?.includes(userContext?.user?.uid || "") ? (
                                            <Favorite sx={{ color: "text.primary" }}></Favorite>
                                        ) : (
                                            <FavoriteBorder
                                                sx={{
                                                    color: "text.primary",
                                                    "&:hover": {
                                                        color: "primary.main",
                                                    },
                                                }}
                                            ></FavoriteBorder>
                                        )}
                                    </ButtonBase>
                                ) : (
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <FavoriteBorder sx={{ opacity: 0.5 }}></FavoriteBorder>
                                    </Box>
                                )}
                                <Typography mr={2}>{story.likedBy ? story.likedBy.length : 0}</Typography>
                            </Box>
                        </Box>
                        <Box
                            sx={{
                                mt: 2,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "flex-end",
                                }}
                            >
                                <ButtonBase
                                    onClick={() => {
                                        if (openMember.role === "event") {
                                            setOpenMemberStory(undefined);
                                        }
                                        navigate("member", { state: { member: openMember } });
                                    }}
                                >
                                    <Avatar
                                        sx={{
                                            width: 60,
                                            height: 60,
                                        }}
                                        src={openMember.imagePath}
                                    ></Avatar>
                                    <Typography ml={2} variant="body1">
                                        {getLanguageText(openMember.name)}
                                    </Typography>
                                </ButtonBase>
                            </Box>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                {storyIndex !== 0 ? (
                                    <ButtonBase
                                        onClick={() => {
                                            setStoryIndex((storyIndex) => {
                                                return storyIndex - 1;
                                            });
                                        }}
                                    >
                                        <ArrowBackIos color="primary" sx={{ width: 30, height: 30 }}></ArrowBackIos>
                                    </ButtonBase>
                                ) : null}
                                {storyIndex !== stories.length - 1 ? (
                                    <ButtonBase
                                        onClick={() => {
                                            setStoryIndex((storyIndex) => storyIndex + 1);
                                        }}
                                    >
                                        <ArrowForwardIos color="primary" sx={{ width: 30, height: 30 }}></ArrowForwardIos>
                                    </ButtonBase>
                                ) : null}
                            </Box>
                        </Box>
                    </Box>

                    {/* Close button */}
                </Box>
            </ThemeProvider>
        </Dialog>
    );
};

export default StoriesDisplay;
