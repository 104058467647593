import { Avatar, Box, Button, ButtonBase, IconButton, ThemeProvider, Typography } from "@mui/material";

import { Add, Remove } from "@mui/icons-material";
import { doc, getDoc } from "firebase/firestore";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { db, getStorageURL, getStorageUrlStatic } from "../../../../firebase";
import { LineUpElement, Member } from "../../../../types/bll";
import { Backstage } from "../../Backstage";

type Props = {
    lineUpElement: LineUpElement;
    handleLineUpCicked: () => void;
    addElement: (memberId: string) => void;
    removeElement: (memberId: string) => void;
    isLoggedIn: boolean;
    isAdded: boolean;
    stage?: string;
    day?: number;
};

const LineUpComponent = ({ lineUpElement, handleLineUpCicked, addElement, removeElement, isLoggedIn, isAdded, stage, day }: Props) => {
    //const [artist] = React.useState<Member>();
    const [openArtist, setOpenArtist] = React.useState<string | undefined>(undefined);
    const { darkTheme, brightTheme } = React.useContext(EventContext);
    const navigate = useNavigate();

    const getMember = async () => {
        if (openArtist) {
            let memberDocRef = doc(db, "users", openArtist);
            // Retrieve the document
            let docSnapshot = await getDoc(memberDocRef);
            if (docSnapshot.exists()) {
                // If the document exists, you can use docSnapshot.data() to access its data
                let memberData = docSnapshot.data();
                memberData.id = docSnapshot.id;
                memberData.imagePath = await getStorageURL(memberData.imagePath);
                // Do something with memberData, e.g., update state
                navigate("member", { state: { member: memberData as Member } });
            } else {
                // Handle the case where the document does not exist
                console.log("No such document!");
            }
        }
    };

    function formatTime(time: string): string {
        let [hours, minutes] = time.split(":").map(Number); // Step 1: Split and convert to numbers

        hours %= 24; // Step 2: Adjust hours if over 24

        // Step 3: Format with leading zeros if necessary
        const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
        const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

        // Step 4: Concatenate for final time string
        return `${formattedHours}:${formattedMinutes}`;
    }

    const getActionButton = () => {
        if (!isLoggedIn) {
            return;
        }
        if (!isAdded) {
            return (
                <IconButton color="primary" onClick={() => addElement(lineUpElement.memberId)}>
                    <Add></Add>
                </IconButton>
            );
        }

        return (
            <IconButton color="primary" onClick={() => removeElement(lineUpElement.memberId)}>
                <Remove></Remove>
            </IconButton>
        );
    };
    return (
        <ThemeProvider theme={darkTheme}>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    mb: 1,
                    p: 1,
                    borderRadius: 2,
                    wdith: "100%",
                }}
            >
                <Box
                    sx={{
                        flexDirection: "row",
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                    }}
                >
                    <Box sx={{ flexDirection: "row", display: "flex", width: "100%" }}>
                        <ButtonBase
                            sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "start",
                            }}
                            onClick={() => {
                                if (openArtist) {
                                    setOpenArtist(undefined);
                                } else {
                                    setOpenArtist(lineUpElement.memberId);
                                    handleLineUpCicked();
                                }
                            }}
                        >
                            {lineUpElement.imagePath ? (
                                <Avatar
                                    alt={lineUpElement.name}
                                    src={
                                        lineUpElement.imagePath.includes("https")
                                            ? lineUpElement.imagePath
                                            : getStorageUrlStatic(lineUpElement.imagePath)
                                    }
                                ></Avatar>
                            ) : (
                                <Avatar>{lineUpElement.name.slice(0, 2)}</Avatar>
                            )}

                            <Box sx={{ ml: 1 }}>
                                <Typography align="left" fontWeight={"bold"}>
                                    {lineUpElement.name}
                                </Typography>
                                {(stage || day) && (
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <Typography variant="body2">{day}</Typography>
                                        <Typography ml={day ? 1 : 0} variant="body2">
                                            {stage}
                                        </Typography>
                                    </Box>
                                )}
                            </Box>
                        </ButtonBase>
                    </Box>
                    {getActionButton()}
                    <ThemeProvider theme={brightTheme}>
                        <Box
                            sx={{
                                flexDirection: "row",
                                display: "flex",
                            }}
                        >
                            <Box
                                sx={{
                                    p: 1,
                                    borderRadius: 2,
                                    minWidth: 100,
                                    backgroundColor: isAdded ? "primary.main" : "background.default",
                                }}
                            >
                                <Typography variant="body2" style={{ whiteSpace: "nowrap" }}>
                                    {formatTime(lineUpElement.startTime) + "-" + formatTime(lineUpElement.endTime)}
                                </Typography>
                            </Box>
                        </Box>
                    </ThemeProvider>
                </Box>
                {openArtist && (
                    <Box>
                        <Button onClick={() => getMember()} sx={{ mt: 1 }}>
                            View Artist Page
                        </Button>
                        <Backstage isExclusive={false} member={lineUpElement.memberId} preview={true}></Backstage>{" "}
                    </Box>
                )}
                {openArtist && (
                    <Button onClick={() => setOpenArtist(undefined)} sx={{ mt: 1 }}>
                        Close
                    </Button>
                )}
            </Box>
        </ThemeProvider>
    );
};

export default LineUpComponent;
