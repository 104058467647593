import { Box, CircularProgress, TextField, Typography } from "@mui/material";
import { useEffect } from "react";
import { SoundCloudElement } from "../../../../types/bll";
import { getL, getLanguageValue, updateLanguageValue } from "../ContentUploadContainer";

type Props = {
    languages?: string[];
    soundCloudElement: SoundCloudElement | undefined;
    setSoundCloudElement: (element: SoundCloudElement) => void;
};

const SoundCloudUploadComponent = ({ languages, soundCloudElement, setSoundCloudElement }: Props) => {
    useEffect(() => {
        if (soundCloudElement === undefined) {
            setSoundCloudElement({ trackId: "" } as SoundCloudElement);
        }
    });

    if (!soundCloudElement) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <Box
            sx={{
                width: "100%",
                flex: 1,
                display: "flex",
                flexDirection: "column",
            }}
            gap={1}
        >
            <Typography>Track Id</Typography>
            <TextField
                label="SoundCloud Track ID"
                variant="outlined"
                required={true}
                value={soundCloudElement?.trackId}
                onChange={(e) =>
                    setSoundCloudElement({
                        ...soundCloudElement,
                        trackId: e.target.value,
                    })
                }
            />
            <Typography>Or</Typography>
            <TextField
                label="SoundCloud WordPress Embedd"
                variant="outlined"
                required={true}
                value={soundCloudElement?.trackId}
                onChange={(e) => {
                    let parts = e.target.value.split("tracks/");
                    let newValue = parts.length > 1 ? parts[1].split("&color")[0] : "";
                    setSoundCloudElement({
                        ...soundCloudElement,
                        trackId: newValue,
                    });
                }}
            />
            {soundCloudElement.trackId ? (
                <iframe
                    id={soundCloudElement.trackId}
                    width="102%"
                    height="200"
                    allow="autoplay"
                    src={
                        "https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/" +
                        soundCloudElement.trackId +
                        "&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"
                    }
                ></iframe>
            ) : null}
            {languages ? (
                languages.map((language) => (
                    <Box gap={1} sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>{getL(language)}</Typography>
                        <TextField
                            label="Track Name"
                            variant="outlined"
                            required={false}
                            value={getLanguageValue(soundCloudElement.name, language)}
                            onChange={(e) =>
                                setSoundCloudElement({
                                    ...soundCloudElement,
                                    name: updateLanguageValue(soundCloudElement.name, language, e.target.value),
                                })
                            }
                        />
                        <TextField
                            label="Description"
                            variant="outlined"
                            required={false}
                            value={getLanguageValue(soundCloudElement.description, language)}
                            onChange={(e) =>
                                setSoundCloudElement({
                                    ...soundCloudElement,
                                    description: updateLanguageValue(soundCloudElement.description, language, e.target.value),
                                })
                            }
                        />
                    </Box>
                ))
            ) : (
                <Box gap={1} sx={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        label="Track Name"
                        variant="outlined"
                        required={false}
                        value={soundCloudElement.name || ""}
                        onChange={(e) =>
                            setSoundCloudElement({
                                ...soundCloudElement,
                                name: e.target.value,
                            })
                        }
                    />
                    <TextField
                        label="Description"
                        variant="outlined"
                        required={false}
                        value={soundCloudElement.description || ""}
                        onChange={(e) =>
                            setSoundCloudElement({
                                ...soundCloudElement,
                                description: e.target.value,
                            })
                        }
                    />
                </Box>
            )}
        </Box>
    );
};

export default SoundCloudUploadComponent;
