import { createTheme, ThemeOptions } from "@mui/material/styles";
import { defaultTheme } from "./defaultTheme";

export const defaultThemeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: "#AD4594",
            contrastText: "#FFFFFF",
        },
        secondary: { main: "#FFFFFF" },
        background: {
            default: "#3B3B3B",
        },
        text: {
            primary: "#FFFFFF",
        },
    },
    typography: {
        fontFamily: "Montserrat, sans-serif",
        h1: {
            fontFamily: "Montserrat, sans-serif",
            fontSize: 35,
        },
        h2: {
            fontFamily: "Montserrat, sans-serif",
            fontSize: 20,
        },
        h3: {
            fontFamily: "Montserrat, sans-serif",
            fontSize: 25,
        },
        h5: {
            fontFamily: "Montserrat, sans-serif",
            fontSize: 25,
        },
        body1: {
            fontFamily: "Montserrat, sans-serif",
            fontSize: 14,
        },
        body2: {
            fontFamily: "Montserrat, sans-serif",
            fontSize: 12,
        },
    },
};

export const artegrityTheme = createTheme(defaultTheme, defaultThemeOptions);
