import { Box } from "@mui/material";
import React from "react";
import {} from "../../../analytics/Posthog";
import { EventContext } from "../../../contexts/EventContextProvider";
import CheckIn from "./components/CheckIn";
import FreeTickets from "./components/FreeTickets";
import MemberAnalytics from "./components/MemberAnalytics";
import PageAnalytics from "./components/PageAnalytics";
import TicketSummary from "./components/TicketSummary";
import BackstageAnalytics from "./components/BackstageAnalytics";

const AdminContainer = () => {
    const { event } = React.useContext(EventContext);

    if (!event) return null;

    return (
        <Box
            sx={{
                "& > *:not(:last-child)": {
                    mb: 2, // Adds a bottom margin to all children except the last one
                },
            }}
        >
            {event?.ticketProvider?.name === "artegrity" ? <TicketSummary></TicketSummary> : null}
            <PageAnalytics></PageAnalytics>
            {event?.multiEvent && <BackstageAnalytics></BackstageAnalytics>}
            <MemberAnalytics eventId={event.id}></MemberAnalytics>
            {event?.ticketProvider?.name === "artegrity" ? <FreeTickets></FreeTickets> : null}
            {event?.ticketProvider?.name === "artegrity" ? <CheckIn></CheckIn> : null}
        </Box>
    );
};

export default AdminContainer;
