import { DocumentReference } from "firebase/firestore";
import { Company, EventDataInterface, EventFrame, EventTheme, InfoElement, LanguageCode, TicketProvider } from "./dto";
import { LanguageText } from "./bll";

class EventData implements EventDataInterface {
    onlyEventRoom?: boolean;
    websiteUrl?: string;
    id: string;
    title: LanguageText;
    subtitle: LanguageText;
    description: LanguageText;
    ticketProvider?: TicketProvider;
    imagePath: string;
    frame?: EventFrame;
    company: Company;
    members: DocumentReference[];
    ticketCode?: string;
    theme: EventTheme;
    infoList?: InfoElement[];
    supportedLanguages: LanguageCode[];
    credits?: string;
    sponsorImages?: string[];
    analyticsVersion?: number;
    ga4MeasurementId?: string;
    multiEvent?: boolean;
    pixel?: string;

    constructor(eventData: EventDataInterface) {
        this.id = eventData.id;
        this.onlyEventRoom = eventData.onlyEventRoom;
        this.title = eventData.title;
        this.subtitle = eventData.subtitle;
        this.description = eventData.description;
        this.ticketProvider = eventData.ticketProvider;
        this.imagePath = eventData.imagePath;
        this.company = eventData.company;
        this.members = eventData.members;
        this.ticketCode = eventData.ticketCode;
        this.theme = eventData.theme;
        this.infoList = eventData.infoList;
        this.frame = eventData.frame;
        this.supportedLanguages = eventData.supportedLanguages;
        this.websiteUrl = eventData.websiteUrl;
        this.credits = eventData.credits;
        this.sponsorImages = eventData.sponsorImages;
        this.analyticsVersion = eventData.analyticsVersion;
        this.ga4MeasurementId = eventData.ga4MeasurementId;
        this.multiEvent = eventData.multiEvent;
        this.pixel = eventData.pixel;
    }

    getNavOffset = (isLg: boolean): number => {
        if (this.frame) {
            return isLg ? 0 : this.frame.data.offset;
        } else {
            return 0;
        }
    };
}

export default EventData;
