import { keyframes } from "@emotion/react";
import { SvgIconProps } from "@mui/material";
import { Balls } from "../svg/Balls";

const rotate = keyframes`from {transform: rotate(0deg);}to {transform: rotate(-360deg);}`;

export const CircularProgressArtegrity = (props: SvgIconProps) => {
    return (
        <Balls
            {...props}
            sx={{
                animation: `${rotate} 5s linear infinite`,
                ...props.sx, // Merge with any additional styles passed in
            }}
        />
    );
};
