import { Box } from "@mui/material";
import { ContentComponent, ContentElementWireframe, ContentType, ImagesElement, SoundCloudElement, VideoElement } from "../../../../types/bll";
import ImagesUploadComponent from "./ImagesUploadComponent";
import LineUpUploadComponent from "./LineUpUploadComponent";
import SoundCloudUploadComponent from "./SoundCloudUploadComponent";
import VideoUploadComponent from "./VideoUploadComponent";

type Props = {
    contentElement: ContentElementWireframe;
    updateContentElement: (updates: Partial<ContentElementWireframe>) => void;
};

const UploadComponentPage = ({ contentElement, updateContentElement }: Props) => {
    const setComponentData = (data: ContentType) => {
        updateContentElement({ data: data });
    };

    return (
        <Box>
            {(() => {
                switch (contentElement.component) {
                    case ContentComponent.SOUNDCLOUD:
                        return (
                            <SoundCloudUploadComponent
                                setSoundCloudElement={setComponentData}
                                soundCloudElement={contentElement.data ? (contentElement.data as SoundCloudElement) : undefined}
                                languages={contentElement.supportedLanguages}
                            />
                        );
                    case ContentComponent.VIDEO:
                        return (
                            <VideoUploadComponent
                                videoElement={contentElement.data ? (contentElement.data as VideoElement) : undefined}
                                setVideoElement={setComponentData}
                                languages={contentElement.supportedLanguages}
                            ></VideoUploadComponent>
                        );
                    case ContentComponent.IMAGES:
                        return (
                            <ImagesUploadComponent
                                imagesElement={contentElement.data ? (contentElement.data as ImagesElement) : undefined}
                                setImagesElement={setComponentData}
                                languages={contentElement.supportedLanguages}
                            ></ImagesUploadComponent>
                        );
                    case ContentComponent.LINE_UP:
                        return <LineUpUploadComponent></LineUpUploadComponent>;
                    default:
                        return null;
                }
            })()}
        </Box>
    );
};

export default UploadComponentPage;
