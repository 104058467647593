import { Box, Typography } from "@mui/material";

const LineUpUploadComponent = () => {
    return (
        <Box>
            <Typography>Hello World</Typography>
        </Box>
    );
};

export default LineUpUploadComponent;
