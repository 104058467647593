import { ReactNode, useContext, useEffect } from "react";
import { FrameContext } from "../contexts/FrameContext";

interface Props {
    children: ReactNode;
}
const NoFrame = ({ children }: Props) => {
    const frameContext = useContext(FrameContext);

    useEffect(() => {
        frameContext?.setShowFrame(false);

        return () => {
            frameContext?.setShowFrame(true);
        };
    }, [frameContext]);

    return <>{children}</>;
};
export default NoFrame;
