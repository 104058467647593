import { Box, CircularProgress, Typography } from "@mui/material";

type Props = {
    title?: string;
};

const LoadingScreen = ({ title }: Props) => {
    return (
        <Box sx={{ width: "100%" }}>
            <Typography variant="h2">LOADING: {title}</Typography>
            <CircularProgress></CircularProgress>
        </Box>
    );
};

export default LoadingScreen;
