import { Box, CircularProgress, ThemeProvider, Typography } from "@mui/material";
import { useContext } from "react";
import { EventContext } from "../../../contexts/EventContextProvider";
import ArtegrityTicketShopWrapper from "../artegrityTicketShop/ArtegrityTicketShopWrapper";
import { useTranslation } from "react-i18next";

const TicketShop = () => {
    const { darkTheme, brightTheme } = useContext(EventContext);
    const { event } = useContext(EventContext);
    const { t } = useTranslation();

    if (!event) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <ThemeProvider theme={brightTheme}>
                <Box sx={{ borderRadius: 5, backgroundColor: "background.default", pt: 2 }}>
                    <Typography variant="h2">{t("buy tickets")}</Typography>
                    {event.ticketProvider?.name === "artegrity" ? <ArtegrityTicketShopWrapper></ArtegrityTicketShopWrapper> : null}
                </Box>
            </ThemeProvider>
        </ThemeProvider>
    );
};

export default TicketShop;
