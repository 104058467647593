import { CircularProgress } from "@mui/material";
import "./analytics/Posthog";
import { privateLog } from "./dev/PrivateConsole";
import "./firebase";
import { Frame } from "./frame/Frame";
import "./global.css"; // Adjust the path if necessary
import "./languagesupport";
import { useEffect, useState } from "react";

const App = () => {
    const mocksEnabled = process.env.REACT_APP_MOCKING_ENABLED;

    const [mocksInitialized, setMocksInitialized] = useState(false);

    useEffect(() => {
        const initWithMocks = async () => {
            privateLog("log", "ENV: Development");
            const { worker } = await import("./mocks/browser");
            await worker.start();
            //worker.start({
            //onUnhandledRequest(req: any) {
            //if (
            //req.url.host.includes("firestore") ||
            //req.url.host.includes("firebase") ||
            //req.url.host.includes("soundcloud") ||
            //req.url.host.includes("paypal")
            //) {
            //} else {
            //console.warn(
            //`Found an unhandled ${req.method} request to ${req.url.href}`
            //);
            //}
            //},
            //});
            setMocksInitialized(true);
        };
        if (window.location.hostname === "localhost" && mocksEnabled === "true") {
            initWithMocks();
        }
    }, [mocksEnabled]);
    //const { worker } = require("./mocks/browser");
    //worker.start({
    //onUnhandledRequest(req: any) {
    //if (
    //req.url.host.includes("firestore") ||
    //req.url.host.includes("firebase") ||
    //req.url.host.includes("soundcloud") ||
    //req.url.host.includes("paypal")
    //) {
    //} else {
    //console.warn(
    //`Found an unhandled ${req.method} request to ${req.url.href}`
    //);
    //}
    //},
    //});

    if (window.location.hostname === "localhost" && !mocksInitialized && mocksEnabled === "true") {
        return <CircularProgress></CircularProgress>;
    }

    return <Frame></Frame>;
};

export default App;
