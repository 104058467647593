import { Box, ButtonBase, Divider, Typography } from "@mui/material";
import EventData from "../../../../types/EventData";

type Props = {
    event: EventData;
    onClick?: () => void;
    showDivider?: boolean;
};

const PoweredBy = ({ event, onClick, showDivider }: Props) => {
    return (
        <ButtonBase
            sx={{
                mt: 2,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                width: "100%",
                mb: 1,

                marginTop: "auto",
            }}
            onClick={() => {
                if (onClick) onClick();
            }}
        >
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                }}
            >
                {showDivider === undefined || showDivider === true ? (
                    <Divider
                        sx={{
                            width: "100%",
                            backgroundColor: "text.primary",
                        }}
                    ></Divider>
                ) : null}
                <Box
                    sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                    }}
                >
                    <Box component={"img"} alt="event image header" src={event.company.icon} sx={{ maxHeight: 30, mt: 2 }}></Box>
                </Box>

                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        mt: 2,
                    }}
                >
                    <Typography mt={1}>Powered By</Typography>
                    <Box component={"img"} alt="artegirty header" src={"/images/logos/artegrity_logo.png"} sx={{ maxHeight: 30, ml: 1 }}></Box>
                </Box>
            </Box>
        </ButtonBase>
    );
};

export default PoweredBy;
