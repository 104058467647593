import { ArrowBackIos, Google, Mail } from "@mui/icons-material";
import { Box, Button, ButtonBase, CircularProgress, Container, Divider, Link, TextField, ThemeProvider, Typography } from "@mui/material";
import {
    GoogleAuthProvider,
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
} from "firebase/auth";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EventContext } from "../../contexts/EventContextProvider";
import { UserContext } from "../../contexts/UserContext";
import { auth } from "../../firebase";
import { artegrityBrightTheme } from "../../themes/artegrityBrightTheme";
import UserComponent from "./UserComponent";

const Account = () => {
    const { event } = React.useContext(EventContext);
    const userContext = React.useContext(UserContext);
    const { t } = useTranslation();
    const [name, setName] = React.useState("");
    const [isSignIn, setIsSignIn] = React.useState<boolean>(true);
    const [isPasswordForgot, setIsPasswordForgot] = React.useState<boolean>(false);
    const [error, setError] = React.useState("");
    const navigate = useNavigate();

    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");

    const signInWithGoogle = async () => {
        const provider = new GoogleAuthProvider();
        const user = await signInWithPopup(auth, provider).catch((error) => {
            setError(error.message);
        });
        if (user) {
            navigateBack();
        }
    };

    const signUpWithEmail = async () => {
        const user = await createUserWithEmailAndPassword(auth, email, password).catch((error: any) => {
            setError(error.message);
        });
        if (user) {
            navigateBack();
        }
    };

    const signInWithEmail = async () => {
        let user = await signInWithEmailAndPassword(auth, email, password).catch((error) => {
            setError(error.message);
        });
        if (user) {
            navigateBack();
        }
    };

    const handlePasswordReset = async () => {
        await sendPasswordResetEmail(auth, email).catch((error) => {
            setError(error.message);
        });
    };

    if (!event) {
        return <CircularProgress></CircularProgress>;
    }

    const navigateBack = () => {
        navigate(`/v0/${event.id}/eventRoom`);
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 2, backgroundColor: "background.default" }}>
            {event ? (
                <Button
                    onClick={() => {
                        navigateBack();
                    }}
                    variant="contained"
                    sx={{ mb: 2 }}
                >
                    <ArrowBackIos sx={{ width: 20, height: 20 }}></ArrowBackIos>
                    <Typography color={{ color: "text.main" }}>{t("back")}</Typography>
                </Button>
            ) : null}
            <ThemeProvider theme={artegrityBrightTheme}>
                <Box
                    sx={{
                        backgroundColor: "background.default",
                        p: 2,
                        borderRadius: 5,
                        border: 1,
                        borderColor: "divider",
                        boxShadow: 1,
                    }}
                >
                    {userContext && userContext.user && userContext.user.providerId !== "firebase" ? (
                        <UserComponent></UserComponent>
                    ) : (
                        <Box
                            sx={{
                                alignItems: "center",
                                display: "flex",
                                flexDirection: "column",
                            }}
                            component="form"
                            autoComplete="off"
                            onSubmit={(e) => {
                                e.preventDefault();
                                if (isSignIn && !isPasswordForgot) {
                                    signInWithEmail();
                                } else if (isSignIn && isPasswordForgot) {
                                    handlePasswordReset();
                                } else if (!isSignIn) {
                                    signUpWithEmail();
                                }
                            }}
                        >
                            <Box
                                sx={{
                                    mt: 2,
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center",
                                    width: "100%",
                                    justifyContent: "center",
                                }}
                            >
                                <ButtonBase
                                    onClick={() => {
                                        setIsSignIn(true);
                                        setIsPasswordForgot(false);
                                    }}
                                >
                                    <Typography fontSize={25} variant="h2" fontWeight={isSignIn ? "bold" : "medium"}>
                                        Sign In
                                    </Typography>
                                </ButtonBase>
                                <Divider orientation="vertical" flexItem sx={{ mr: 1, ml: 1, borderWidth: 1 }}></Divider>
                                <ButtonBase
                                    onClick={() => {
                                        setIsSignIn(false);
                                        setIsPasswordForgot(false);
                                    }}
                                >
                                    <Typography fontWeight={!isSignIn ? "bold" : "medium"} fontSize={25} variant="h2">
                                        Sign Up
                                    </Typography>
                                </ButtonBase>
                            </Box>
                            <Typography mt={2}>{t("login to view your backstage pass content")}</Typography>
                            {!isSignIn ? (
                                <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} fullWidth margin="normal" />
                            ) : null}

                            <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} fullWidth margin="normal" />
                            {!isPasswordForgot ? (
                                <TextField
                                    label="Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    type="password"
                                    fullWidth
                                    margin="normal"
                                />
                            ) : null}
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                {isSignIn && !isPasswordForgot ? <Button onClick={() => setIsPasswordForgot(true)}>Forgot Password?</Button> : null}
                                {isSignIn && !isPasswordForgot ? (
                                    <Button
                                        onClick={() => {
                                            setIsSignIn(false);
                                            setIsPasswordForgot(false);
                                        }}
                                    >
                                        or Sign Up
                                    </Button>
                                ) : null}
                                {!isSignIn && !isPasswordForgot ? (
                                    <Button
                                        onClick={() => {
                                            setIsSignIn(true);
                                            setIsPasswordForgot(false);
                                        }}
                                    >
                                        or Sign In
                                    </Button>
                                ) : null}
                            </Box>
                            {isSignIn && isPasswordForgot ? (
                                <Button
                                    sx={{ mt: 1, mb: 1 }}
                                    variant="contained"
                                    onClick={() => {
                                        handlePasswordReset();
                                        alert("Password Reset Email Send!");
                                    }}
                                >
                                    Reset Password
                                </Button>
                            ) : null}
                            {isPasswordForgot ? <Button onClick={() => setIsPasswordForgot(false)}>Back to Sign In</Button> : null}
                            {!isSignIn ? (
                                <Button onClick={signUpWithEmail} variant="contained" color="primary" sx={{ mt: 1 }}>
                                    <Mail style={{ color: "white" }}></Mail>
                                    <Typography color="text" sx={{ ml: 2 }}>
                                        Sign Up with Email
                                    </Typography>
                                </Button>
                            ) : isPasswordForgot ? null : (
                                <Button onClick={signInWithEmail} variant="contained" color="primary" sx={{ mt: 1 }} type="submit">
                                    <Mail style={{ color: "white" }}></Mail>
                                    <Typography color="text" sx={{ ml: 2 }}>
                                        Sign In with Email
                                    </Typography>
                                </Button>
                            )}
                            <Typography variant="body2" color={"red"}>
                                {error}
                            </Typography>
                            <Typography fontSize={25} sx={{ mt: 2, mb: 2 }}>
                                Or Sign in with:
                            </Typography>

                            <Button onClick={signInWithGoogle} variant="contained" color="primary" type="submit">
                                <Google style={{ color: "white" }}></Google>
                                <Typography color={"text"} sx={{ ml: 2 }}>
                                    Sign In with Google
                                </Typography>
                            </Button>

                            <Typography mt={4} fontSize={12}>
                                *By signing up you agree with Artegrity's{" "}
                                <Link
                                    target="_blank"
                                    href="https://psychedelic-hardboard-ee4.notion.site/Terms-and-Conditions-1a7d2122d8ac41d99a0c4038bc11aa16"
                                >
                                    Terms and Conditions
                                </Link>
                            </Typography>
                        </Box>
                    )}
                </Box>
            </ThemeProvider>
        </Container>
    );
};

export default Account;
