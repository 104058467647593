import i18n from "i18next";
import HttpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import { LanguageText } from "./types/bll";

export const getLanguageText = (t: LanguageText, languageCode?: string): string => {
    // If t is not a string, it should be an object with language keys
    let currentLanguage;
    if (!languageCode) {
        currentLanguage = i18n.language as keyof LanguageText;
    } else {
        currentLanguage = languageCode as keyof LanguageText;
    }
    if (typeof t === "object") {
        return t[currentLanguage] || "";
    }
    return t as string;
    // Check if the object has a key for the current language
};

i18n.use(HttpBackend)
    .use(initReactI18next)
    .init({
        fallbackLng: "en",
        debug: true,
        interpolation: {
            escapeValue: false, // React already safes from XSS
        },
        resources: {
            en: {
                translation: {
                    "change email": "Change Email",
                    "your performer profile": "Go to your Performer Profile",
                    "Cookie consent status": "Cookie Consent Status",
                    cookies: "This website uses cookies to improve your user experience!",
                    "cookie accept": "Accept",
                    "cookie decline": "Decline",
                    accepted: "Accepted",
                    declined: "Declined",
                    comments: "Comments",
                    "make a comment": "Please feel free to leave a comment and share your experience!",
                    "only available in": "Only available in",
                    credits: "Credits",
                    languageName: "English",
                    "login to save your backstage pass to your account": "Login to save your Backstage Pass to your account.",
                    "backstagepass benefits":
                        "You are currently using the backstagepass for the event {{event_title}}, with the backstagepass you can interact with this event.",
                    "go to original event page": "Go to original event page.",
                    "connect your backstage pass":
                        "Connect your Backstage Pass you get when buying the ticket for {{event_title}} to interact with the event.",
                    "visiting the digital backstage": "Currently you are visiting the digital backstage of {{event_title}}.",
                    "visiting the event room": "Currently you are visiting the event room of {{event_title}}.",
                    "artist room": "Artist Room",
                    ticketShop: "Ticket Shop",
                    backstage: "Backstage",
                    information: "Information",
                    eventRoom: "Event Room",
                    admin: "Admin",
                    "using backstage pass": "Using Backstage Pass",
                    "go to event room": "Go to Event Room",
                    "go to backstage": "Go to Backstage",
                    "buy tickets": "Buy Tickets",
                    "you are succesfully signed in": "You are successfully signed in!",
                    "delete account": "Delete Account",
                    hi: "Hi",
                    "login to view your backstage pass content": "Login with your Artegrity Account to view your Backstage Pass content!",
                    back: "Back",
                },
            },
            de: {
                translation: {
                    "change email": "E-Mail ändern",
                    "your performer profile": "Gehe zu deinem Performer Profil",
                    "Cookie consent status": "Cookie Verwendung",
                    cookies: "Diese Website verwendet Cookies, um Ihre Benutzererfahrung zu verbessern!",
                    "cookie accept": "Akzeptieren",
                    "cookie decline": "Ablehnen",
                    accepted: "Akzeptiert",
                    declined: "Abgelehnt",
                    comments: "Kommentare",
                    "make a comment": "Bitte hinterlassen Sie einen Kommentar und teilen Sie Ihre Erfahrungen!",
                    "only available in": "Nur verfügbar in",
                    credits: "Mitwirkende",
                    languageName: "Deutsch",
                    "login to save your backstage pass to your account": "Melden Sie sich an, um Ihren Backstage-Pass auf Ihrem Konto zu speichern.",
                    "backstagepass benefits":
                        "Sie nutzen derzeit den Backstage-Pass für das Event {{event_title}}. Mit dem Backstage-Pass können Sie mit diesem Event interagieren.",
                    "go to original event page": "Zur ursprünglichen Veranstaltungsseite gehen.",
                    "connect your backstage pass":
                        "Verbinden Sie Ihren Backstage-Pass, den Sie beim Kauf des Tickets für {{event_title}} erhalten, um mit dem Event zu interagieren.",
                    "visiting the digital backstage": "Derzeit besuchen Sie den digitalen Backstage-Bereich von {{event_title}}.",
                    "visiting the event room": "Derzeit besuchen Sie den Veranstaltungsraum von {{event_title}}.",
                    "artist room": "Backstage",
                    back: "Zurück",
                    ticketShop: "Ticketshop",
                    backstage: "Backstage",
                    information: "Information",
                    eventRoom: "Event",
                    admin: "Administrator",
                    "using backstage pass": "Mit Backstage-Pass",
                    "go to event room": "Zum Veranstaltungsraum gehen",
                    "go to backstage": "Zum Backstage gehen",
                    "buy tickets": "Tickets kaufen",
                    "you are succesfully signed in": "Sie haben sich erfolgreich angemeldet!",
                    "delete account": "Konto löschen",
                    hi: "Hallo",
                    "login to view your backstage pass content":
                        "Melden Sie sich mit ihrem Artegrity Konto an, um Ihren Backstage-Pass-Inhalt zu sehen!",
                },
            },
            sv: {
                translation: {
                    "Cookie consent status": "Cookie Användning",
                    cookies: "Denna webbplats använder cookies för att förbättra din användarupplevelse!",
                    "cookie accept": "Acceptera",
                    "cookie decline": "Avböj",
                    accepted: "Accepterad",
                    declined: "Avböjd",
                    comments: "Kommentater",
                    "make a comment": "Lämna gärna en kommentar eller bild och dela din upplevelse med oss!",
                    "only available in": "Endast tillgänglig i",
                    credits: "Vi har gjort föreställningen",
                    languageName: "Svenska",
                    "login to save your backstage pass to your account": "Logga in för att spara ditt backstagepass till ditt konto.",
                    "backstagepass benefits":
                        "Du använder för närvarande backstagepasset för evenemanget {{event_title}}. Med backstagepasset kan du interagera med detta evenemang.",
                    "go to original event page": "Gå till den ursprungliga evenemangssidan.",
                    "connect your backstage pass": "Anslut ditt backstagepass för att interagera med evenemanget för {{event_title}}",
                    "visiting the digital backstage": "För närvarande besöker du den digitala backstage av {{event_title}}.",
                    "visiting the event room": "För närvarande besöker du evenemangsrummet för {{event_title}}.",
                    "artist room": "Artist Room",
                    back: "Tillbaka",
                    ticketShop: "Köp biljetter",
                    backstage: "Backstage",
                    information: "Information",
                    eventRoom: "Event Room",
                    admin: "Admin",
                    "using backstage pass": "Backstagepass",
                    "go to event room": "Gå till Event Room",
                    "go to backstage": "Gå till Backstage",
                    "buy tickets": "Köp biljetter",
                    "you are succesfully signed in": "Du har loggat in!",
                    "delete account": "Radera konto",
                    hi: "Hej",
                    "login to view your backstage pass content": "Logga in med ditt Artegrity-konto för att se ditt Backstagepass innehåll!",
                },
            },
            fi: {
                translation: {
                    "Cookie consent status": "Evästeiden käyttö",
                    cookies: "Tämä sivusto käyttää evästeitä parantaakseen käyttäjäkokemustasi!",
                    "cookie accept": "Hyväksy",
                    "cookie decline": "Hylkää",
                    accpeted: "Hyväksytty",
                    declined: "Hylätty",
                    comments: "Kommentteja",
                    "make a comment": "Saat mielellään jakaa kokemuksesi kanssamme jättämällä kommentin tai kuvan!",
                    "only available in": "Vain saatavilla",
                    credits: "Tekijät",
                    languageName: "Suomi",
                    "login to save your backstage pass to your account": "Kirjaudu sisään tallentaaksesi Backstage-passisi tilillesi.",
                    "backstagepass benefits":
                        "Käytät tällä hetkellä backstagepassia tapahtumalle {{event_title}}. Backstagepassin avulla voit olla vuorovaikutuksessa tämän tapahtuman kanssa.",
                    "go to original event page": "Mene alkuperäiselle tapahtumasivulle.",
                    "connect your backstage pass":
                        "Nähdäksesi enemmän yksinoikeudellista sisältöä, yhdistä Backstage lippusi, jonka saat ostamalla lipun tapahtumaan {{event_title}}, jotta voit olla vuorovaikutuksessa tapahtuman kanssa.",
                    "visiting the digital backstage": "Vierailet tällä hetkellä {{event_title}} tapahtuman digitaalisessa backstagessa.",
                    "visiting the event room": "Vierailet tällä hetkellä {{event_title}} tapahtuman tapahtumatilassa.",
                    "artist room": "Taiteilijahuone",
                    back: "Takaisin",
                    ticketShop: "Lippukauppa",
                    backstage: "Backstage",
                    information: "Tiedot",
                    eventRoom: "Tapahtumatila",
                    admin: "Ylläpito",
                    "using backstage pass": "Käyttäen Backstage lippua",
                    "go to event room": "Mene tapahtumatilaan",
                    "go to backstage": "Mene Backstagelle",
                    "buy tickets": "Osta lippuja",
                    "you are succesfully signed in": "Olet onnistuneesti kirjautunut sisään!",
                    "delete account": "Poista tili",
                    hi: "Hei",
                    "login to view your backstage pass content": "Kirjaudu sisään Artegrity-tililläsi nähdäksesi Backstage-passisi sisällön!",
                },
            },
        },
        // additional options like backend load path can be added here
    });

export default i18n;
