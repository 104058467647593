import { SvgIcon, SvgIconProps } from "@mui/material";

export const Balls = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 247 247">
            <circle cx="117" cy="23" r="22" fill="#AD4594" stroke="black" stroke-width="2" />
            <path d="M115.5 5C115.5 5 123 3.50001 129 10.5" stroke="black" stroke-width="2" stroke-linecap="round" />
            <circle cx="131" cy="14" r="1" fill="black" />
            <circle cx="218" cy="91" r="22" fill="#AD4594" stroke="black" stroke-width="2" />
            <path d="M216.5 73C216.5 73 224 71.5 230 78.5" stroke="black" stroke-width="2" stroke-linecap="round" />
            <circle cx="232" cy="82" r="1" fill="black" />
            <circle cx="185" cy="203" r="22" fill="#AD4594" stroke="black" stroke-width="2" />
            <path d="M183.5 185C183.5 185 191 183.5 197 190.5" stroke="black" stroke-width="2" stroke-linecap="round" />
            <circle cx="199" cy="194" r="1" fill="black" />
            <circle cx="26" cy="99" r="22" fill="#AD4594" stroke="black" stroke-width="2" />
            <path d="M24.5 81C24.5 81 32.0001 79.5 38 86.5" stroke="black" stroke-width="2" stroke-linecap="round" />
            <circle cx="40" cy="90" r="1" fill="black" />
            <circle cx="64" cy="207" r="22" fill="#AD4594" stroke="black" stroke-width="2" />
            <path d="M62.5 189C62.5 189 70.0001 187.5 76 194.5" stroke="black" stroke-width="2" stroke-linecap="round" />
            <circle cx="78" cy="198" r="1" fill="black" />
        </SvgIcon>
    );
};
