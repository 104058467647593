import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import { Box, CircularProgress, FormControlLabel, IconButton, Switch, Typography } from "@mui/material";
import { arrayRemove, arrayUnion, doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect, useMemo, useState } from "react";
import { mediaInteraction } from "../../../../analytics/Posthog";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { useAuth } from "../../../../contexts/UserContext";
import { privateLog } from "../../../../dev/PrivateConsole";
import { db } from "../../../../firebase";
import { ContentElementProps, LineUpData } from "../../../../types/bll";
import LineUpComponent from "./LineUpComponent";

const LineUp = (props: ContentElementProps) => {
    const [selectedStage, setSelectedStage] = React.useState<number>(0);
    const [selectedDay, setSelectedDay] = React.useState<number>(0);

    const { lineUpElements, stages, days } = props.contentElement.data as LineUpData;
    const sortedLineUpElements = lineUpElements.sort((a, b) => {
        let cA = a.day * 1000000 + Number(a.startTime.split(":")[0]) * 10000 + Number(a.startTime.split(":")[1]) * 100;
        let cB = b.day * 1000000 + Number(b.startTime.split(":")[0]) * 10000 + Number(b.startTime.split(":")[1]) * 100;
        return cA - cB;
    });

    const [userLineUpIds, setUserLineUpIds] = React.useState<string[]>([]);
    const { event } = React.useContext(EventContext);
    const [mediaInteractionSent, setMediaInteractionSent] = React.useState<boolean>(false);

    // Step 2: Use useMemo for allStages and allDays
    const allStages = useMemo(() => ["All Stages"].concat(stages), [stages]);
    const allDays = useMemo(() => ["All Days"].concat(days.map((day) => String(day))), [days]);

    const userContext = useAuth();

    privateLog("log", "LineUp", userContext);
    // Inside your component
    const [isPersonalLineUp, setIsPersonalLineUp] = useState(false);

    // Toggle handler
    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsPersonalLineUp(event.target.checked);
    };

    useEffect(() => {
        if (userContext.backstagePassVerified && userContext.user?.uid && event?.id) {
            const userRef = doc(db, "users", userContext.user.uid);

            // Check if the user document exists
            getDoc(userRef).then((docSnapshot) => {
                if (!docSnapshot.exists()) {
                    // If the user document does not exist, create it with the role 'user'
                    privateLog("log", "LineUp", "User document does not exist, creating it with role 'user'");
                    setDoc(userRef, { role: "user" })
                        .then(() => {
                            console.log("User document created with role 'user'.");
                        })
                        .catch((error) => {
                            console.error("Error creating user document: ", error);
                        });
                }
            });
            const userEventRef = doc(db, "users", userContext.user.uid, "events", event.id);
            // Assuming you want to listen to a specific document, not a collection

            // Listen for changes to the specific event document
            const unsubscribeEvent = onSnapshot(userEventRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    // If the event document exists, update the state/context with the retrieved lineup
                    const lineup = docSnapshot.data().lineUp;
                    privateLog("log", "LineUp", "Event document exists, updating lineup", docSnapshot.data());
                    setUserLineUpIds(lineup);
                } else {
                    privateLog("log", "LineUp", "Event document does not exist, creating it with an empty lineup");
                    // If the event document does not exist, create it with an empty lineup
                    setDoc(userEventRef, { lineup: [] })
                        .then(() => {
                            console.log("Event document successfully created with an empty lineup.");
                            setUserLineUpIds([]);
                        })
                        .catch((error) => console.error("Error creating event document: ", error));
                }
            });
            // Cleanup subscription on unmount
            return () => unsubscribeEvent();
        }
    }, [event, userContext.backstagePassVerified, userContext.user?.uid]);
    if (!event) {
        return <CircularProgress></CircularProgress>;
    }

    const handleLineUpCicked = () => {
        if (mediaInteractionSent) return;
        mediaInteraction(props.contentElement.docId);
        setMediaInteractionSent(true);
    };

    const addLineUpElement = async (memberId: string) => {
        if (userContext.backstagePassVerified && userContext.user?.uid && event.id) {
            const userEventRef = await doc(db, "users", userContext.user?.uid, "events", event.id);
            updateDoc(userEventRef, { lineUp: arrayUnion(memberId) });
        }
    };

    const removeLineUpElement = async (memberId: string) => {
        if (userContext.backstagePassVerified && userContext.user?.uid && event.id) {
            const userEventRef = await doc(db, "users", userContext.user?.uid, "events", event.id);
            updateDoc(userEventRef, { lineUp: arrayRemove(memberId) });
        }
    };
    privateLog("log", "LineUp", "User LineUp", userLineUpIds);
    return (
        <Box
            sx={{
                borderRadius: 5,
                overflow: "hidden",
                backgroundColor: "background.default",
                p: 2,
                width: "100%",
            }}
        >
            <Box
                sx={{
                    height: "60vh",
                    width: "100%",
                    overflowY: "auto",
                }}
            >
                {sortedLineUpElements
                    .filter((elem) => {
                        if (
                            (selectedDay === 0 || String(elem.day) === allDays[selectedDay]) &&
                            (selectedStage === 0 || elem.stage === allStages[selectedStage])
                        ) {
                            if (isPersonalLineUp) {
                                if (userLineUpIds && userLineUpIds.includes(elem.memberId)) {
                                    return true;
                                }
                                return false;
                            }
                            return true;
                        }
                        return false;
                    })
                    .map((element, ind) => {
                        return (
                            <LineUpComponent
                                isLoggedIn={userContext.backstagePassVerified !== undefined}
                                isAdded={userLineUpIds && userLineUpIds.includes(element.memberId)}
                                addElement={addLineUpElement}
                                removeElement={removeLineUpElement}
                                key={ind}
                                lineUpElement={element}
                                handleLineUpCicked={handleLineUpCicked}
                                stage={selectedStage === 0 ? element.stage : undefined}
                                day={selectedDay === 0 ? element.day : undefined}
                            ></LineUpComponent>
                        );
                    })}
            </Box>
            <Box sx={{ mt: 1, display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between" }}>
                <Box sx={{ display: "flex", flexDirection: "column", alignItems: "start" }}>
                    <Typography variant="h2" textAlign={"start"}>
                        Line Up
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                disabled={userContext?.backstagePassVerified ? false : true}
                                checked={isPersonalLineUp}
                                onChange={handleToggleChange}
                            />
                        }
                        label="Personalized"
                    />
                    {!userContext.backstagePassVerified && (
                        <Typography variant="body2" textAlign={"left"}>
                            Sign In to create your own LineUp
                        </Typography>
                    )}
                </Box>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            width: 200,
                            justifyContent: "space-around",
                        }}
                    >
                        <Box sx={{ width: 50 }}>
                            <IconButton sx={{ display: selectedStage === 0 ? "none" : "" }} onClick={() => setSelectedStage((curr) => curr - 1)}>
                                <ArrowBackIos color="primary"></ArrowBackIos>
                            </IconButton>
                        </Box>
                        <Typography sx={{ minWidth: 70 }}>{allStages[selectedStage]}</Typography>
                        <Box sx={{ width: 50 }}>
                            <IconButton
                                sx={{ display: selectedStage === allStages.length - 1 ? "none" : "" }}
                                onClick={() => setSelectedStage((curr) => curr + 1)}
                            >
                                <ArrowForwardIos color="primary"></ArrowForwardIos>
                            </IconButton>
                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", width: 200, justifyContent: "space-around" }}>
                        <Box sx={{ width: 50 }}>
                            <IconButton sx={{ display: selectedDay === 0 ? "none" : "" }} onClick={() => setSelectedDay((curr) => curr - 1)}>
                                <ArrowBackIos color="primary"></ArrowBackIos>
                            </IconButton>
                        </Box>
                        <Typography sx={{ minWidth: 70 }}>
                            {selectedDay === 0
                                ? "All Days"
                                : new Date(new Date().getFullYear(), new Date().getMonth(), Number(allDays[selectedDay])).toLocaleDateString(
                                      "en-US",
                                      {
                                          weekday: "long",
                                      }
                                  )}
                        </Typography>
                        <Box sx={{ width: 50 }}>
                            <IconButton
                                sx={{ display: selectedDay === allDays.length - 1 ? "none" : "" }}
                                onClick={() => setSelectedDay((curr) => curr + 1)}
                            >
                                <ArrowForwardIos color="primary"></ArrowForwardIos>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default LineUp;
