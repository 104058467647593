import { Box } from "@mui/material";
import { changeLanguage } from "i18next";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { EventContextProvider } from "../contexts/EventContextProvider";
import { FrameContextProvider } from "../contexts/FrameContext";
import { UserProvider } from "../contexts/UserContext";
import { privateLog } from "../dev/PrivateConsole";
import RouteMapping from "../routes/RouteMapping";
import UnsubscribePromotional from "../features/unsubscribe/UnsubscribePromotional";
import UnsubscribeBackstage from "../features/unsubscribe/UnsubscribeBackstage";

export const Frame = () => {
    useEffect(() => {
        const url = new URL(window.location.href);
        const urlSearchParams = new URLSearchParams(window.location.search);
        const language = urlSearchParams.get("language");

        privateLog("log", "Frame.tsx: ", url, urlSearchParams, language);

        if (language) {
            privateLog("info", "debug setting language: ", language);

            changeLanguage(language);
        } else {
            privateLog("debug setting language to default");
        }

        // Remove the eventId from the search parameters
        //TDOO this should not be hardcoded
        if (urlSearchParams.has("eventId") && !url.pathname.includes("unsubscribe")) {
            const eventId = urlSearchParams.get("eventId");
            urlSearchParams.delete("eventId");
            url.pathname = `/v0/${eventId}/backstage`;

            // Set the modified search parameters on the URL
            url.search = urlSearchParams.toString();

            // Change the URL using window.location
            window.location.href = url.toString();
        }
    });
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    path="/v0/:eventId/unsubscribeBackstage"
                    element={
                        <EventContextProvider>
                            <UnsubscribeBackstage></UnsubscribeBackstage>
                        </EventContextProvider>
                    }
                ></Route>
                <Route
                    path="/v0/:eventId/unsubscribePromotional"
                    element={
                        <EventContextProvider>
                            <UnsubscribePromotional></UnsubscribePromotional>
                        </EventContextProvider>
                    }
                ></Route>
                <Route
                    path="/v0/:eventId/*"
                    element={
                        <EventContextProvider>
                            <UserProvider>
                                <FrameContextProvider>
                                    <Box sx={{ background: "background.default" }}>
                                        <Box sx={{ minHeight: "100vh" }}>
                                            <RouteMapping></RouteMapping>
                                        </Box>
                                    </Box>
                                </FrameContextProvider>
                            </UserProvider>
                        </EventContextProvider>
                    }
                ></Route>
            </Routes>
        </BrowserRouter>
    );
};
