import { Box, Button, Divider, Grid, ThemeProvider, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React from "react";
import Chart from "react-google-charts";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { privateLog } from "../../../../dev/PrivateConsole";
import { functions } from "../../../../firebase";
import { OrderSummaryResponse, TicketsSoldByPrice, TicketsSoldPerDay } from "../../../../types/dto";

const TicketSummary = () => {
    const { brightTheme, event } = React.useContext(EventContext);
    const [data, setData] = React.useState<OrderSummaryResponse>();
    const [open, setOpen] = React.useState(false);
    const getTodaysDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
        let day = today.getDate().toString().padStart(2, "0");
        return `${year}-${month}-${day}`;
    };
    const todaysDate = getTodaysDate();
    const getYesterdaysDate = () => {
        const yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);

        const year = yesterday.getFullYear();
        const month = (yesterday.getMonth() + 1).toString().padStart(2, "0");
        const day = yesterday.getDate().toString().padStart(2, "0");

        return `${year}-${month}-${day}`;
    };
    const yesterdaysDate = getYesterdaysDate();

    const getTicketsSoldTrend = (ticketsSoldPerDay: TicketsSoldPerDay) => {
        const todaysSales = ticketsSoldPerDay[todaysDate] || 0;
        const yesterdaysSales = ticketsSoldPerDay[yesterdaysDate] || 0;

        let percentageChange = 0;

        if (yesterdaysSales === 0 && todaysSales > 0) {
            // If there were no sales yesterday but there are sales today
            percentageChange = todaysSales * 100;
        } else if (todaysSales === 0 && yesterdaysSales > 0) {
            // If there are no sales today but there were sales yesterday
            percentageChange = -yesterdaysSales * 100;
        } else if (yesterdaysSales > 0) {
            // Normal calculation when there were sales on both days
            percentageChange = ((todaysSales - yesterdaysSales) / yesterdaysSales) * 100;
        } else if (yesterdaysSales === 0 && todaysSales === 0) {
            percentageChange = 0;
        }

        return percentageChange;
    };

    React.useEffect(() => {
        if (event && !data) {
            const getTicketSummary = httpsCallable(functions, "pilotbe-getOrderSummary");
            privateLog("debug", "Getting Ticket Summary for EventId: ", event.id);
            getTicketSummary({
                eventId: event.id,
            })
                .then((result: any) => {
                    privateLog("debug", "Result Data: ", result.data);

                    if (result.data) {
                        setData(result.data); // Set state with the new data
                    }
                })
                .catch((error) => {
                    privateLog("error", error); // Handle the error
                });
        }
    }, [event, data]);

    const getPieChartData = (ticketsSoldByPrice: TicketsSoldByPrice) => {
        const chartData = Object.entries(ticketsSoldByPrice).map(([price, count]) => [`${price} €`, count]);
        const extendedChartData = [["Price", "Tickets Sold"], ...chartData];
        return extendedChartData;
    };

    const getLineChartData = (ticketsSoldPerDay: TicketsSoldPerDay) => {
        let dateEntry = Object.entries(ticketsSoldPerDay).map(([date, count]) => [new Date(date), count]);
        dateEntry.sort((a, b) => (a[0] as Date).getTime() - (b[0] as Date).getTime());
        const chartData = [["Date", "Tickets Sold"], ...dateEntry];
        return chartData;
    };
    const calculateTotalTicketsSold = (ticketsSoldPerDay: TicketsSoldPerDay) => {
        return Object.values(ticketsSoldPerDay).reduce((total, currentDaySales) => total + currentDaySales, 0);
    };

    return (
        <ThemeProvider theme={brightTheme}>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    mt: 2,
                    borderRadius: 5,
                    p: 2,
                }}
            >
                <Typography variant="h2">Ticket Shop Analytics</Typography>
                {data ? (
                    <Box sx={{ mt: 2 }}>
                        <Box
                            sx={{
                                display: "flex",
                                width: "100%",
                                flexDirection: "row",
                                justifyContent: "space-around",
                            }}
                        >
                            <Grid container spacing={2} justifyContent="space-around">
                                <Grid item xs={6}>
                                    <Typography>Total earnings:</Typography>
                                    <Typography>{(data.total as number).toFixed(2)} €</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Total earnings after Fees:</Typography>
                                    <Typography fontWeight={"bold"}>{(data.totalAfterFees as number).toFixed(2)} €</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>Tickets Sold:</Typography>
                                    <Typography>
                                        {"" + calculateTotalTicketsSold(data.ticketsSoldPerDay) + " "}
                                        {"(" + (data.ticketsSoldPerDay[todaysDate] || 0) + ")"}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Typography>1 Day Trend:</Typography>
                                    <Typography color={getTicketsSoldTrend(data.ticketsSoldPerDay) > 0 ? "green" : "orange"}>
                                        {getTicketsSoldTrend(data.ticketsSoldPerDay)} %
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        {open ? (
                            <Box>
                                <Box>
                                    <Divider sx={{ mt: 1, mb: 1 }}></Divider>
                                    <Typography variant="h2">Tickets Sold Per Day</Typography>
                                    <Chart
                                        chartType="LineChart"
                                        width="100%"
                                        height="300px"
                                        data={getLineChartData(data.ticketsSoldPerDay)}
                                        options={{
                                            curveType: "function",
                                            legend: { position: "bottom" },
                                            hAxis: {
                                                title: "Date",
                                                format: "dd/MM/yy",
                                            },
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Divider sx={{ mt: 1, mb: 1 }}></Divider>
                                    <Typography variant="h2">Tickets Sold By Price</Typography>
                                    <Chart
                                        chartType="PieChart"
                                        width="100%"
                                        data={getPieChartData(data.ticketsSoldByPrice)}
                                        options={{
                                            is3D: true,
                                        }}
                                    />
                                </Box>
                                <Button onClick={() => setOpen(false)} variant="outlined" sx={{ mt: 2 }}>
                                    Close Charts
                                </Button>
                            </Box>
                        ) : (
                            <Button onClick={() => setOpen(true)} variant="outlined" sx={{ mt: 2 }}>
                                See Charts
                            </Button>
                        )}
                    </Box>
                ) : null}
            </Box>
        </ThemeProvider>
    );
};

export default TicketSummary;
