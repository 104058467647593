import { Box, Divider, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import { Currency } from "firebase/analytics";
import { TicketCell } from "./ArtegrityTicketCell";
import { ArtegrityTicket, Quantity } from "../../../../types/artegrtiyShopTypes";

type Props = {
    tickets: ArtegrityTicket[];
    setQuantity: (quantity: Quantity) => void;
    currencyCode: Currency;
    vat: number;
};

export const TicketTable = ({ currencyCode, tickets, setQuantity, vat }: Props) => {
    let currChar = getCurrencyCharacter(currencyCode);

    function getCurrencyCharacter(currencyCode: Currency) {
        if (currencyCode === "EUR") {
            return "€";
        } else if (currencyCode === "CHF") {
            return "SFr.";
        }
        return "";
    }

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <TableContainer sx={{ display: "flex", flexDirection: "column" }}>
            <Table aria-label="simple table">
                <TableHead key={"table-head"}>
                    <TableRow key="header-row">
                        <TableCell key="name_row"></TableCell>
                        <TableCell key={"single_price"} align="right">
                            <Typography fontWeight={"bold"}>Single Price&nbsp;</Typography>
                        </TableCell>
                        <TableCell key={"quantity"} sx={{ fontWeight: "bold" }} align="right">
                            <Typography fontWeight={"bold"}>Quantity&nbsp;</Typography>
                        </TableCell>
                        {!isXs ? (
                            <TableCell key={"total"} sx={{ fontWeight: "bold" }} align="right">
                                <Typography fontWeight={"bold"}>Total Price&nbsp;</Typography>
                            </TableCell>
                        ) : null}
                    </TableRow>
                </TableHead>
                <TableBody key={"table-body"}>
                    {tickets.map((ticket) => (
                        <TicketCell
                            currChar={currChar}
                            key={ticket.id + "" + (ticket.groupPricingIndex ? ticket.groupPricingIndex : "")}
                            ticket={ticket}
                            setQuantity={setQuantity}
                        />
                    ))}
                    <TableRow key={"total"} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        <TableCell key={"emtpy1"} component="th" scope="row"></TableCell>
                        <TableCell key={"sum-total-name"} align="right">
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                }}
                            >
                                <Typography textAlign={"left"}>{"Total"}</Typography>
                                <Typography textAlign={"left"} variant="body2">
                                    {"*excluding Service Fee"}
                                </Typography>
                            </Box>
                        </TableCell>
                        {!isXs ? <TableCell key={"emtpy2"} align="right"></TableCell> : null}
                        <TableCell key={"sum-total"} align="right">
                            <Typography>
                                {tickets
                                    .reduce((acc, curr) => {
                                        return acc + curr.price * curr.quantity;
                                    }, 0)
                                    .toFixed(2) + currChar}
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <Divider></Divider>
            {vat > 0 && (
                <Typography variant="body2" sx={{ my: 2, mb: 10, alignSelf: "flex-end" }}>
                    All prices include VAT
                </Typography>
            )}
            <Divider sx={{ mb: 2 }}></Divider>
        </TableContainer>
    );
};
