import { Delete } from "@mui/icons-material";
import { Box, Button, ButtonBase, Link, MenuItem, TextField, ThemeProvider, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { EventContext } from "../../../contexts/EventContextProvider";
import IconSwitcher from "../../../shared/IconSwitcher";
import { IconLink, IconName } from "../../../types/bll";

type Props = {
    socials: IconLink[];
    setNewSocials: (newSocials: IconLink[]) => void;
};

const EditSocials = ({ socials, setNewSocials }: Props) => {
    const { brightTheme } = useContext(EventContext);

    const [newSocialName, setNewSocialName] = useState("");
    const [newSocialLink, setNewSocialLink] = useState("");

    // Step 1: Define the delete function
    const handleDeleteSocial = (index: number) => {
        const updatedSocials = socials.filter((_, socialIndex) => socialIndex !== index);
        setNewSocials(updatedSocials);
    };

    const handleAddSocial = () => {
        const newSocial = { name: newSocialName as IconName, link: newSocialLink } as IconLink;
        setNewSocials([...socials, newSocial]);
        // Reset input fields
        setNewSocialName("");
        setNewSocialLink("");
    };

    return (
        <ThemeProvider theme={brightTheme}>
            <Box sx={{ flexDirection: "column", display: "flex", mb: 2, backgroundColor: "background.default", borderRadius: 5, p: 2 }}>
                <Typography variant="h6">Socials</Typography>
                {socials?.map((social, index) => {
                    return (
                        <Box key={index} sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                            <Link href={social.link} target="_blank" sx={{ display: "flex", flexDirection: "row" }}>
                                <IconSwitcher name={social.name}></IconSwitcher>
                                <Typography ml={1}>{social.link.replace(/\/$/, "").split("/").pop()}</Typography>
                            </Link>
                            <ButtonBase onClick={() => handleDeleteSocial(index)}>
                                <Delete color="primary" sx={{ ml: 2 }}></Delete>
                            </ButtonBase>
                        </Box>
                    );
                })}
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mt: 2, justifyContent: "space-between" }}>
                    <TextField
                        select
                        label="Icon"
                        value={newSocialName}
                        onChange={(e) => setNewSocialName(e.target.value)}
                        sx={{ mr: 2, minWidth: 70 }}
                    >
                        {Object.keys(IconName).map((name) => (
                            <MenuItem key={name} value={name}>
                                <IconSwitcher name={name}></IconSwitcher>
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField label="URL" value={newSocialLink} fullWidth={true} onChange={(e) => setNewSocialLink(e.target.value)} sx={{ mr: 2 }} />
                    <Button variant="contained" onClick={() => handleAddSocial()}>
                        Add
                    </Button>
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default EditSocials;
