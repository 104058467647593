import { Box, CircularProgress, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { privateLog } from "../../../../dev/PrivateConsole";
import { functions } from "../../../../firebase";

const BackstageAnalytics = () => {
    const { event } = React.useContext(EventContext);

    const [countsByDate, setCountsByDate] = useState<{
        [date: string]: { email: number; qr: number };
    }>({});

    const getData = async () => {
        if (event) {
            try {
                const getBackstageAnalyticsData = httpsCallable(functions, "backstageAnalytics-getTicketCodeAnalytics");

                let result = await getBackstageAnalyticsData({ eventId: event.id });
                privateLog("info", "BackstageAnalytics result", result);
                setCountsByDate(result.data as { [date: string]: { email: number; qr: number } });
            } catch (error) {
                console.error("Error getting backstage analytics data:", error);
            }
        }
    };
    useEffect(() => {
        getData();
    }, [event]);

    // Prepare data for the chart
    const chartData = [["Date", "Email", "QR Code"], ...Object.entries(countsByDate).map(([date, counts]) => [date, counts.email, counts.qr])];

    return (
        <Box sx={{ backgroundColor: "background.default", p: 2, borderRadius: 5 }}>
            <Typography variant="h2">Backstage Analytics</Typography>
            <Chart
                width={"100%"}
                height={"300px"}
                chartType="ColumnChart"
                loader={<CircularProgress></CircularProgress>}
                data={chartData}
                options={{
                    hAxis: {
                        title: "Date",
                        minValue: 0,
                    },
                    vAxis: {
                        title: "Total Backstage Users",
                    },
                }}
            />
        </Box>
    );
};

export default BackstageAnalytics;
