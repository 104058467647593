import { Refresh } from "@mui/icons-material";
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import React, { useEffect } from "react";
import Chart from "react-google-charts";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { privateLog } from "../../../../dev/PrivateConsole";
import { functions } from "../../../../firebase";

const CheckIn = () => {
    const { event, brightTheme } = React.useContext(EventContext);
    const [data, setData] = React.useState<any>();
    const [copySuccess, setCopySuccess] = React.useState("");

    const fetchData = () => {
        if (event) {
            const getCheckInSummary = httpsCallable(functions, "pilotbe-getCheckInStatus");
            getCheckInSummary({
                eventId: event.id,
            })
                .then((result: any) => {
                    if (result.data) {
                        privateLog("debug", "Check In Result: ", result.data);
                        setData(result.data); // Set state with the new data
                    }
                })
                .catch((error) => {
                    privateLog("error", error); // Handle the error
                });
        }
    };
    useEffect(() => {
        fetchData();
    }, [event]);

    if (!data) {
        return null;
    }

    const getPieChartData = (data: any) => {
        const ret = [
            ["Ticket Status", "Number"],
            ["Checked In", data.checkedInTickets],
            ["Not Checked In", data.totalTickets - data.checkedInTickets],
        ];
        return ret;
    };
    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(data.checkInCode)
            .then(() => {
                // If the copy was successful
                setCopySuccess("Copied!");
                setTimeout(() => setCopySuccess(""), 2000); // Remove the message after 2 seconds
            })
            .catch(() => {
                // If the copy failed
                setCopySuccess("Failed to copy");
            });
    };

    return (
        <ThemeProvider theme={brightTheme}>
            <Box
                sx={{
                    backgroundColor: "background.default",
                    borderRadius: 5,
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                }}
            >
                <Typography mb={2} variant="h2">
                    Check In
                </Typography>
                {data.checkedInTickets > 0 ? (
                    <Chart
                        chartType="PieChart"
                        width="100%"
                        data={getPieChartData(data)}
                        options={{
                            pieHole: 0.4,
                            slices: {
                                0: { color: "green" },
                                1: { color: "red" },
                            },
                        }}
                    />
                ) : null}
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        minWidth: 180,
                        justifyContent: "space-between",
                    }}
                >
                    <Typography>Total Tickets Sold:</Typography>
                    <Typography ml={1}>{data.totalTickets}</Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "row",
                        minWidth: 180,
                        justifyContent: "space-between",
                    }}
                >
                    <Typography>Checked In Tickets:</Typography>
                    <Typography ml={1}>{data.checkedInTickets}</Typography>
                </Box>
                <Box sx={{ mt: 2 }}>
                    <Typography>Check-In Code: {data.checkInCode}</Typography>
                    <Button onClick={copyToClipboard} variant="outlined" sx={{ ml: 1 }}>
                        Copy to Clipboard
                    </Button>
                    {copySuccess && <Typography sx={{ ml: 1 }}>{copySuccess}</Typography>}
                </Box>
                <Button onClick={() => fetchData()} variant="outlined" sx={{ mt: 2 }}>
                    <Refresh></Refresh>
                </Button>
            </Box>
        </ThemeProvider>
    );
};

export default CheckIn;
