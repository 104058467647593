import {
    BottomNavigation,
    BottomNavigationAction,
    Box,
    Divider,
    DividerProps,
    Theme,
    ThemeProvider,
    createTheme,
    useMediaQuery,
} from "@mui/material";
import React, { SyntheticEvent, useContext, useRef } from "react";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { NavItem } from "../ContentContainer";

const NavBarDivider: React.FC<DividerProps> = (props) => {
    return <Divider key={props.key} orientation={props.orientation} sx={props.sx}></Divider>;
};

interface ContentContainerNavBarProps {
    navItems: NavItem[];
    initIndex: number;
    setNavIndex: (newIndex: number) => void;
    navIndex: number;
}

const ContentContainerNavBar = (props: ContentContainerNavBarProps) => {
    const { navItems, setNavIndex, navIndex } = props;
    const { darkTheme, event } = useContext(EventContext);
    const sentinelRef = useRef(null);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setNavIndex(newValue);
    };

    const buildNavBarItems = () => {
        let extendedNavItems = navItems.slice(0, navItems.length - 1).flatMap((item) => [item, undefined]);
        extendedNavItems.push(navItems[navItems.length - 1]);
        return extendedNavItems;
    };

    const isLg = useMediaQuery<Theme>((theme) => theme.breakpoints.up("lg"));

    const newTheme = createTheme(darkTheme, {
        components: {
            MuiBottomNavigation: {
                styleOverrides: {
                    root: {
                        backgroundColor: darkTheme.palette.background.default,
                        border: "1px solid",
                        borderColor: darkTheme.palette.text.primary,
                    },
                },
            },
            MuiBottomNavigationAction: {
                styleOverrides: {
                    root: {
                        color: darkTheme.palette.text.primary,
                    },
                },
            },
        },
    });

    return (
        <Box
            ref={sentinelRef}
            sx={{
                position: "sticky",
                //top: event?.frame.data.offset,
                //TODO: add
                top: event?.getNavOffset(isLg),
                mt: 1,
                zIndex: 3,
            }}
        >
            <Box sx={{ backgroundColor: "background.default" }}>
                <ThemeProvider theme={newTheme}>
                    <BottomNavigation
                        value={navIndex}
                        onChange={handleChange}
                        sx={{
                            height: "60px",
                            justifyContent: "space-evenly",
                        }}
                    >
                        {buildNavBarItems().map((item, index) => {
                            if (item) {
                                return (
                                    <BottomNavigationAction
                                        key={`action-${index / 2}`}
                                        label={item.label}
                                        value={index / 2}
                                        icon={React.createElement(item.icon, item.navColor ? { sx: { color: item.navColor } } : {})}
                                        sx={{
                                            "& .MuiBottomNavigationAction-label": {
                                                color: item.navColor,
                                            },
                                        }}
                                    ></BottomNavigationAction>
                                );
                            } else {
                                return (
                                    <NavBarDivider
                                        key={`divider-${(index + 1) / 2}`}
                                        orientation="vertical"
                                        sx={{ backgroundColor: "text.primary" }}
                                    />
                                );
                            }
                        })}
                    </BottomNavigation>
                </ThemeProvider>
            </Box>
        </Box>
    );
};

export default ContentContainerNavBar;
