import posthog from "posthog-js";
import { privateLog } from "../dev/PrivateConsole";

export const initPosthog = (withCookie: boolean, eventId: string | null, isBackstage: boolean, analyticsVersion?: number) => {
    if (!(process.env.REACT_APP_POSTHOG_ENABLED === "false")) {
        let debug = false;
        if (process.env.REACT_APP_POSTHOG_ENABLED === "debug") {
            console.log("posthog is in debug mode");
            debug = true;
        }

        console.log(
            "initPosthog with Cookies: ",
            withCookie,
            "with EventId: ",
            eventId,
            "with is Backstage: ",
            isBackstage,
            "with analyticsVersion: ",
            analyticsVersion,
            "analyitcsId: ",
            eventId + "-" + analyticsVersion,
        );

        if (withCookie) {
            console.log("posthog started with cookie");
            posthog.init("phc_R46YCFc3NIAHL5HE3nAWwvNvz3Q0YJPDd74Xk7Zdpv4", {
                api_host: "https://app.posthog.com",
                persistence: "cookie",
                debug: debug,
            });
        } else {
            console.log("posthog started without cookie");
            posthog.init("phc_R46YCFc3NIAHL5HE3nAWwvNvz3Q0YJPDd74Xk7Zdpv4", {
                api_host: "https://app.posthog.com",
                persistence: "localStorage",
                debug: debug,
            });
        }
        if (eventId && isBackstage !== undefined) {
            if (analyticsVersion !== undefined) {
                posthog.register({
                    firebaseEventId: eventId + "-" + analyticsVersion,
                    artegrityPage: isBackstage ? "backstage" : "eventRoom",
                });
            } else {
                posthog.register({
                    firebaseEventId: eventId,
                    artegrityPage: isBackstage ? "backstage" : "eventRoom",
                });
            }
        }
    } else {
        console.log("posthog is disabled");
    }
};

export const mediaInteraction = (docId: string | null | undefined) => {
    if (docId) {
        posthog.capture("media interaction", { docId: docId });
        privateLog("info", "Media Interaction: ", docId);
    }
};

export const mediaShare = (docId: string | null | undefined) => {
    if (docId) posthog.capture("media share", { docId: docId });
};

export const memberInteraction = (memberId: string | null | undefined) => {
    if (memberId) posthog.capture("member interaction", { memberId: memberId });
};
