import { ArrowBackIos, Cancel, Edit, Info, Menu, Save } from "@mui/icons-material";
import { alpha, Avatar, Box, Button, ButtonBase, Link, Typography } from "@mui/material";
import { doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { memberInteraction } from "../../../analytics/Posthog";
import { useAuth } from "../../../contexts/UserContext";
import { privateLog } from "../../../dev/PrivateConsole";
import { db, storage } from "../../../firebase";
import IconSwitcher from "../../../shared/IconSwitcher";
import { purpleTheme } from "../../../themes/purpleTheme";
import { IconLink, LanguageText, Member } from "../../../types/bll";
import { Backstage } from "../Backstage";
import ContentContainer from "../contentContainer/ContentContainer";
import EditSocials from "./EditSocials";
import MemberInformation from "./MemberInformation";

type Props = {
    currMember: Member;
    onClose?: () => void;
};

const MemberComponent = ({ currMember, onClose }: Props) => {
    const { eventId } = useParams();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const navigate = useNavigate();
    const [memberState, setMember] = useState<Member>(currMember);
    const { member, isAdmin, backstagePassVerified } = useAuth();
    const [editing, setEditing] = useState<boolean>(false);
    const [newInformation, setNewInformation] = useState<LanguageText>(memberState.information || "");
    const [newSocials, setNewSocials] = useState<IconLink[]>(memberState.socials || []);
    const { t } = useTranslation();

    const [isBackstage, setIsBackstage] = React.useState<boolean | undefined>(undefined);

    useEffect(() => {
        if (backstagePassVerified === undefined) {
            setIsBackstage(undefined);
            privateLog("info", "Frame setting isBackstage: ", undefined);
        } else if (backstagePassVerified === false) {
            privateLog("info", "Frame setting isBackstage: ", false);
            setIsBackstage(false);
        } else {
            privateLog("info", "Frame setting isBackstage: ", true, "Backstage Pass verified", backstagePassVerified);
            setIsBackstage(true);
        }
    }, [backstagePassVerified]);

    //console.log("No such document!");
    //}
    //}
    //};
    //getMember();
    //}
    //}, [memberState]);

    const [interactionSend, setInteractionSend] = useState<boolean>(false);

    const isMember = member === memberState.id;

    React.useEffect(() => {
        if (interactionSend === false) {
            memberInteraction(memberState.id);
            setInteractionSend(true);
        }
    }, [interactionSend, memberState.id]);

    const navItemsMember = [
        {
            label: backstagePassVerified === true ? t("backstage") : t("artist room"),
            value: "Backstage",
            icon: Menu,
            ...(backstagePassVerified === true ? { navColor: purpleTheme.palette.primary.main } : {}),
            component: <Backstage member={memberState.id} isExclusive={isBackstage !== undefined ? isBackstage : false} />,
        },
        {
            label: t("information"),
            value: "Info",
            icon: Info,
            component: (
                <MemberInformation
                    editing={false}
                    information={memberState.information || ""}
                    setNewInformation={setNewInformation}
                    informationImageUrl={memberState.informationImageUrl}
                />
            ),
        },
    ];

    const onSave = async () => {
        const userDocRef = doc(db, `users/${memberState.id}`);
        const userSnapshot = await getDoc(userDocRef);
        if (userSnapshot.exists()) {
            let newMember = userSnapshot.data() as Member;
            newMember.information = newInformation;
            newMember.id = userSnapshot.id;
            newMember.socials = newSocials;
            privateLog("log", "MemberComponent", "Saving new information", newMember, "userDocRef", userDocRef);
            await setDoc(userDocRef, newMember);
            newMember.imagePath = memberState.imagePath;
            setMember(newMember);
        }
        setEditing(false);
    };

    const uploadNewImage = async () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleNewImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files.length > 0) {
            const file = event.target.files[0];
            const fileRef = ref(storage, `${memberState.id}/profile_picture/${memberState.name}.${file.name.split(".").pop()}`);
            const uploadTask = uploadBytesResumable(fileRef, file);

            uploadTask.on(
                "state_changed",
                (snapshot) => {
                    // You can add logic to show upload progress here
                },
                (error) => {
                    privateLog("error", "Upload failed:", error);
                },
                async () => {
                    privateLog("log", "MemberComponent", "Upload successful:", fileRef.fullPath);

                    // Update the Firestore document with the new imagePath
                    const userDocRef = doc(db, `users/${memberState.id}`);

                    await updateDoc(userDocRef, {
                        imagePath: fileRef.fullPath,
                    });

                    let newUrl = await getDownloadURL(fileRef);

                    setMember((prevState) => ({
                        ...prevState,
                        imagePath: newUrl, // Update the state with the new unique URL
                    }));
                }
            );
        }
    };

    const getEditingButtons = () => {
        if (!isMember && !isAdmin) {
            return null;
        }
        if (!editing) {
            return (
                <Button sx={{ alignSelf: "end" }} onClick={() => setEditing(true)}>
                    <Edit></Edit>
                </Button>
            );
        } else {
            return (
                <Box sx={{ alignSelf: "end", display: "flex", flexDirection: "row" }}>
                    <Button onClick={() => onSave()}>
                        <Save></Save>
                    </Button>
                    <Button onClick={() => setEditing(false)}>
                        <Cancel></Cancel>
                    </Button>
                </Box>
            );
        }
    };

    return (
        <Box sx={{ mt: 2, width: "100%" }}>
            <ButtonBase
                sx={{ mb: 1 }}
                onClick={() => {
                    if (!onClose) navigate(`/v0/${eventId}/backstage`);
                    else onClose();
                }}
            >
                <ArrowBackIos color="primary"></ArrowBackIos>
            </ButtonBase>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                }}
            >
                <Typography variant="h2">{memberState.name}</Typography>
                {getEditingButtons()}
            </Box>

            <ButtonBase
                onClick={() => {
                    uploadNewImage();
                }}
            >
                {isMember || isAdmin ? <input ref={fileInputRef} type="file" hidden accept="image/*" onChange={handleNewImage} /> : null}
                <Avatar
                    key={memberState.imagePath}
                    sx={(theme) => ({
                        mt: 1,
                        width: 120,
                        height: 120,
                        mb: 2,
                        img: {
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "top",
                        },
                        ...(isMember || isAdmin
                            ? {
                                  "&:hover": {
                                      backgroundColor: theme.palette.primary.main,
                                      "&::after": {
                                          content: '""',
                                          position: "absolute",
                                          top: 0,
                                          right: 0,
                                          bottom: 0,
                                          left: 0,
                                          backgroundColor: alpha(theme.palette.primary.main, 0.5),
                                      },
                                  },
                              }
                            : {}),
                    })}
                    src={memberState.imagePath}
                ></Avatar>
            </ButtonBase>
            {!editing && (
                <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", width: "100%" }}>
                    {memberState.socials &&
                        memberState.socials.map((social, index) => {
                            return (
                                <Link key={index} href={social.link} sx={{ ml: index === 0 ? 0 : 1 }} target="_blank">
                                    <Box sx={{ display: "flex", flexDirection: "row" }}>
                                        <IconSwitcher name={social.name}></IconSwitcher>
                                        <Typography ml={1}>{social.link.replace(/\/$/, "").split("/").pop()}</Typography>
                                    </Box>
                                </Link>
                            );
                        })}
                </Box>
            )}
            {editing && <EditSocials setNewSocials={setNewSocials} socials={newSocials}></EditSocials>}
            {editing ? (
                <MemberInformation editing={true} information={newInformation} setNewInformation={setNewInformation}></MemberInformation>
            ) : (
                <ContentContainer navItems={navItemsMember} initIndex={1}></ContentContainer>
            )}
        </Box>
    );
};

export default MemberComponent;
