import { Box, useTheme } from "@mui/material";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import React from "react";

type Props = {
    title: string;
    path: string;
};

const EventRoomFrameHeader = ({ title, path }: Props) => {
    const [downloadUrl, setDownloadUrl] = React.useState("");
    const [isPortrait, setIsPortrait] = React.useState<boolean | undefined>();
    React.useEffect(() => {
        if (!downloadUrl) {
            const storage = getStorage();
            const storageRef = ref(storage, path);
            getDownloadURL(storageRef).then((url) => {
                setDownloadUrl(url);
                const img = new Image();
                img.onload = () => {
                    setIsPortrait(img.height > img.width);
                };
                img.src = url;
            });
        }
    }, [downloadUrl, path]);

    const theme = useTheme();

    const renderImage = () => (
        <img
            src={downloadUrl}
            alt="headerImg"
            style={{
                height: "auto",
                boxShadow: "0px 0px 100px 100px rgba(0,0,0,0.25)",
                objectFit: "contain",
                marginTop: 0, // Adjust marginTop if necessary for non-portrait images
            }}
        />
    );

    return (
        <Box>
            {isPortrait !== undefined &&
                (isPortrait ? (
                    <Box
                        sx={{
                            mt: 2,
                            p: 2, // Padding 2
                            position: "relative",
                            width: "100%",
                            height: 300, // Height of 300
                            backgroundColor: theme.palette.primary.main,
                            display: "flex",
                            justifyContent: "center", // Center the image horizontally
                            overflow: "hidden",
                        }}
                    >
                        {renderImage()}
                    </Box>
                ) : (
                    <img
                        width={"100%"}
                        height={"auto"}
                        src={downloadUrl}
                        alt="headerImg"
                        style={{
                            boxShadow: "0px 0px 150px " + theme.palette.background.default,
                            objectFit: "cover",
                        }}
                    ></img>
                ))}
        </Box>
    );
};

export default EventRoomFrameHeader;
