import { AdminPanelSettings, Chat, ConfirmationNumber, Info, Menu } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { doc, getDoc } from "firebase/firestore";
import React, { ReactElement, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { EventContext } from "../../../contexts/EventContextProvider";
import { useAuth } from "../../../contexts/UserContext";
import { privateLog } from "../../../dev/PrivateConsole";
import { db, getStorageURL } from "../../../firebase";
import { purpleTheme } from "../../../themes/purpleTheme";
import { Member } from "../../../types/bll";
import { Backstage } from "../Backstage";
import AdminContainer from "../admin/AdminContainer";
import Comments from "../comments/Comments";
import TicketShop from "../contentElements/TicketShop";
import { Information } from "../information/Information";
import ContentContainer from "./ContentContainer";

export interface NavItem {
    label: string;
    value: string;
    icon: React.ElementType;
    component: ReactElement;
    navColor?: string;
}

interface ContentContainerProps {
    isBackstage: boolean;
}

const EventRoomContentContainerWrapper: React.FC<ContentContainerProps> = ({ isBackstage }) => {
    const { t, i18n } = useTranslation();
    const { event } = React.useContext(EventContext);
    const userContext = useAuth();
    const [navItems, setNavItems] = React.useState<NavItem[]>([]);
    const navigate = useNavigate();

    useEffect(() => {
        let urlSearchParams = new URLSearchParams(window.location.search);
        let memberIdParam = urlSearchParams.get("member");
        const getMember = async () => {
            if (memberIdParam) {
                let memberDocRef = doc(db, "users", memberIdParam);
                // Retrieve the document
                let docSnapshot = await getDoc(memberDocRef);
                if (docSnapshot.exists()) {
                    // If the document exists, you can use docSnapshot.data() to access its data
                    let memberData = docSnapshot.data();
                    // Do something with memberData, e.g., update state

                    privateLog("log", "EventRoomContentContainerWrapper", memberData);
                    memberData.imagePath = await getStorageURL(memberData.imagePath);
                    memberData.id = memberDocRef.id;
                    privateLog("log", "EventRoomContentContainerWrapper", memberData);
                    navigate("member", { state: { member: memberData as Member } });
                } else {
                    // Handle the case where the document does not exist
                    console.log("No such document!");
                }
            }
        };
        getMember();
    }, []);

    React.useEffect(() => {
        let navItemsEventRoom = [
            {
                label: t("eventRoom"),
                value: "Backstage",
                icon: Menu,
                component: <Backstage isExclusive={false} />,
            },
            {
                label: t("ticketShop"),
                value: "TicketShop",
                icon: ConfirmationNumber,
                component: <TicketShop />,
            },
            {
                label: t("information"),
                value: "Info",
                icon: Info,
                component: <Information />,
            },
            {
                label: t("comments"),
                value: "Comments",
                icon: Chat,
                component: <Comments></Comments>,
            },
        ];

        if (event?.ticketProvider?.name !== "artegrity") {
            navItemsEventRoom = navItemsEventRoom.filter((e, key) => key !== 1);
        }

        const navItemsBackstage = [
            {
                label: t("backstage"),
                value: "Backstage",
                icon: ConfirmationNumber,
                component: <Backstage isExclusive={true} />,
                navColor: purpleTheme.palette.primary.main,
            },
            {
                label: t("information"),
                value: "Info",
                icon: Info,
                component: <Information />,
            },
            {
                label: t("comments"),
                value: "Comments",
                icon: Chat,
                component: <Comments></Comments>,
            },
        ];
        setNavItems(isBackstage ? navItemsBackstage : navItemsEventRoom);
    }, [isBackstage, userContext.backstagePassVerified, i18n.language]); // Added i18n.language

    const adminNavItem = {
        label: t("admin"),
        value: "Admin",
        icon: AdminPanelSettings,
        component: <AdminContainer />,
    };

    if (!event) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <ContentContainer
            navItems={userContext.isAdmin ? [...navItems, adminNavItem] : navItems}
            initIndex={!isBackstage && event.ticketProvider && event.ticketProvider.name === "artegrity" ? 1 : 0}
        ></ContentContainer>
    );
};

export default EventRoomContentContainerWrapper;
