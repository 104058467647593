import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Footer } from "../eventRoom/frame/footer/Footer";
import { EventContext } from "../../contexts/EventContextProvider";
import { getLanguageText } from "../../languagesupport";
import { Mail } from "@mui/icons-material";

const UnsubscribeBackstage = () => {
    const [unsubscribeSucc, setUnsubscribeSucc] = useState(null);
    const { event } = useContext(EventContext);

    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("email");

    useEffect(() => {
        if (event?.ticketProvider?.name === "artegrity" && event.ticketProvider.id && email) {
            fetch(process.env.REACT_APP_PILOT_BE_URL + "/v0/event/unsubscribe?eventId=" + event.ticketProvider.id + "&email=" + email, {
                method: "POST",
                headers: {
                    Accept: "application/hal+json",
                },
            })
                .then((response) => response.json())
                .then((data) => setUnsubscribeSucc(data));
        }
    }, [event, email]);

    if (unsubscribeSucc === null || !event) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: "100vw", height: "100vh", alignItems: "center", display: "flex", flexDirection: "column", mt: 4 }}>
                <Mail sx={{ width: 100, height: 100 }}></Mail>
                <Typography mt={4}>
                    {unsubscribeSucc
                        ? `You have successfully unsubscribed from updates for the digital backstage of ${getLanguageText(event.title)}`
                        : `We couldn't find your subscription for the digital backstage of ${getLanguageText(event.title)}`}
                </Typography>
            </Box>
            <Box>
                <Footer></Footer>
            </Box>
        </Box>
    );
};

export default UnsubscribeBackstage;
