import { LocalActivity } from "@mui/icons-material";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React from "react";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { getLanguageText } from "../../../../languagesupport";
import { OrderInfo } from "../../../../types/artegrtiyShopTypes";

const ArtegrityOrderSummary = (orderInfo: OrderInfo) => {
    const { event } = React.useContext(EventContext);

    if (!event) {
        return <CircularProgress></CircularProgress>;
    }

    function downloadImage(index: number) {
        fetch(process.env.REACT_APP_PILOT_BE_URL + "/v0/tickets/pdf?paypalId=" + orderInfo.orderId + "&ticketNumber=" + index, {
            method: "POST",
            headers: { "Content-Type": "application/pdf" },
        }).then((response) => {
            //TODO: i don t know what i am doing haha
            response.blob().then((blob) => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement("a");
                a.href = url;
                a.target = "_blank";
                a.download = "ticket" + index + ".pdf";
                a.click();
            });
        });
    }

    return (
        <Box sx={{ backgroundColor: "background.default", m: 2 }}>
            <Typography sx={{ typography: { xs: "h4", md: "h2" } }} textAlign={"center"}>
                Thank you for your order for {getLanguageText(event.title)}!
            </Typography>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    mt: 4,
                    width: "100%",
                }}
            >
                <Box
                    sx={{
                        flexDirection: "row",
                        display: "flex",
                    }}
                >
                    <LocalActivity sx={{ color: "primary.main" }}></LocalActivity>
                    <Typography ml={2}>{orderInfo.ticketNumbers.length}</Typography>
                </Box>
                <Typography mt={3} mb={1}>
                    The tickets have been send to you via email, please also check your spam folder or download the ticket here.
                </Typography>{" "}
                {Array(orderInfo.ticketNumbers.length)
                    .fill(null)
                    .map((_, ind) => {
                        return (
                            <Box
                                key={"ticketdownload" + ind}
                                sx={{
                                    mt: 1,
                                    flex: 1,
                                    width: "100%",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button variant="contained" onClick={() => downloadImage(ind)} sx={{ flex: 1 }}>
                                    download ticket {ind + 1}
                                </Button>
                                <Button
                                    sx={{
                                        backgroundColor: "secondary.main",
                                        flex: 1,
                                        "&:hover": {
                                            background: "#395437",
                                        },
                                    }}
                                    variant="contained"
                                    href={orderInfo.verifyLinks[ind]}
                                    target={"_blank"}
                                >
                                    Digital Backstage
                                </Button>
                            </Box>
                        );
                    })}
                <Typography mb={1} mt={2}>
                    With your Ticket you get access to the Digital Backstage of {getLanguageText(event.title)}. Follow the link to interact with the
                    Event:
                </Typography>
            </Box>
        </Box>
    );
};
export default ArtegrityOrderSummary;
