import { Cancel } from "@mui/icons-material";
import { Box, Button, CircularProgress, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material";
import { collection, doc, updateDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { privateLog } from "../../../dev/PrivateConsole";
import { db } from "../../../firebase";
import { getLanguageText } from "../../../languagesupport";
import EventData from "../../../types/EventData";
import { LanguageText } from "../../../types/bll";
import { InfoElement } from "../../../types/dto";

type EditableEventFields = {
    title: LanguageText;
    subtitle: LanguageText;
    description: LanguageText;
    infoList?: InfoElement[];
};

type Props = {
    event: EventData;
    setIsEditing: (isEditing: boolean) => void;
};

export const EditInformation = ({ event, setIsEditing }: Props) => {
    const [editableEvent, setEditableEvent] = useState<EditableEventFields>({
        title: event.title,
        subtitle: event.subtitle,
        description: event.description,
        infoList: event.infoList,
    });

    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language); // default to the first language

    useEffect(() => {
        privateLog("log", "event.infoList", event.infoList);
    }, [event.infoList]);

    if (!event) {
        return <CircularProgress></CircularProgress>;
    }

    const handleLanguageChange = (event: any) => {
        const newLanguage = event.target.value;
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };
    const handleSave = async () => {
        privateLog("debug", "updating event with content: ", editableEvent);

        const eventsCollectionRef = collection(db, "events");
        const docRef = doc(eventsCollectionRef, event.id); // Replace 'event.id' with the id of the event

        if (!editableEvent.infoList) {
            delete editableEvent.infoList;
        }

        privateLog("log", "event", editableEvent);

        await updateDoc(docRef, editableEvent)
            .then(() => {
                privateLog("debug", "updated event successful", event.id);
            })
            .catch((e) => {
                privateLog("error", "Error: ", e);
            });

        setIsEditing(false);
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }} gap={2}>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                }}
            >
                {event.supportedLanguages ? (
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={2}>
                        <Typography>Selected Language: </Typography>
                        <Select value={language} onChange={handleLanguageChange}>
                            {event.supportedLanguages.map((lang) => (
                                <MenuItem key={lang} value={lang}>
                                    {lang}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                ) : null}
                <IconButton onClick={() => setIsEditing(false)}>
                    <Cancel></Cancel>
                </IconButton>
            </Box>
            <TextField
                label="Title"
                variant="outlined"
                name="title"
                value={getLanguageText(editableEvent.title)}
                onChange={(e) =>
                    setEditableEvent({
                        ...editableEvent,
                        title: {
                            ...(editableEvent.title as any),
                            [language]: e.target.value,
                        },
                    })
                }
            />
            <TextField
                label="Subtitle"
                variant="outlined"
                name="subtitle"
                value={getLanguageText(editableEvent.subtitle)}
                onChange={(e) =>
                    setEditableEvent({
                        ...editableEvent,
                        subtitle: {
                            ...(editableEvent.subtitle as any),
                            [language]: e.target.value,
                        },
                    })
                }
            />
            {editableEvent.infoList
                ? editableEvent.infoList.map((item, key) => (
                      <Box key={key} sx={{ display: "flex", flexDirection: "row" }}>
                          <TextField
                              label={"InfoTable" + (key + 1)}
                              variant="outlined"
                              name={`infoList[${key}].title`}
                              value={getLanguageText(item.title)}
                              onChange={(e) =>
                                  setEditableEvent((editableEvent) => {
                                      let result = {
                                          ...editableEvent,
                                          infoList: editableEvent.infoList?.map((item, index) => {
                                              if (index === key) {
                                                  return {
                                                      ...item,
                                                      title: {
                                                          ...(item.title as any),
                                                          [language]: e.target.value,
                                                      },
                                                  };
                                              } else {
                                                  return item;
                                              }
                                          }),
                                      };
                                      return result;
                                  })
                              }
                          />
                          <TextField
                              label={"InfoElement" + (key + 1)}
                              variant="outlined"
                              name={`infoList[${key}].description`}
                              value={getLanguageText(item.description)}
                              onChange={(e) =>
                                  setEditableEvent({
                                      ...editableEvent,
                                      infoList: editableEvent.infoList?.map((item, index) =>
                                          index === key
                                              ? {
                                                    ...item,
                                                    description: {
                                                        ...(item.description as any),
                                                        [language]: e.target.value,
                                                    },
                                                }
                                              : item,
                                      ),
                                  })
                              }
                          />
                      </Box>
                  ))
                : null}
            <TextField
                label="Description"
                variant="outlined"
                name="description"
                value={getLanguageText(editableEvent.description).replace(/\\n/g, "\n")}
                onChange={(e) =>
                    setEditableEvent({
                        ...editableEvent,
                        description: {
                            ...(editableEvent.description as any),
                            [language]: e.target.value,
                        },
                    })
                }
                multiline
            />
            <Button onClick={handleSave}>save</Button>
        </Box>
    );
};
