import { Delete } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, IconButton, TextField, Typography } from "@mui/material";
import React, { useRef } from "react";
import { ImagesElement } from "../../../../types/bll";
import { getL, getLanguageValue, updateLanguageValue } from "../ContentUploadContainer";

type ImagesUploadComponentProps = {
    imagesElement: ImagesElement | undefined;
    setImagesElement: (element: ImagesElement) => void;
    languages?: string[];
};

const ImagesUploadComponent = ({ imagesElement, setImagesElement, languages }: ImagesUploadComponentProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    React.useEffect(() => {
        if (imagesElement === undefined) {
            setImagesElement({ paths: [], size: 0, format: "png" } as ImagesElement);
        }
    });

    if (!imagesElement) {
        return <CircularProgress></CircularProgress>;
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files) {
            const fileURLs = Array.from(event.target.files).map((file) => URL.createObjectURL(file));
            setImagesElement({
                ...imagesElement,
                paths: fileURLs,
            });
        }
    };

    const handleDeleteImage = (index: number) => {
        // Revoke the blob URL to free memory
        if (imagesElement?.paths) {
            URL.revokeObjectURL(imagesElement.paths[index]);
            setImagesElement({
                ...imagesElement,
                paths: imagesElement.paths.filter((_, i) => i !== index),
            });
        }
    };

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }} gap={1}>
            <Typography>Images Upload</Typography>
            <input ref={fileInputRef} type="file" hidden accept="image/*" multiple onChange={handleFileChange} />
            <Button variant="outlined" onClick={() => fileInputRef.current?.click()}>
                Select Images
            </Button>
            {imagesElement.paths && (
                <Grid container spacing={2}>
                    {imagesElement.paths.map((imageUrl, index) => (
                        <Grid item xs={4} key={imageUrl + index}>
                            <Box position="relative" width="100%">
                                <img src={imageUrl} alt={`Selected ${index}`} style={{ width: "100%" }} />
                                <IconButton onClick={() => handleDeleteImage(index)} style={{ position: "absolute", top: 0, right: 0 }}>
                                    <Delete />
                                </IconButton>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            )}
            {languages ? (
                languages.map((language, key) => (
                    <Box key={language + key} gap={1} sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>{getL(language)}</Typography>
                        <TextField
                            label={`Name`}
                            value={getLanguageValue(imagesElement.title, language) || ""}
                            onChange={(e) =>
                                setImagesElement({
                                    ...imagesElement,
                                    title: updateLanguageValue(imagesElement.title, language, e.target.value),
                                })
                            }
                        />
                        <TextField
                            label={`Description`}
                            value={getLanguageValue(imagesElement.description, language) || ""}
                            onChange={(e) =>
                                setImagesElement({
                                    ...imagesElement,
                                    description: updateLanguageValue(imagesElement.description, language, e.target.value),
                                })
                            }
                        />
                    </Box>
                ))
            ) : (
                <Box gap={1} sx={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        label={`Name`}
                        value={imagesElement.title || ""}
                        onChange={(e) =>
                            setImagesElement({
                                ...imagesElement,
                                title: e.target.value,
                            })
                        }
                    />
                    <TextField
                        label={`Description`}
                        value={imagesElement.description || ""}
                        onChange={(e) =>
                            setImagesElement({
                                ...imagesElement,
                                description: e.target.value,
                            })
                        }
                    />
                </Box>
            )}
        </Box>
    );
};

export default ImagesUploadComponent;
