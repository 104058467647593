// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectFirestoreEmulator } from "firebase/firestore";
import { getRemoteConfig } from "firebase/remote-config";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { privateLog } from "./dev/PrivateConsole";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAzNOZDGk0vuVwaqnqf48rNDPlG-hZQG2w",

    authDomain: "artegrity-backstage.firebaseapp.com",

    projectId: "artegrity-backstage",

    storageBucket: "artegrity-backstage.appspot.com",

    messagingSenderId: "938157609557",

    appId: "1:938157609557:web:5ca2c4395badbb8c85badc",

    measurementId: "G-GLYYHLMKY4",
};

//process.env.FUNCTIONS_EMULATOR = "true";
//process.env.FIRESTORE_EMULATOR_HOST = "localhost:4005";

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);

export const auth = getAuth();

export const functions = getFunctions(app, "europe-west3");

export const remoteConfig = getRemoteConfig(app);

///const val3 = getValue(remoteConfig, "website_status");
//privateLog("log", "RemoteConfig", val3)

//fetchAndActivate(remoteConfig).then((val) => {

//const val2 = getValue(remoteConfig, "website_status");

//privateLog("log", "RemoteConfig", val)
//privateLog("log", "RemoteConfig", val2)

//});

export const storage = getStorage();

export const getStorageUrlStatic = (path: string) => {
    const baseUrl = "https://firebasestorage.googleapis.com/v0/b/artegrity-backstage.appspot.com/o/";
    const encodedPath = encodeURIComponent(path);
    return `${baseUrl}${encodedPath}?alt=media`;
};

export const getStorageURL = async (path: string) => {
    const storageRef = ref(storage, path);
    let prom = await getDownloadURL(storageRef);
    return prom;
};

if (process.env.REACT_APP_FUNCTIONS_EMULATOR === "true") {
    privateLog("log", "connecting to functions emulator on: Localhost 5001");
    connectFunctionsEmulator(functions, "localhost", 5001);
}

if (process.env.REACT_APP_FIRESTORE_EMULATOR === "true") {
    privateLog("log", "connecting to  Firestore Emulator on: 4005");
    connectFirestoreEmulator(db, "localhost", 4005);
}
