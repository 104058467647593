export function privateLog(level = "log", ...messages: any[]) {
    let logLevel = "log";
    if (process.env.REACT_APP_LOG_LEVEL) {
        logLevel = process.env.REACT_APP_LOG_LEVEL;
    }
    if (logLevel !== "production") {
        switch (level) {
            case "log":
                console.log(...messages);
                break;
            case "debug":
                if (logLevel === "debug") {
                    console.debug(...messages);
                }
                break;
            case "error":
                console.log(...messages);
                break;
            case "warn":
                if (logLevel === "warn" || logLevel === "debug") {
                    console.warn(...messages);
                }
                break;
            default:
                console.log(...messages);
                break;
        }
    }
}
