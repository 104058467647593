import { Avatar, Box, Button, CircularProgress, Link, TextField, Typography } from "@mui/material";
import { signOut, verifyBeforeUpdateEmail } from "firebase/auth";
import React from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { EventContext } from "../../contexts/EventContextProvider";
import { useAuth } from "../../contexts/UserContext";
import { privateLog } from "../../dev/PrivateConsole";
import { auth } from "../../firebase";

const UserComponent = () => {
    const { t } = useTranslation();

    const { event } = React.useContext(EventContext);
    const [email, setEmail] = React.useState<string>("");
    const [emailChange, setEmailChange] = React.useState<boolean>(false);
    const [msg, setMsg] = React.useState<string>("");
    const userContext = useAuth();

    if (!userContext?.user || !event) {
        return <CircularProgress></CircularProgress>;
    }

    const deleteAccount = async () => {
        const result = await Swal.fire({
            title: "Are you sure?",
            text: "You will not be able to recover this account!",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Yes, delete it!",
            cancelButtonText: "No, keep it",
        });

        if (result.value) {
            const user = auth.currentUser;
            if (user) {
                try {
                    await user.delete();
                    Swal.fire("Deleted!", "Your account has been deleted.", "success");
                    auth.signOut();
                    return true;
                } catch (error: any) {
                    privateLog("error", "Failed to delete user account", error);
                    if (error.code === "auth/requires-recent-login") {
                        Swal.fire("Failed", "Please sign in again to delete your account.", "error");
                    }
                }
            }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire("Cancelled", "Your account is safe :)", "error");
            return false;
        }
    };

    // Function to update user email
    function updateUserEmail(newEmail: string) {
        const user = auth.currentUser;
        if (user) {
            verifyBeforeUpdateEmail(user, newEmail)
                .then(() => {
                    console.log("Email updated successfully!");
                    // Handle email update success, maybe redirect or show a success message
                    setEmailChange(false);
                    setMsg("Please accept the verificaiton email sent to your new email address.");
                })
                .catch((error) => {
                    console.error("Error updating email:", error);
                    // Handle errors here, such as showing an error message to the user
                    setEmailChange(false);
                });
        }
    }

    return (
        <Box
            sx={{
                gap: 2,
                display: "flex",
                flexDirection: "column",
                width: "100%",
                p: 2,
            }}
        >
            <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <Avatar sx={{ mt: 2 }}>{userContext.user.displayName?.slice(0, 2)}</Avatar>
                <Typography variant="h2" mt={2}>
                    {t("hi")}, {userContext.user.email}
                </Typography>
                <Typography mt={2}>{t("you are succesfully signed in")}</Typography>
                {userContext.member && (
                    <Link href={new URL(window.location.origin + `/v0/${event.id}/eventRoom?&member=` + userContext.member).toString()} mt={2}>
                        {t("your performer profile")}
                    </Link>
                )}
                {emailChange && (
                    <TextField sx={{ mt: 1 }} label="Email" variant="outlined" value={email} onChange={(e) => setEmail(e.target.value)}></TextField>
                )}
                {msg && (
                    <Typography mt={1} variant="body2">
                        {msg}
                    </Typography>
                )}
                {emailChange ? (
                    <Box sx={{ width: "100%", display: "flex", flexDirection: "row" }}>
                        <Button
                            fullWidth={true}
                            sx={{ mt: 2, mr: 0.5 }}
                            onClick={() => {
                                updateUserEmail(email);
                            }}
                            variant="contained"
                        >
                            {t("save")}
                        </Button>

                        <Button
                            fullWidth={true}
                            sx={{ mt: 2, ml: 0.5 }}
                            onClick={() => {
                                setEmailChange((prev) => !prev);
                                setMsg("");
                            }}
                            variant="contained"
                        >
                            {t("abort")}
                        </Button>
                    </Box>
                ) : (
                    <Button
                        sx={{ mt: 2 }}
                        onClick={() => {
                            setEmailChange((prev) => !prev);
                            setMsg("");
                        }}
                        variant="contained"
                    >
                        {t("change email")}
                    </Button>
                )}

                <Button
                    sx={{ mt: 2 }}
                    onClick={() => {
                        signOut(auth);
                    }}
                    variant="contained"
                >
                    Sign Out
                </Button>
                <Button sx={{ mt: 2 }} onClick={deleteAccount} variant="contained">
                    {t("delete account")}
                </Button>
            </Box>
        </Box>
    );
};

export default UserComponent;
