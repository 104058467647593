import { Box, MenuItem, Select, Typography } from "@mui/material";
import { DocumentReference } from "firebase/firestore";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventContext } from "../../../../contexts/EventContextProvider";
import EventData from "../../../../types/EventData";
import { ContentComponent, ContentElementWireframe } from "../../../../types/bll";
import SoundCloudComponent from "../../contentElements/SoundCloudComponent";
import VideoComponent from "../../contentElements/VideoComponent";
import ImageViewer from "../../contentElements/imageViewer/ImageViewer";

type Props = {
    contentElement: ContentElementWireframe;
    event: EventData;
};

const UploadPreviewPage = ({ contentElement, event }: Props) => {
    const { darkTheme } = useContext(EventContext);
    const [language, setLanguage] = useState(event.supportedLanguages[0]); // default to the first language

    const { i18n } = useTranslation();

    const handleLanguageChange = (event: any) => {
        const newLanguage = event.target.value;
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    return (
        <Box gap={2} sx={{ display: "flex", flexDirection: "column" }}>
            {contentElement.supportedLanguages ? (
                <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }} gap={2}>
                    <Typography>Selected Language: </Typography>
                    <Select value={language} onChange={handleLanguageChange}>
                        {contentElement.supportedLanguages.map((lang, key) => (
                            <MenuItem key={lang + key} value={lang}>
                                {lang}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
            ) : null}

            <Box
                sx={{
                    border: `10px solid ${darkTheme.palette.background.default}`,
                    borderRadius: 5,
                }}
            >
                {(() => {
                    switch (contentElement.component) {
                        case ContentComponent.SOUNDCLOUD:
                            return (
                                <SoundCloudComponent
                                    contentElement={{
                                        ...contentElement,
                                        docId: "",
                                        position: 0,
                                        docRef: {} as DocumentReference,
                                    }}
                                    eventId={event.id}
                                    track={false}
                                    isOpen={false}
                                ></SoundCloudComponent>
                            );
                        case ContentComponent.VIDEO:
                            return (
                                <VideoComponent
                                    contentElement={{
                                        ...contentElement,
                                        docId: "",
                                        position: 0,
                                        docRef: {} as DocumentReference,
                                    }}
                                    eventId={event.id}
                                    track={false}
                                    isOpen={false}
                                ></VideoComponent>
                            );
                        case ContentComponent.IMAGES:
                            return (
                                <ImageViewer
                                    contentElement={{
                                        ...contentElement,
                                        docId: "",
                                        position: 0,
                                        docRef: {} as DocumentReference,
                                    }}
                                    eventId={event.id}
                                    track={false}
                                    isOpen={false}
                                ></ImageViewer>
                            );
                        default:
                            return null;
                    }
                })()}
            </Box>
        </Box>
    );
};

export default UploadPreviewPage;
