import { ConfirmationNumber, Share } from "@mui/icons-material";
import { Box, CircularProgress, CircularProgressProps, IconButton, SvgIconProps, SxProps, Theme, ThemeProvider, Typography } from "@mui/material";
import { useState } from "react";
import { privateLog } from "../dev/PrivateConsole";
import { purpleTheme } from "../themes/purpleTheme";
import { DocumentReference } from "firebase/firestore";
import { mediaShare } from "../analytics/Posthog";

interface Props {
    url: string;
    width: number;
    height: number;
    download: boolean;
    iconProps: SvgIconProps | CircularProgressProps;
    earlyAccess?: boolean;
    sx?: SxProps<Theme>; // Add this line
    docRef: DocumentReference;
}

const MediaShareButton = ({ url, width, height, iconProps, download, earlyAccess, sx, docRef }: Props) => {
    const [loading, setLoading] = useState<boolean>(false);

    const handleShareClick = async () => {
        mediaShare(docRef.id);
        try {
            if (download) {
                setLoading(true);
                const response = await fetch(url);
                const blob = await response.blob();
                setLoading(false);
                const fileName = url.substring(url.lastIndexOf("/") + 1);

                const file = new File([blob], fileName, { type: blob.type });

                if (navigator.canShare && navigator.canShare({ files: [file] })) {
                    await navigator.share({
                        files: [file],
                    });
                } else {
                    const shareUrl = URL.createObjectURL(file);
                    window.open(shareUrl, "_blank");
                }
            } else {
                if (navigator.share) {
                    await navigator.share({
                        url: url,
                    });
                } else {
                    window.open(url, "_blank");
                }
            }
        } catch (error) {
            setLoading(false);
            privateLog("error", "Error sharing:", error);
            // Handle any errors that occur during sharing
        }
    };

    if (earlyAccess === true) {
        return (
            <ThemeProvider theme={purpleTheme}>
                <Box sx={{ flexDirection: "row", display: "flex", alignItems: "center" }}>
                    <Typography mr={2}>Early Access</Typography>
                    <ConfirmationNumber sx={{ color: "primary.main" }}></ConfirmationNumber>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <IconButton onClick={handleShareClick}>
            <Box sx={sx}>
                {!loading ? (
                    <Share color={iconProps.color} sx={{ width: width, height: height }} />
                ) : (
                    <CircularProgress color={(iconProps as CircularProgressProps).color} size={height}></CircularProgress>
                )}
            </Box>
        </IconButton>
    );
};

export default MediaShareButton;
