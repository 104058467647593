import { Box, CircularProgress, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import Chart from "react-google-charts";
import { privateLog } from "../../../../dev/PrivateConsole";
import { functions } from "../../../../firebase";
import { DateFrom } from "../../../../types/bll";
type Props = {
    eventId: string;
};

const MemberAnalytics = ({ eventId }: Props) => {
    const [memberAnalytics, setMemberAnalytics] = useState<(string | number)[][]>([]);

    useEffect(() => {
        const fetchData = async () => {
            const getAnalyticsData = httpsCallable(functions, "postHogAnalytics-getPostHogAnalytics");

            const memberAnalytics = await getAnalyticsData({
                analyticsFunc: "graphMember",
                eventId: eventId,
                dateFrom: DateFrom.Last7Days,
            });

            privateLog("info", memberAnalytics);
            setMemberAnalytics(memberAnalytics.data as (string | number)[][]);
        };
        fetchData();
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: "background.default",
                borderRadius: 5,
                overflow: "hidden",
                p: 2,
            }}
        >
            <Typography variant="h2">Member Analytics</Typography>
            <Chart
                chartType="BarChart"
                loader={<CircularProgress></CircularProgress>}
                width={"100%"}
                height={"300px"}
                data={memberAnalytics}
                options={{
                    legend: { position: "none" },
                    hAxis: {
                        title: "Count",
                    },
                    vAxis: {
                        title: "Name",
                    },
                }}
            />
        </Box>
    );
};

export default MemberAnalytics;
