import React from "react";
import { useLocation } from "react-router-dom";
import MemberComponent from "./MemberComponent";
import { privateLog } from "../../../dev/PrivateConsole";
import { Member } from "../../../types/bll";

const MemberNavFrame = () => {
    const location = useLocation();

    privateLog("log", "MemberNavFrame", location.state?.member);

    return <MemberComponent currMember={location.state?.member as Member}></MemberComponent>;
};

export default MemberNavFrame;
