import { Box, Button, Divider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/UserContext";
import { getLanguageText } from "../../../languagesupport";
import EventData from "../../../types/EventData";
import SignInButton from "../../account/SignInButton";
import UserComponent from "../../account/UserComponent";
import BackstagePass from "../components/BackstagePass";
import PoweredBy from "./components/PoweredBy";
import DevelopmentMenu from "./DevelopmentMenu";

type Props = {
    event: EventData;
    isBackstage: boolean;
    handleClose: () => void;
    cookieValue: boolean | undefined;
    updateCookieValue: () => void;
};

const UserMenu = ({ event, isBackstage, handleClose, cookieValue, updateCookieValue }: Props) => {
    const userContext = useAuth();

    const user = userContext?.user; // Safely access the user property

    const { t } = useTranslation();
    const isDevelopment = window.location.hostname === "localhost";

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                flex: 1,
            }}
        >
            {event.onlyEventRoom ? null : (
                <div>
                    <Box sx={{ p: 2 }}>
                        {isBackstage ? (
                            <BackstagePass></BackstagePass>
                        ) : (
                            <Typography>
                                {t("connect your backstage pass", {
                                    event_title: getLanguageText(event.title),
                                })}
                            </Typography>
                        )}
                    </Box>
                </div>
            )}

            <Divider
                sx={{
                    width: "100%",
                    backgroundColor: "text.primary",
                }}
            ></Divider>
            {(user && user.providerData.length === 0) || !user ? (
                <Box
                    sx={{
                        p: 2,
                        flexDirection: "column",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <SignInButton></SignInButton>
                    <Typography mt={1} textAlign={"start"}>
                        {isBackstage ? t("login to save your backstage pass to your account") : t("login to view your backstage pass content")}
                    </Typography>
                </Box>
            ) : (
                <UserComponent></UserComponent>
            )}
            <Divider
                sx={{
                    mb: 2,
                    width: "100%",
                    backgroundColor: "text.primary",
                }}
            ></Divider>
            <Typography mb={1}>
                {t("Cookie consent status")}
                {": "}
                {cookieValue ? t("accepted") : t("declined")}
            </Typography>
            <Button variant="contained" onClick={() => updateCookieValue()} sx={{ mb: 2 }}>
                Reset Cookies
            </Button>
            <PoweredBy event={event} onClick={handleClose}></PoweredBy>
            {isDevelopment && <DevelopmentMenu id={event.id}></DevelopmentMenu>}
        </Box>
    );
};

export default UserMenu;
