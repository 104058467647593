import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import PushPinIcon from "@mui/icons-material/PushPin";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { Box, IconButton, Tooltip, Typography, TextField, Button } from "@mui/material";
import { collection, getDocs, query, updateDoc, where, orderBy, limit } from "firebase/firestore";
import { privateLog } from "../../../../dev/PrivateConsole";
import { db } from "../../../../firebase";
import { ContentElement } from "../../../../types/bll";
import { useState } from "react";

type Props = {
    contentElement: ContentElement;
};

const MoveContentElement = ({ contentElement }: Props) => {
    const [positionInput, setPositionInput] = useState<number | string>("");

    const togglePin = async () => {
        const updatedElement = { ...contentElement, pinned: !contentElement.pinned };

        await updateDoc(contentElement.docRef, updatedElement);
    };

    const moveElement = async (direction: "up" | "down") => {
        privateLog("log", "MoveContentElement", "Move", direction);
        let postCollection = collection(db, "Posts");
        let conditions = [
            where("eventId", "==", contentElement.eventId),
            where("position", "==", contentElement.position + (direction === "up" ? 1 : -1)),
            where("visibility", "in", ["public", "exclusive"]),
        ];

        const q = query(postCollection, ...conditions);
        const querySnapshot = await getDocs(q);

        privateLog("log", "MoveContentElement", "Move Query", querySnapshot);

        if (!querySnapshot.empty) {
            let targetDoc = querySnapshot.docs[0];

            let before = contentElement.position;
            let after = contentElement.position + (direction === "up" ? 1 : -1);
            // Swap positions
            await updateDoc(targetDoc.ref, { position: before });
            await updateDoc(contentElement.docRef, { position: after });
        } else {
            privateLog("error", "MoveContentElement", "No element found to swap positions with");
        }
    };

    const switchWithTop = async () => {
        privateLog("log", "MoveContentElement", "Switch with Top");
        let postCollection = collection(db, "Posts");
        let conditions = [where("eventId", "==", contentElement.eventId), where("visibility", "in", ["public", "exclusive"])];

        const q = query(postCollection, ...conditions, orderBy("position", "asc"), limit(1));
        const querySnapshot = await getDocs(q);

        privateLog("log", "MoveContentElement", "Switch Query", querySnapshot);

        if (!querySnapshot.empty) {
            let targetDoc = querySnapshot.docs[0];

            let before = contentElement.position;
            let after = targetDoc.data().position;
            // Swap positions
            await updateDoc(targetDoc.ref, { position: before });
            await updateDoc(contentElement.docRef, { position: after });
        } else {
            privateLog("error", "MoveContentElement", "No element found to switch positions with");
        }
    };

    const switchWithPosition = async (newPosition: number) => {
        privateLog("log", "MoveContentElement", "Switch with Position", newPosition);
        let postCollection = collection(db, "Posts");
        let conditions = [where("eventId", "==", contentElement.eventId), where("visibility", "in", ["public", "exclusive"])];

        const q = query(postCollection, ...conditions, orderBy("position", "desc"));
        const querySnapshot = await getDocs(q);

        privateLog("log", "MoveContentElement", "Switch Query", querySnapshot);

        if (!querySnapshot.empty) {
            let targetDoc = querySnapshot.docs[newPosition - 1];

            if (targetDoc) {
                let before = contentElement.position;
                let after = targetDoc.data().position;
                // Swap positions
                await updateDoc(targetDoc.ref, { position: before });
                await updateDoc(contentElement.docRef, { position: after });
            } else {
                privateLog("error", "MoveContentElement", "No element found at the specified position");
            }
        } else {
            privateLog("error", "MoveContentElement", "No elements found to switch positions with");
        }
    };

    return (
        <Box>
            <Typography variant="h6">Change Order</Typography>
            <Tooltip title="Move component one place up">
                <IconButton onClick={() => moveElement("up")}>
                    <ArrowUpwardIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Move component one place down">
                <IconButton onClick={() => moveElement("down")}>
                    <ArrowDownwardIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Pin element to the top" color={contentElement.pinned ? "primary" : ""} onClick={() => togglePin()}>
                <IconButton>
                    <PushPinIcon />
                </IconButton>
            </Tooltip>
            <Tooltip title="Switch with the element at the top">
                <IconButton onClick={switchWithTop}>
                    <SwapVertIcon />
                </IconButton>
            </Tooltip>
            <Box display="flex" mt={2} sx={{ justifyContent: "center" }}>
                <TextField
                    label="Position"
                    type="number"
                    value={positionInput}
                    onChange={(e) => setPositionInput(Number(e.target.value))}
                    variant="outlined"
                    size="small"
                />
                <Button variant="contained" color="primary" onClick={() => switchWithPosition(Number(positionInput))} sx={{ ml: 2 }}>
                    Switch
                </Button>
            </Box>
        </Box>
    );
};

export default MoveContentElement;
