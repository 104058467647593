/* eslint-disable no-unused-vars */

import { DocumentReference, FieldValue, getDoc } from "firebase/firestore";
import { Cvt } from "./dto";
import { getStorageURL } from "../firebase";

export enum LineUpAction {
    ADD,
    REMOVE,
    NONE,
    ADDED,
}

export enum UploadState {
    PROPERTIES,
    COMPONENT,
    PREVIEW,
}

export enum DateFrom {
    All = "all",
    Last30Days = "-30d",
    Last7Days = "-7d",
}

export enum MemberRoles {
    ARTIST = "artist",
    COMPANY = "company",
    EVENT = "event",
}

export interface MemberRef {
    role: MemberRoles;
}
export interface Member {
    id: string;
    role: string;
    name: string;
    imagePath: string;
    information?: LanguageText;
    informationImageUrl?: string;
    socials?: IconLink[];
}

export const resolveMembers = async (memberRef: DocumentReference): Promise<Member> => {
    let member = (await getDoc(memberRef)).data() as MemberRef | Member;
    let ret;
    switch (member.role) {
        case MemberRoles.ARTIST:
            ret = member as ArtistMember;
            ret.id = memberRef.id;
            break;
        case MemberRoles.COMPANY:
            let company = await getDoc((member as CompanyRef).ref);
            let companyData = company.data();
            let companyUser: CompanyMember = {
                id: company.id,
                name: companyData?.name,
                role: member.role,
                imagePath: companyData?.imagePath,
            };
            ret = companyUser;
            break;
        case MemberRoles.EVENT:
            let event = await getDoc((member as EventRef).ref);
            let eventData = event.data();
            let eventUser: EventMember = {
                id: event.id,
                name: eventData?.title,
                role: member.role,
                imagePath: eventData?.imagePath,
            };
            ret = eventUser;
            break;
        default:
            throw new Error("Wrong Input");
    }
    if (ret.imagePath) {
        ret.imagePath = await getStorageURL(ret.imagePath);
    }
    return ret;
};

export interface EventMember extends Member {}

export interface CompanyMember extends Member {}

export interface EventRef extends MemberRef {
    ref: DocumentReference;
}

export interface CompanyRef extends MemberRef {
    ref: DocumentReference;
}

export interface ArtistMember extends Member {
    name: string;
    imagePath: string;
}

export enum ContentComponent {
    MUSIC_SET = "musicSet",
    IMAGES = "images",
    NFT = "nft",
    DISCOUNT = "discount",
    LINE_UP = "lineUp",
    SOUNDCLOUD = "soundcloud",
    TICKETS = "tickets",
    VIDEO = "video",
    TEXT_ELEMENT = "text",
    LINK = "link",
}
export type ContentType =
    | MusicSetElement
    | ImagesElement
    | NFTData
    | LineUpData
    | SoundCloudElement
    | TicketElement[]
    | VideoElement
    | TextElement
    | LinkElement
    | undefined;

export type LinkElementField = {
    title?: LanguageText;
    link: string;
    buttonText: LanguageText;
};

export type LinkElement = {
    title?: LanguageText;
    description?: LanguageText;
    links: LinkElementField[];
};

export type CommentPost = {
    uploadedAt: FieldValue;
    userId: string;
    eventId: string;
    text: string;
    path?: string;
    id: string;
};
export interface ContentElementWireframe {
    eventId: string;
    userId: string;
    visibility: string;
    members: string[];
    component: ContentComponent;
    supportedLanguages?: string[];
    data: ContentType;
    uploadedAt?: FieldValue;
}

export type Schedule = {
    start?: number;
    end?: number;
};

export interface ContentElement extends ContentElementWireframe {
    pinned?: boolean;
    docRef: DocumentReference;
    docId: string;
    position: number;
    likedBy?: string[];
    scheduled?: Schedule;
}

export type ContentElementProps = {
    contentElement: ContentElement;
    eventId: string;
    track: boolean;
    isOpen: boolean;
};

export type LanguageText =
    | {
          sv?: string;
          fi?: string;
          en?: string;
          de?: string;
      }
    | string;

export interface TextElement {
    imageUrl: string;
    date: string;
    displayDescriptionExpanded?: boolean;
    title: LanguageText;
    description: LanguageText;
    textHtml: LanguageText;
    shareUrl: string;
    isEarlyAccess: boolean;
}

export interface TicketElement {
    signature: string;
    ticketCode: number;
}

export interface NFTData {
    cvtId: number;
}

export interface VideoElement {
    path: string;
    description?: LanguageText;
    name?: LanguageText;
}
export interface SoundCloudElement {
    trackId: string;
    description?: LanguageText;
    name?: LanguageText;
}

export interface NFTElement {
    type: NFTType;
    imageUrl: string;
    smartContractAddress: string;
    cvtId: number;
    jsonUrl: string;
}

export enum NFTType {
    UNIQUE = "UNIQUE",
    SINGLE = "SINGLE",
}

export interface NFTAttribute {
    trait_type: string;
    value: string;
}
export interface LineUpData {
    lineUpElements: LineUpElement[];
    stages: string[];
    days: number[];
}

export interface LineUpElement {
    startTime: string;
    endTime: string;
    day: number;
    memberId: string;
    name: string;
    stage: string;
    imagePath?: string;
}

export interface MusicSetElement {
    url: string;
    soundCloudProfile: string;
    soundCloudUrl: string | undefined;
    name: LanguageText;
    description: LanguageText;
}

export interface ImagesElement {
    baseUrl?: string;
    size: number;
    title?: LanguageText;
    descriptions?: LanguageText[];
    format: string;
    description?: LanguageText;
    socials?: IconLink[];
    paths?: string[];
}

export type ColumnesText = {
    columnes: ColumnText[];
};

export type ColumnText = {
    name: string;
    links: LinkText[];
};

export type LinkText = {
    name: string;
    link: string;
};

export type ErrorState = {
    cvt: Cvt;
    msg: string;
    statusCode: number;
};

export type PreviousArtistLocationState = {
    from: Location;
    artist: null;
};

export type PreviousLocationState = {
    from: Location;
    cvtId: number;
};

export type IconLink = {
    icon: IconName;
    name: string;
    link: string;
};

export type IconLinkColumn = {
    name: string;
    items: IconLink[];
};

export enum IconName {
    WEBSITE = "website",
    INSTAGRAM = "instagram",
    FACEBOOK = "facebook",
    DOCUMENT = "document",
    MAIL = "mail",
    LINKEDIN = "linkedin",
    MAPS = "maps",
    SOUNDCLOUD = "soundcloud",
}
