import { Box, Button, Typography } from "@mui/material";
import { getLanguageText } from "../../../languagesupport";
import { ContentElementProps, LinkElement } from "../../../types/bll";

export const LinkComponent = (props: ContentElementProps) => {
    const data = props.contentElement.data as LinkElement;
    //TODO: make this proper
    return (
        <Box
            sx={{
                borderRadius: 5,
                backgroundColor: "background.default",
                p: 2,
                gap: 2,
                display: "flex",
                flexDirection: "column",
            }}
        >
            {data.title && <Typography variant="h2">{getLanguageText(data.title)}</Typography>}
            {data.description && <Typography variant="body1">{getLanguageText(data.description)}</Typography>}
            {data.links.map((link, index) => (
                <Button key={index} variant="contained" target="__blank" fullWidth href={link.link}>
                    {getLanguageText(link.buttonText)}
                </Button>
            ))}
        </Box>
    );
};

export default LinkComponent;
