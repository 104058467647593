import { Box, Button, CircularProgress, TextField, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { VideoElement } from "../../../../types/bll";
import { getL, getLanguageValue, updateLanguageValue } from "../ContentUploadContainer";

type VideoUploadComponentProps = {
    videoElement: VideoElement | undefined;
    setVideoElement: (element: VideoElement) => void;
    languages?: string[];
};

const VideoUploadComponent = ({ videoElement, setVideoElement, languages }: VideoUploadComponentProps) => {
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const url = URL.createObjectURL(file);
            setVideoElement({ path: url });
            event.target.value = ""; // reset the value
        }
    };

    useEffect(() => {
        if (videoElement === undefined) {
            setVideoElement({ path: "" } as VideoElement);
        }
    });

    if (!videoElement) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }} gap={1}>
            <Typography>Video Upload</Typography>
            <input ref={fileInputRef} type="file" hidden accept="video/*" onChange={handleFileChange} />
            <Button variant="outlined" onClick={() => fileInputRef.current?.click()}>
                Choose Video
            </Button>
            {videoElement.path && (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        maxHeight: "300px",
                        overflow: "auto",
                    }}
                >
                    <video style={{ maxHeight: "100%", objectFit: "contain" }} controls>
                        <source src={videoElement.path} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Box>
            )}
            {languages ? (
                languages.map((language, key) => (
                    <Box key={key} gap={1} sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography>{getL(language)}</Typography>
                        <TextField
                            label={`Name`}
                            value={getLanguageValue(videoElement.name, language) || ""}
                            onChange={(e) =>
                                setVideoElement({
                                    ...videoElement,
                                    name: updateLanguageValue(videoElement.name, language, e.target.value),
                                })
                            }
                        />
                        <TextField
                            label={`Description`}
                            value={getLanguageValue(videoElement.description, language) || ""}
                            onChange={(e) =>
                                setVideoElement({
                                    ...videoElement,
                                    description: updateLanguageValue(videoElement.description, language, e.target.value),
                                })
                            }
                        />
                    </Box>
                ))
            ) : (
                <Box gap={1} sx={{ display: "flex", flexDirection: "column" }}>
                    <TextField
                        label={`Name`}
                        value={videoElement.name || ""}
                        onChange={(e) =>
                            setVideoElement({
                                ...videoElement,
                                name: e.target.value,
                            })
                        }
                    />
                    <TextField
                        label={`Description`}
                        value={videoElement.description || ""}
                        onChange={(e) =>
                            setVideoElement({
                                ...videoElement,
                                description: e.target.value,
                            })
                        }
                    />
                </Box>
            )}
        </Box>
    );
};

export default VideoUploadComponent;
