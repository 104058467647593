import { Box, CircularProgress, Container, Typography } from "@mui/material";
import React, { useEffect } from "react";

import ReactGA from "react-ga4";

import { fetchAndActivate, getValue } from "firebase/remote-config";
import CookieConsent, { getCookieConsentValue, resetCookieConsentValue } from "react-cookie-consent";
import ReactPixel from "react-facebook-pixel";
import { HelmetProvider } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import { Route, Routes } from "react-router-dom";
import { initPosthog } from "../../../analytics/Posthog";
import { EventContext } from "../../../contexts/EventContextProvider";
import { FrameContext } from "../../../contexts/FrameContext";
import { useAuth } from "../../../contexts/UserContext";
import { privateLog } from "../../../dev/PrivateConsole";
import { remoteConfig } from "../../../firebase";
import i18n, { getLanguageText } from "../../../languagesupport";
import EventRoomFrameHeader from "../components/EventRoomFrameHeader";
import EventRoomContentContainerWrapper from "../contentContainer/EventRoomContentContainerWrapper";
import BuyTicketsButton from "../contentElements/BuyTicketsButton";
import Members from "../contentElements/Members";
import MemberNavFrame from "../member/MemberNavFrame";
import Header from "./Header";
import Maintenance from "./Maintenance";
import { Footer } from "./footer/Footer";

const EventRoomFrame = () => {
    const { event } = React.useContext(EventContext);
    const frameContext = React.useContext(FrameContext);
    const [underMaintenance, setUnderMaintenance] = React.useState<boolean>();
    const [gaSent, setGaSent] = React.useState<boolean>(false);

    const { t } = useTranslation();

    const userContext = useAuth();

    const [isBackstage, setIsBackstage] = React.useState<boolean | undefined>(undefined);

    //useEffect(() => {
    //privateLog("info", "Frame isBackstage: ", isBackstage);
    //}, [isBackstage]);

    const switchEventRoom = () => {
        privateLog("debug", "Frame switchEventRoom");
        setIsBackstage((_isBackstage) => {
            if (_isBackstage) {
                return false;
            } else {
                if (
                    userContext.isAdmin ||
                    userContext.member ||
                    (userContext.backstagePassVerified !== false && userContext.backstagePassVerified !== undefined)
                ) {
                    return true;
                } else {
                    return false;
                }
            }
        });
    };

    useEffect(() => {
        privateLog("info", "Frame backstagePassVerified: ", userContext);
        privateLog("debug", "Header Backstage pass changed", userContext.backstagePassVerified);
        if (userContext.backstagePassVerified === undefined) {
            setIsBackstage(undefined);
            privateLog("info", "Frame setting isBackstage: ", undefined);
        } else if (userContext.backstagePassVerified === false) {
            privateLog("info", "Frame setting isBackstage: ", false);
            setIsBackstage(false);
        } else {
            privateLog("info", "Frame setting isBackstage: ", true, "Backstage Pass verified", userContext.backstagePassVerified);
            setIsBackstage(true);
        }
    }, [userContext.backstagePassVerified, event, userContext]);

    const [cookies, setCookies] = React.useState<boolean | undefined>(getCookieConsentValue() ? getCookieConsentValue() === "true" : undefined);

    useEffect(() => {
        if (event) {
            if (event.pixel) {
                console.log("Pixel enableTracking: ", cookies);
                ReactPixel.init(event.pixel);
                if (!cookies) {
                    privateLog("info", "Pixel tracking not allowed because of cookie policy");
                    ReactPixel.revokeConsent();
                } else {
                    privateLog("info", "Pixel tracking allowed because of cookie policy");
                    ReactPixel.grantConsent();
                    ReactPixel.pageView();
                }
            }
        }
    }, [event, cookies]);

    const updateCookieValue = () => {
        resetCookieConsentValue();
        privateLog("info", "Cookies consent value: ", getCookieConsentValue());
        let _c = getCookieConsentValue();
        if (_c === undefined) {
            setCookies(_c);
        } else if (_c === "true") {
            setCookies(true);
        } else if (_c === "false") {
            setCookies(false);
        }
    };

    React.useEffect(() => {
        if (event) {
            privateLog(
                "info",
                "Cookies: ",
                cookies,
                "Event: ",
                event.id,
                "BackstagePassVerified: ",
                userContext.backstagePassVerified,
                "isAdmin: ",
                userContext.isAdmin,
                "Member: ",
                userContext.member
            );
        }

        let cookiesEnabled = cookies === true;
        if (event?.pixel && cookiesEnabled) {
            privateLog("info", "Pixel Grant Consent");
            ReactPixel.grantConsent();
        } else if (event?.pixel && !cookiesEnabled) {
            ReactPixel.revokeConsent();
        }
        if (event && userContext.isAdmin === false && userContext.member === undefined && userContext.backstagePassVerified !== undefined) {
            //TODO: also remove members from analytics
            privateLog("info", "Cookies being initialized ");
            if (event.analyticsVersion) {
                initPosthog(
                    cookiesEnabled,
                    event.id,
                    userContext.backstagePassVerified !== false && userContext.backstagePassVerified !== undefined,
                    event.analyticsVersion
                );
            } else {
                initPosthog(cookiesEnabled, event.id, userContext.backstagePassVerified !== false && userContext.backstagePassVerified !== undefined);
            }

            if (cookiesEnabled && event.ga4MeasurementId && gaSent === false) {
                privateLog("info", "GA4 being initialized");
                setGaSent(true);
                ReactGA.initialize(event.ga4MeasurementId);
                // You can also track a pageview immediately after initializing
                ReactGA.send({ hitType: "pageview", page: window.location.pathname });
            }
        }
    }, [cookies, event, userContext]);

    React.useEffect(() => {
        if (!(window.location.hostname === "localhost")) {
            fetchAndActivate(remoteConfig).then(() => {
                if (event?.ticketProvider?.name === "artegrity") {
                    const artegrityShopStatus = getValue(remoteConfig, "artegrity_shop_status").asBoolean();
                    privateLog("debug", "artegrityShopStatus: ", artegrityShopStatus);
                    if (artegrityShopStatus === false) {
                        setUnderMaintenance(true);
                        return;
                    }
                    const websiteStatus = getValue(remoteConfig, "website_status").asBoolean();
                    privateLog("debug", "websiteStatus: ", websiteStatus);
                    if (websiteStatus === false) {
                        setUnderMaintenance(true);
                        return;
                    }
                    setUnderMaintenance(false);
                }
            });
        } else {
            setUnderMaintenance(false);
        }
    }, [event]);

    if (!event || underMaintenance === null) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <HelmetProvider>
            <Header
                event={event}
                isBackstage={isBackstage}
                switchEventRoom={switchEventRoom}
                cookieValue={cookies}
                updateCookieValue={updateCookieValue}
            ></Header>
            {underMaintenance ? (
                <Maintenance></Maintenance>
            ) : (
                <Container
                    maxWidth="sm"
                    sx={{
                        mt: 2,
                        backgroundColor: "background.default",
                    }}
                >
                    <EventRoomFrameHeader path={event.imagePath} title={getLanguageText(event.title)}></EventRoomFrameHeader>
                    <Routes>
                        <Route path="member" element={<MemberNavFrame></MemberNavFrame>}></Route>
                        <Route
                            path="*"
                            element={
                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <Typography variant="h3" mt={2}>
                                            {getLanguageText(event.title)}
                                        </Typography>
                                        <Box sx={{ alignSelf: "flex-end" }}>
                                            {!isBackstage ? <BuyTicketsButton event={event}></BuyTicketsButton> : null}
                                        </Box>
                                    </Box>
                                    <Box sx={{ mb: 2 }}>
                                        <Members></Members>
                                    </Box>
                                    <EventRoomContentContainerWrapper
                                        isBackstage={isBackstage !== undefined ? isBackstage : false}
                                    ></EventRoomContentContainerWrapper>
                                </Box>
                            }
                        ></Route>
                    </Routes>
                </Container>
            )}
            {frameContext?.showFrame ? <Footer></Footer> : null}
            {userContext.backstagePassVerified !== undefined && (
                <CookieConsent
                    key={i18n.language + cookies} // Added this
                    enableDeclineButton
                    declineButtonText={t("cookie decline")}
                    buttonText={t("cookie accept")}
                    visible={cookies === undefined ? "visible" : "hidden"}
                    onDecline={() => {
                        setCookies(false);
                    }}
                    onAccept={() => {
                        setCookies(true);
                    }}
                >
                    {t("cookies")}
                </CookieConsent>
            )}
        </HelmetProvider>
    );
};

export default EventRoomFrame;
