import { Box, Button } from "@mui/material";
import { signOut } from "firebase/auth";

import { auth } from "../../../firebase";
import posthog from "posthog-js";

type Props = {
    id: string;
};

const DevelopmentMenu = ({ id }: Props) => {
    return (
        <Box>
            <Button sx={{ m: 2 }} onClick={() => signOut(auth)} variant="contained">
                Sign Out Dev
            </Button>
            <Button
                sx={{ m: 2 }}
                onClick={() => (window.location.href = "https://backstage.artegrity.io/v0/eventRoom?eventId=" + id)}
                variant="contained"
            >
                Go to Live
            </Button>
            <Button
                sx={{ m: 2 }}
                onClick={() => {
                    posthog.unregister("firebaseEventId");
                    posthog.unregister("artegrityPage");
                }}
                variant="contained"
            >
                Delete Posthog
            </Button>
        </Box>
    );
};

export default DevelopmentMenu;
