import { Description, Facebook, Google, Instagram, LinkedIn, Mail, QuestionMark, Web } from "@mui/icons-material";
import { IconName } from "../types/bll";
import { ReactComponent as SoundCloudIcon } from "../svg/soundcloud-logo.svg"; // Import the SoundCloud SVG

type Props = {
    name: IconName | string;
    width?: number;
    height?: number;
};

export const IconSwitcher = (props: Props) => {
    // Use local variables for width and height, defaulting to 24 if not provided
    const width = props.width || 24;
    const height = props.height || 24;

    let style = { color: "primary.main", width: width, height: height };
    switch (props.name.toLocaleLowerCase()) {
        case "soundcloud":
            return <SoundCloudIcon style={style} />; // Use the SoundCloudIcon component
        case "website":
            return <Web sx={style}></Web>;
        case "instagram":
            return <Instagram sx={style}></Instagram>;
        case "facebook":
            return <Facebook sx={style}></Facebook>;
        case "document":
            return <Description sx={style}></Description>;
        case "mail":
            return <Mail sx={style}></Mail>;
        case "linkedin":
            return <LinkedIn sx={style}></LinkedIn>;
        case "maps":
            return <Google sx={style}></Google>;
        default:
            return <QuestionMark sx={style}></QuestionMark>;
    }
};

export default IconSwitcher;
