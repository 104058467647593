import { MenuItem, Select, SelectChangeEvent, TableCell, TableRow, Typography, useMediaQuery, useTheme } from "@mui/material";
import { ArtegrityTicket, Quantity } from "../../../../types/artegrtiyShopTypes";

type Props = {
    ticket: ArtegrityTicket;
    setQuantity: (quantity: Quantity) => void;
    currChar: string;
};

export const TicketCell = ({ currChar, ticket, setQuantity }: Props) => {
    const handleChange = (event: SelectChangeEvent) => {
        setQuantity({
            id: ticket.id,
            quantity: +event.target.value,
            groupPricingIndex: ticket.groupPricingIndex,
        });
    };

    const theme = useTheme();
    const isXs = useMediaQuery(theme.breakpoints.down("md"));

    return (
        <TableRow key={"data-row-" + ticket.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
            <TableCell key={"name" + ticket.id} sx={{ fontWeight: "bold" }} component="th" scope="row">
                <Typography fontWeight="bold">{ticket.name}</Typography>
                {ticket.description ? <Typography maxWidth={400}>{ticket.description}</Typography> : null}
            </TableCell>
            <TableCell key={"price" + ticket.id + ticket.groupPricingIndex} align="right">
                <Typography>{ticket.price + currChar}</Typography>
            </TableCell>
            <TableCell key={"quantity" + ticket.id + ticket.groupPricingIndex} align="right">
                {ticket.available === undefined}
                {ticket.available !== undefined && ticket.available === 0 && <Typography>Not Available</Typography>}
                {ticket.available && ticket.available > 0 ? (
                    <Select value={"" + ticket.quantity} onChange={handleChange} displayEmpty inputProps={{ "aria-label": "Without label" }}>
                        {ticket.minQuantity
                            ? Array.from({ length: ticket.available + 1 }, (_, i) => {
                                  if (i === 0 || (ticket.minQuantity && i >= ticket.minQuantity)) {
                                      return (
                                          <MenuItem key={i} value={i}>
                                              {"" + i}
                                          </MenuItem>
                                      );
                                  }
                              })
                            : Array.from({ length: ticket.available + 1 }, (_, i) => {
                                  return (
                                      <MenuItem key={i} value={i}>
                                          {"" + i}
                                      </MenuItem>
                                  );
                              })}
                    </Select>
                ) : null}
            </TableCell>
            {!isXs ? (
                <TableCell key={"total-price" + ticket.id + ticket.groupPricingIndex} align="right">
                    <Typography>{(ticket.quantity * ticket.price).toFixed(2) + currChar}</Typography>
                </TableCell>
            ) : null}
        </TableRow>
    );
};
