import { Cancel, Edit } from "@mui/icons-material";
import { Box, Button, CircularProgress, Grid, IconButton, MenuItem, Select, TextField, ThemeProvider, Typography } from "@mui/material";
import { collection, doc, updateDoc } from "firebase/firestore";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { EventContext } from "../../../contexts/EventContextProvider";
import { useAuth } from "../../../contexts/UserContext";
import { privateLog } from "../../../dev/PrivateConsole";
import { db } from "../../../firebase";
import { getLanguageText } from "../../../languagesupport";
import { EditInformation } from "./EditInformation";

export const Information = () => {
    const { event, brightTheme } = React.useContext(EventContext);
    const { isAdmin } = useAuth();
    const [isEditing, setIsEditing] = React.useState<boolean>(false);
    const [isCreditsEditing, setIsCreditsEditing] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const [credits, setCredits] = React.useState<string>();
    const { i18n } = useTranslation();
    const [language, setLanguage] = useState(i18n.language); // default to the first language

    useEffect(() => {
        if (event) {
            if (event.credits) {
                setCredits(event.credits);
            }
        }
    }, [event]);

    if (!event) {
        return <CircularProgress></CircularProgress>;
    }
    const description = getLanguageText(event.description).replace(/\\n/g, "\n");

    const handleLanguageChange = (event: any) => {
        const newLanguage = event.target.value;
        setLanguage(newLanguage);
        i18n.changeLanguage(newLanguage);
    };

    const infoList = event.infoList;

    const handleSave = async () => {
        const eventsCollectionRef = collection(db, "events");
        const docRef = doc(eventsCollectionRef, event.id); // Replace 'event.id' with the id of the event

        await updateDoc(docRef, { credits: credits })
            .then(() => {
                privateLog("debug", "updated event successful", event.id);
            })
            .catch((e) => {
                privateLog("error", "Error: ", e);
            });

        setIsCreditsEditing(false);
    };

    return (
        <ThemeProvider theme={brightTheme}>
            <Box
                sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "left",
                    backgroundColor: "background.default",
                    p: 2,
                    borderRadius: 5,
                }}
            >
                {!isEditing ? (
                    <Box>
                        {isAdmin ? (
                            <Box
                                sx={{
                                    width: "100%",
                                    display: "flex",
                                    justifyContent: "flex-end",
                                }}
                            >
                                <IconButton onClick={() => setIsEditing(true)}>
                                    <Edit></Edit>
                                </IconButton>
                            </Box>
                        ) : null}
                        <Typography variant="h1" mb={1}>
                            {getLanguageText(event.title)}
                        </Typography>
                        <Typography variant="h2" mb={1}>
                            {getLanguageText(event.subtitle)}
                        </Typography>
                        {infoList ? (
                            <ThemeProvider theme={brightTheme}>
                                <Grid
                                    container
                                    sx={{
                                        border: "1px solid black",
                                        borderRadius: 2,
                                        backgroundColor: "background.default",
                                        width: "100%",
                                        m: 0,
                                        mt: 2,
                                        pb: 2,
                                        pr: 2,
                                    }}
                                    spacing={2}
                                >
                                    {infoList.map((item, key) => (
                                        <Grid item sm={6} key={key}>
                                            <Typography fontWeight={"bold"}>{getLanguageText(item.title)}:</Typography>
                                            <Typography>{getLanguageText(item.description)}</Typography>
                                        </Grid>
                                    ))}
                                </Grid>
                            </ThemeProvider>
                        ) : null}
                        <Typography mb={2} mt={2} whiteSpace={"pre-wrap"} textAlign={"justify"}>
                            {getLanguageText(description)}
                        </Typography>
                    </Box>
                ) : (
                    <EditInformation event={event} setIsEditing={setIsEditing}></EditInformation>
                )}
            </Box>
            {credits && getLanguageText(credits) ? (
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        backgroundColor: "background.default",
                        borderRadius: 5,
                        p: 2,
                        mt: 2,
                    }}
                >
                    {isAdmin ? (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "flex-end",
                            }}
                        >
                            {isCreditsEditing ? (
                                <IconButton>
                                    <Cancel onClick={() => setIsCreditsEditing(false)}></Cancel>
                                </IconButton>
                            ) : (
                                <IconButton onClick={() => setIsCreditsEditing(true)}>
                                    <Edit></Edit>
                                </IconButton>
                            )}
                        </Box>
                    ) : null}
                    <Typography variant="h2" mb={2} textAlign={"left"}>
                        {t("credits")}
                    </Typography>
                    {isCreditsEditing ? (
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                            {event.supportedLanguages ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                    }}
                                    gap={2}
                                >
                                    <Typography>Selected Language: </Typography>
                                    <Select value={language} onChange={handleLanguageChange}>
                                        {event.supportedLanguages.map((lang) => (
                                            <MenuItem key={lang} value={lang}>
                                                {lang}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </Box>
                            ) : null}
                            <TextField
                                label="Credits"
                                variant="outlined"
                                name="credits"
                                value={getLanguageText(credits)}
                                multiline
                                rows={12}
                                onChange={(e) =>
                                    setCredits({
                                        ...(credits as any),
                                        [language]: e.target.value,
                                    })
                                }
                            />
                            <Button onClick={handleSave} variant="outlined">
                                SAVE
                            </Button>
                        </Box>
                    ) : (
                        <Typography textAlign={"left"} whiteSpace={"pre-wrap"}>
                            {getLanguageText(credits)}
                        </Typography>
                    )}
                </Box>
            ) : null}
        </ThemeProvider>
    );
};
