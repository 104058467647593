import { Box, CircularProgress, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Footer } from "../eventRoom/frame/footer/Footer";
import { EventContext } from "../../contexts/EventContextProvider";
import { getLanguageText } from "../../languagesupport";
import { Mail } from "@mui/icons-material";
import { privateLog } from "../../dev/PrivateConsole";

const UnsubscribePromotional = () => {
    const [unsubscribeSucc, setUnsubscribeSucc] = useState(null);
    const { event } = useContext(EventContext);

    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("email");

    privateLog("log", "UnsubscribePromotional.tsx: ", event, email);

    useEffect(() => {
        if (event?.ticketProvider?.name === "artegrity" && event.ticketProvider.id && email) {
            fetch(process.env.REACT_APP_PILOT_BE_URL + `/v0/company/unsubscribeWithEventId?eventId=${event.ticketProvider.id}&email=${email}`, {
                method: "POST",
                headers: {
                    Accept: "application/hal+json",
                },
            })
                .then((response) => response.json())
                .then((data) => setUnsubscribeSucc(data));
        }
    }, [event, email]);

    if (unsubscribeSucc === null || !event) {
        return <CircularProgress></CircularProgress>;
    }

    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box sx={{ width: "100vw", height: "100vh", alignItems: "center", display: "flex", flexDirection: "column", mt: 4 }}>
                <Mail sx={{ width: 100, height: 100 }}></Mail>
                <Typography mt={4}>
                    {unsubscribeSucc
                        ? `You have successfully unsubscribed from promotional updates from ${getLanguageText(event.company.name)}`
                        : `We couldn't find your subscription for promotional updates from ${getLanguageText(event.company.name)}`}
                </Typography>
            </Box>
            <Box>
                <Footer></Footer>
            </Box>
        </Box>
    );
};

export default UnsubscribePromotional;
