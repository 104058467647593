import { Box, Button, CircularProgress, FormControl, TextField, ThemeProvider, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { EventContext } from "../../../../contexts/EventContextProvider";
import { httpsCallable } from "firebase/functions";
import { functions } from "../../../../firebase";
import { privateLog } from "../../../../dev/PrivateConsole";

const FreeTickets = () => {
    const { event, brightTheme } = useContext(EventContext);

    const [email, setEmail] = useState("");
    const [quantity, setQuantity] = useState("");
    const [loading, setLoading] = useState(false);
    const [result, setResult] = useState<boolean | undefined>(undefined);

    if (!event) {
        return <CircularProgress></CircularProgress>;
    }

    const handleSubmit = (_event: any) => {
        setLoading(true);
        setResult(undefined);
        _event.preventDefault();
        // Add your logic to handle the submission here
        privateLog("debug", "Sending Free Tickets for EventId: ", event.id);
        const sendFreeTicket = httpsCallable(functions, "pilotbe-sendFreeTickets");
        sendFreeTicket({
            eventId: event.id,
            quantity: quantity,
            email: email,
        })
            .then((result: any) => {
                privateLog("debug", "Was able to send Ticket: ", result.data);
                setResult(result.data);
                setLoading(false);
            })
            .catch((error) => {
                privateLog("error", error); // Handle the error
            });
    };

    return (
        <ThemeProvider theme={brightTheme}>
            <Box
                sx={{
                    p: 2,
                    borderRadius: 5,
                    backgroundColor: "background.default",
                }}
            >
                <Typography variant="h2">Send Free Tickets</Typography>
                <Box component="form" onSubmit={handleSubmit}>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <TextField label="Email" type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    </FormControl>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                        <TextField label="Quantity" type="number" value={quantity} onChange={(e) => setQuantity(e.target.value)} required />
                    </FormControl>

                    {!loading ? (
                        <Button sx={{ mt: 2 }} type="submit" variant="outlined" color="primary">
                            Send Free Tickets
                        </Button>
                    ) : (
                        <CircularProgress></CircularProgress>
                    )}
                    {result ? <Typography color={"green"}>Succesfully send Tickets!</Typography> : null}

                    {result === false ? <Typography color={"red"}>Could not deliver Tickets!</Typography> : null}
                </Box>
            </Box>
        </ThemeProvider>
    );
};

export default FreeTickets;
