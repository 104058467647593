import { AdsClick, Share } from "@mui/icons-material";
import { Box, Button, ThemeProvider, Typography } from "@mui/material";
import { httpsCallable } from "firebase/functions";
import { ReactNode, useEffect, useState } from "react";
import { functions } from "../../../../firebase";
import { artegrityBrightTheme } from "../../../../themes/artegrityBrightTheme";
import { ContentElement, Schedule } from "../../../../types/bll";
import EventData from "../../../../types/EventData";
import ContentElementAdminWrapperExpanded from "./ContentElementAdminWrapperExpanded";

type Props = {
    children: ReactNode;
    docId: string;
    eventId: string;
    event: EventData;
    shareUrl?: string;
    isBackstage: boolean;
    scheduled?: Schedule;
    contentElement: ContentElement;
    isMember?: boolean;
};

const ContentElementAdminWrapper = ({ children, docId, eventId, event, isBackstage, scheduled, contentElement, isMember }: Props) => {
    const [totalMediaInteraction, setTotalMediaInteraction] = useState<number>(0);
    const [totalMediaShare, setTotalMediaShare] = useState<number>(0);
    const [expand, setExpand] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            const getAnalyticsData = httpsCallable(functions, "postHogAnalytics-getPostHogAnalytics");

            let totalMediaInteraction = await getAnalyticsData({
                analyticsFunc: "allTimeMediaInteraction",
                eventId: eventId,
                docId: docId,
            });

            setTotalMediaInteraction(totalMediaInteraction.data as number);

            let totalMediaShare = await getAnalyticsData({
                analyticsFunc: "allTimeMediaShare",
                eventId: eventId,
                docId: docId,
            });
            setTotalMediaShare(totalMediaShare.data as number);
        };
        if (docId && eventId) {
            fetchData();
        }
    }, []);

    return (
        <Box
            sx={{
                backgroundColor: artegrityBrightTheme.palette.primary.main,
                borderRadius: 5,
            }}
        >
            {children}
            <ThemeProvider theme={artegrityBrightTheme}>
                <Box
                    sx={{
                        p: 2,
                    }}
                >
                    {expand ? (
                        <ContentElementAdminWrapperExpanded
                            contentElement={contentElement}
                            isBackstage={isBackstage}
                            event={event}
                            docId={docId}
                            scheduled={scheduled}
                            minimize={() => setExpand(false)}
                            memberView={isMember ? true : undefined}
                        ></ContentElementAdminWrapperExpanded>
                    ) : null}
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <Box
                            sx={{
                                flexDirection: "row",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <AdsClick sx={{ color: "primary.contrastText" }}></AdsClick>
                            <Typography sx={{ ml: 1, color: "primary.contrastText" }}>{totalMediaInteraction}</Typography>
                        </Box>
                        <Box>
                            <Button variant="outlined" onClick={() => setExpand((_expand) => !_expand)} sx={{ color: "primary.contrastText" }}>
                                {!expand ? "More Info" : "Minimize"}
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                flexDirection: "row",
                                display: "flex",
                                alignItems: "center",
                            }}
                        >
                            <Typography sx={{ color: "primary.contrastText" }}>{totalMediaShare}</Typography>
                            <Share sx={{ ml: 1, color: "primary.contrastText" }}></Share>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        </Box>
    );
};

export default ContentElementAdminWrapper;
