import { Box, Grid, ThemeProvider } from "@mui/material";
import "firebase/firestore";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { EventContext } from "../../contexts/EventContextProvider";
import { BackstageTicketCodeEvent, useAuth } from "../../contexts/UserContext";
import { privateLog } from "../../dev/PrivateConsole";
import { db } from "../../firebase";
import AddButton from "../../shared/AddButton";
import LoadingScreen from "../../shared/LoadingScreen";
import { ContentComponent, ContentElement } from "../../types/bll";
import LinkComponent from "./contentElements/LinkComponent";
import SoundCloudComponent from "./contentElements/SoundCloudComponent";
import VideoComponent from "./contentElements/VideoComponent";
import ContentElementAdminWrapper from "./contentElements/adminWrapper/ContentElementAdminWrapper";
import ImageViewer from "./contentElements/imageViewer/ImageViewer";
import LineUp from "./contentElements/lineUp/LineUp";
import TextComponent from "./contentElements/textViewer/TextComponent";
import ContentUploadContainer from "./contentUpload/ContentUploadContainer";

type Props = {
    isExclusive: boolean;
    member?: string;
    preview?: boolean;
};

export const Backstage = ({ isExclusive, member, preview }: Props) => {
    const { event, darkTheme, brightTheme } = React.useContext(EventContext);
    const [openUpload, setOpenUpload] = React.useState<boolean>(false);
    const [contentElements, setContentElements] = React.useState<ContentElement[]>([]);

    const searchParams = new URLSearchParams(window.location.search);
    const directLink = searchParams.get("contentElement");
    const { i18n } = useTranslation();

    const userContext = useAuth();

    const refs = useRef<{ [key: string]: React.RefObject<HTMLElement> }>({});

    React.useEffect(() => {
        if (event) {
            if (userContext?.backstagePassVerified && (userContext.backstagePassVerified as BackstageTicketCodeEvent)) {
                const diffInMinutesToStart = Math.round(
                    (new Date((userContext.backstagePassVerified as BackstageTicketCodeEvent).startDate).getTime() - Date.now()) / 60000
                );
                privateLog("info", `The play will start in ${diffInMinutesToStart} minutes from now.`);
            }
            const fetchData = async () => {
                try {
                    const postsCollection = collection(db, "Posts");
                    let conditions = [where("eventId", "==", event.id)];

                    // Filter by member if provided
                    if (member) {
                        conditions.push(where("members", "array-contains", member));
                    }

                    // Filter by visibility based on 'isExclusive' flag
                    if (!isExclusive) {
                        conditions.push(where("visibility", "in", ["public"]));
                    } else {
                        conditions.push(where("visibility", "in", ["public", "exclusive"]));
                    }
                    if (!member) {
                        conditions.push(where("members", "array-contains", event.id));
                    }

                    const q = query(postsCollection, ...conditions);
                    // Listen for real-time updates
                    const unsubscribe = onSnapshot(q, (querySnapshot) => {
                        let fetchedData: ContentElement[] = [];
                        privateLog("info", "fetching posts");
                        privateLog("info", "FetchContentElements");
                        querySnapshot.forEach((doc) => {
                            let contentElement = {
                                ...doc.data(),
                                docId: doc.id,
                                docRef: doc.ref,
                            } as ContentElement;
                            privateLog("info", "FetchContentElements", contentElement.docId, contentElement.component);

                            if (!contentElement.supportedLanguages || -1 !== contentElement.supportedLanguages.indexOf(i18n.language)) {
                                if (
                                    userContext &&
                                    (userContext.backstagePassVerified as BackstageTicketCodeEvent) &&
                                    contentElement.scheduled &&
                                    !userContext.isAdmin
                                ) {
                                    // If schedule is set, only add the content element if backstageTicketCodeEvent exists
                                    let backstageTicketCodeEvent = userContext.backstagePassVerified as BackstageTicketCodeEvent;
                                    const now = Date.now();
                                    const eventStart = new Date(backstageTicketCodeEvent.startDate).getTime();
                                    const eventEnd = new Date(backstageTicketCodeEvent.endDate).getTime();

                                    const scheduledStart =
                                        contentElement.scheduled.start !== undefined
                                            ? eventStart + contentElement.scheduled.start * 60000
                                            : undefined;
                                    const scheduledEnd =
                                        contentElement.scheduled.end !== undefined ? eventEnd + contentElement.scheduled.end * 60000 : undefined;

                                    if ((!scheduledStart || scheduledStart <= now) && (!scheduledEnd || now <= scheduledEnd)) {
                                        fetchedData.push(contentElement);
                                        privateLog(
                                            "debug",
                                            "scheduledStart: ",
                                            scheduledStart,
                                            "scheduledEnd: ",
                                            scheduledEnd,
                                            "id:",
                                            contentElement.docId
                                        );
                                    }
                                } else {
                                    // If schedule is not set, add the content element
                                    fetchedData.push(contentElement);
                                }
                            }
                        });

                        fetchedData.sort((a, b) => {
                            const positionA = a.position !== undefined ? a.position : 50;
                            const positionB = b.position !== undefined ? b.position : 50;
                            return positionA - positionB;
                        });

                        setContentElements(fetchedData);
                    });
                    return unsubscribe;
                } catch (error) {
                    privateLog("error", "Error fetching posts:", error);
                }
            };

            fetchData();
            const intervalId = setInterval(fetchData, 120000); // Call the function every 2 minutes

            // Clean up the interval on component unmount
            return () => clearInterval(intervalId);
        }
    }, [event, isExclusive, member, i18n.language]);

    useEffect(() => {
        if (directLink) {
            if (directLink && refs.current[directLink]?.current) {
                //privateLog("log", "got here");
                refs.current[directLink].current?.scrollIntoView({
                    behavior: "smooth",
                });
            }
        }
    }, [directLink, contentElements]);

    if (!event) {
        return <LoadingScreen></LoadingScreen>;
    }

    const getContentElement = (contentElement: ContentElement) => {
        const { component } = contentElement;
        const isOpen = contentElement.docId === directLink;
        // Create a ref for this element if it doesn't exist yet
        if (!refs.current[contentElement.docId]) {
            refs.current[contentElement.docId] = React.createRef<HTMLElement>();
        }
        let Component;

        privateLog("info", "ContentElement", contentElement.docId, contentElement.component);

        switch (component) {
            case ContentComponent.LINE_UP:
                Component = LineUp;
                break;
            case ContentComponent.SOUNDCLOUD:
                Component = SoundCloudComponent;
                break;
            case ContentComponent.VIDEO:
                Component = VideoComponent;
                break;
            case ContentComponent.LINK:
                Component = LinkComponent;
                break;
            case ContentComponent.IMAGES:
                Component = ImageViewer;
                break;
            case ContentComponent.TEXT_ELEMENT:
                Component = TextComponent;
                break;
            default:
                console.log(`Unknown component type: ${component}`);
                return null;
        }

        return (
            <Box ref={refs.current[contentElement.docId]}>
                <Component isOpen={isOpen} eventId={event.id} contentElement={contentElement} track={true} />
            </Box>
        );
    };

    return (
        <ThemeProvider theme={darkTheme}>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}
            >
                {userContext?.user && (userContext.isAdmin || userContext.member) && !preview ? (
                    <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                        <AddButton
                            onClick={() => {
                                setOpenUpload(true);
                            }}
                            text={"Upload Post"}
                            height={40}
                            width={40}
                        ></AddButton>
                        {openUpload ? <ContentUploadContainer targetMember={member} setOpenUpload={setOpenUpload}></ContentUploadContainer> : null}
                    </Box>
                ) : null}
                <Grid container spacing={2}>
                    {contentElements
                        .sort((a, b) => {
                            let b_value = b.pinned ? 1000 + b.position : b.position;
                            let a_value = a.pinned ? 1000 + a.position : a.position;
                            return b_value - a_value;
                        })
                        .map((item, key) => {
                            return (
                                <ThemeProvider theme={brightTheme} key={key}>
                                    <Grid item xs={12}>
                                        {(userContext?.isAdmin || (userContext.member && userContext.member === item.userId)) && !preview ? (
                                            <ContentElementAdminWrapper
                                                isMember={userContext.member ? true : undefined}
                                                contentElement={item}
                                                isBackstage={item.visibility === "exclusive"}
                                                docId={item.docId}
                                                eventId={event.id}
                                                event={event}
                                                scheduled={item.scheduled}
                                            >
                                                {getContentElement(item)}
                                            </ContentElementAdminWrapper>
                                        ) : (
                                            getContentElement(item)
                                        )}
                                    </Grid>
                                </ThemeProvider>
                            );
                        })}
                </Grid>
            </Box>
        </ThemeProvider>
    );
};
