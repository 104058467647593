import { ContentCopy } from "@mui/icons-material";
import { Box, ButtonBase, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { useState } from "react";
import i18n from "../../../../languagesupport";
import EventData from "../../../../types/EventData";
import { LanguageCode } from "../../../../types/dto";

type Props = { event: EventData; docId: string };

const CampaignLink = ({ event, docId }: Props) => {
    const [language, setLanguage] = useState(i18n.language);
    const [copySuccess, setCopySuccess] = useState("");
    const copyToClipboard = () => {
        navigator.clipboard
            .writeText(shareUrl.toString())
            .then(() => {
                // If the copy was successful
                setCopySuccess("Copied!");
                setTimeout(() => setCopySuccess(""), 2000); // Remove the message after 2 seconds
            })
            .catch(() => {
                // If the copy failed
                setCopySuccess("Failed to copy");
            });
    };

    const [shareUrl, setShareUrl] = useState<URL>(
        new URL(window.location.origin + `/v0/${event.id}eventRoom?` + "&language=" + language + "&contentElement=" + docId)
    );
    const handleChange = (event: SelectChangeEvent<string>) => {
        setLanguage(event.target.value as LanguageCode);
        setShareUrl((url) => {
            url.searchParams.set("language", event.target.value as LanguageCode);
            return url;
        });
    };
    return (
        <Box>
            <Typography variant="h6" mb={2}>
                Campaign Link
            </Typography>
            <Box
                sx={{
                    flexDirection: "row",
                    display: "flex",
                    alignItems: "center",
                }}
            >
                {event.supportedLanguages ? (
                    <Select value={language} onChange={handleChange} displayEmpty>
                        {event.supportedLanguages.map((lang) => (
                            <MenuItem key={lang} value={lang}>
                                {lang}
                            </MenuItem>
                        ))}
                    </Select>
                ) : null}
                <Typography
                    sx={{
                        ml: event.supportedLanguages ? 2 : 0,
                        mr: 2,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                    }}
                >
                    {shareUrl.toString()}
                </Typography>
                <ButtonBase onClick={() => copyToClipboard()}>
                    <ContentCopy sx={{ color: "text.primary" }}></ContentCopy>
                </ButtonBase>
                <Typography>{copySuccess}</Typography>
            </Box>
        </Box>
    );
};

export default CampaignLink;
