import { Settings } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

const Maintenance = () => {
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                textAlign: "center",
                p: 2,
            }}
        >
            <Settings
                sx={{
                    animation: "spin 20s linear infinite",
                    "@keyframes spin": {
                        from: { transform: "rotate(0deg)" },
                        to: { transform: "rotate(360deg)" },
                    },
                    width: 150,
                    height: 150,
                }}
            ></Settings>
            <Typography variant="h4" gutterBottom mt={4}>
                Under Maintenance
            </Typography>
            <Typography variant="body1">We're currently performing some maintenance. Please check back later.</Typography>
        </Box>
    );
};

export default Maintenance;
