import { ArrowBackIos } from "@mui/icons-material";
import { Box, Button, Container, Divider, ThemeProvider, Typography, useTheme } from "@mui/material";
import { useContext } from "react";
import { createPortal } from "react-dom";
import { EventContext } from "../../../../contexts/EventContextProvider";
import NoFrame from "../../../../dialog/NoFrame";
import { getLanguageText } from "../../../../languagesupport";
import { LanguageText, TextElement } from "../../../../types/bll";

type Props = {
    onClose: () => void;
    fallBackLanguage?: string;
};

const TextExpandedComponent = ({
    title,
    textHtml,
    imageUrl,
    shareUrl,
    description,
    displayDescriptionExpanded,
    date,
    onClose,
}: TextElement & Props) => {
    const { event, brightTheme, darkTheme } = useContext(EventContext);
    const theme = useTheme();

    if (displayDescriptionExpanded === undefined) {
        displayDescriptionExpanded = true;
    }

    function manipulateHTML(htmlString: string | LanguageText): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(getLanguageText(htmlString), "text/html");

        // Set the color of all text
        const allTextNodes = doc.querySelectorAll("p, h1, h2, h3, h4, h5, h6, li, span, a, figcaption");
        allTextNodes.forEach((node) => {
            if (node instanceof HTMLElement) {
                node.style.color = theme.palette.text.primary;
            }
        });

        // Set text alignment for all paragraphs
        const allParagraphs = doc.querySelectorAll("p");
        allParagraphs.forEach((p) => {
            if (p instanceof HTMLParagraphElement) {
                p.style.textAlign = "justify";
                if (window.getComputedStyle(p).fontStyle === "italic") {
                    p.style.textAlign = "center";
                }
            }
        });

        // Set max width for all images
        const allImages = doc.querySelectorAll("img");
        allImages.forEach((img) => {
            if (img instanceof HTMLImageElement) {
                img.style.maxWidth = "100%";
                img.style.height = "auto";
            }
        });

        return doc.body.innerHTML;
    }
    return createPortal(
        <ThemeProvider theme={darkTheme}>
            <NoFrame>
                <Box
                    sx={{
                        width: "100%",
                        height: "100%",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        justifyContent: "center",
                        overflowY: "auto",
                        backgroundColor: "background.default",
                        zIndex: 14000,
                    }}
                >
                    <Container maxWidth="sm">
                        <Box
                            sx={(theme) => ({
                                display: "flex",
                                flexDirection: "column",
                                position: "sticky",
                                top: 0,
                                zIndex: 4,
                                backgroundColor: "background.default",
                                height: 60,
                                justifyContent: "space-between",
                            })}
                        >
                            <Box></Box>
                            <Box
                                sx={{
                                    flexDirection: "row",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <Button onClick={onClose}>
                                    <ArrowBackIos></ArrowBackIos>
                                </Button>
                                <Typography>{event?.company.name}</Typography>
                                <Typography>{date}</Typography>
                            </Box>

                            <Divider sx={{ backgroundColor: "primary.main", color: null }}></Divider>
                        </Box>
                        <ThemeProvider theme={brightTheme}>
                            <Box
                                sx={{
                                    position: "sticky",
                                    top: 0,
                                    bg: "primary.main",
                                    backgroundColor: "background.default",
                                    borderRadius: 5,
                                    mt: 2,
                                    p: 2,
                                    textAlign: "center",
                                }}
                            >
                                <Typography mt={1} variant="h1">
                                    {getLanguageText(title)}
                                </Typography>
                                {description && displayDescriptionExpanded === true ? (
                                    <Typography
                                        textAlign={"left"}
                                        mt={1}
                                        sx={{
                                            hyphens: "auto",
                                            WebkitHyphens: "auto", // for Chrome and Safari
                                            MozHyphens: "auto", // for Firefox
                                            msHyphens: "auto", // for Microsoft browsers
                                        }}
                                    >
                                        {getLanguageText(description)}
                                    </Typography>
                                ) : null}
                                <Box
                                    component="div"
                                    sx={{
                                        mt: 2,
                                        WebkitHyphens: "auto", // for Chrome and Safari
                                        MozHyphens: "auto", // for Firefox
                                        msHyphens: "auto", // for Microsoft browsers
                                    }}
                                    dangerouslySetInnerHTML={{ __html: manipulateHTML(textHtml) }}
                                />
                            </Box>
                        </ThemeProvider>
                    </Container>
                </Box>
            </NoFrame>
        </ThemeProvider>,
        document.body,
    );
};

export default TextExpandedComponent;
