import { Box, ButtonBase, Divider, Link, Typography } from "@mui/material";
import i18n, { getLanguageText } from "../../../languagesupport";
import EventData from "../../../types/EventData";
import BuyTicketsButton from "../contentElements/BuyTicketsButton";
import PoweredBy from "./components/PoweredBy";
import { useAuth } from "../../../contexts/UserContext";
import { useTranslation } from "react-i18next";

type Props = {
    event: EventData;
    isBackstage: boolean;
    handleClose: () => void;
    switchEventRoom: () => void;
};

const HeaderMenu = ({ event, isBackstage, handleClose, switchEventRoom }: Props) => {
    const userContext = useAuth();

    const { t } = useTranslation();

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
                flex: 1,
            }}
        >
            <Box sx={{ p: 2, display: "flex", flexDirection: "row" }}>
                {event.supportedLanguages?.map((lan, key) => (
                    <Box key={key}>
                        <ButtonBase
                            sx={(theme) => ({
                                borderBottom: lan === i18n.language ? `2px solid ${theme.palette.primary.main}` : "",
                                mr: 1,
                                "&:hover": {
                                    borderBottom: `2px solid ${theme.palette.primary.main}`, // Add this line
                                },
                            })}
                            onClick={() => {
                                i18n.changeLanguage(lan);
                                handleClose();
                            }}
                        >
                            <Typography
                                sx={{
                                    fontWeight: lan === i18n.language ? "bold" : "normal",
                                    textDecorationLine: "underline",
                                    textDecorationLineColor: "text.primary",
                                    color: "text.primary",
                                }}
                            >
                                {i18n.getFixedT(lan)("languageName")}
                            </Typography>
                        </ButtonBase>
                    </Box>
                ))}
            </Box>
            <Divider
                sx={{
                    width: "100%",
                    backgroundColor: "text.primary",
                }}
            ></Divider>
            {event.onlyEventRoom ? null : (
                <Box sx={{ width: "100%" }}>
                    <Box
                        sx={{
                            p: 2,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            width: "100%",
                        }}
                    >
                        <Typography textAlign={"center"}>
                            {userContext.backstagePassVerified !== false && userContext.backstagePassVerified !== undefined
                                ? t("visiting the digital backstage", {
                                      event_title: getLanguageText(event.title),
                                  })
                                : t("visiting the event room", {
                                      event_title: getLanguageText(event.title),
                                  })}
                        </Typography>
                        {userContext.backstagePassVerified !== false && userContext.backstagePassVerified !== undefined ? (
                            <Link
                                onClick={() => {
                                    switchEventRoom();
                                }}
                            >
                                {isBackstage ? t("go to event room") : t("go to backstage")}
                            </Link>
                        ) : null}
                    </Box>
                    <Divider
                        sx={{
                            width: "100%",
                            backgroundColor: "text.primary",
                        }}
                    ></Divider>{" "}
                </Box>
            )}
            <Box
                sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <BuyTicketsButton event={event}></BuyTicketsButton>
                <Typography mt={1} textAlign={"center"}>
                    {t("go to original event page")}
                </Typography>
                <Link href={event.websiteUrl}>{getLanguageText(event.title)}</Link>
            </Box>
            <PoweredBy event={event} onClick={handleClose}></PoweredBy>
        </Box>
    );
};

export default HeaderMenu;
