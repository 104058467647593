import { ThemeProvider } from "@emotion/react";
import { Cancel } from "@mui/icons-material";
import { Box, Typography, ButtonBase, Divider, Button, CircularProgress } from "@mui/material";
import { useContext } from "react";
import { EventContext } from "../../../contexts/EventContextProvider";
import Dialog from "../../../dialog/Dialog";
import { UploadState } from "../../../types/bll";

type Props = {
    setOpenUpload: (isOpen: boolean) => void;
    children: React.ReactNode;
    uploadState: UploadState;
    goToNextPage: () => void;
    goBackPage: () => void;
    upload: () => void;
    uploading: boolean;
    error: string;
};

const ContentUploadContainerFrame = ({ setOpenUpload, children, uploadState, goToNextPage, goBackPage, upload, uploading, error }: Props) => {
    const { brightTheme } = useContext(EventContext);
    return (
        <Dialog sx={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
            <ThemeProvider theme={brightTheme}>
                <Box
                    flexDirection="column"
                    position="relative"
                    sx={{
                        backgroundColor: "background.default",
                        display: "flex",
                        flex: 1,
                        width: "100%",
                        minHeight: "100%",
                        p: 2,
                        pt: 0,
                        zIndex: 4,
                    }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                        }}
                    >
                        <Box
                            sx={{
                                position: "sticky",
                                top: 0,
                                pt: 2,
                                width: "100%",
                                backgroundColor: "background.default",
                                zIndex: 5,
                            }}
                        >
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    width: "100%",
                                    flex: 1,
                                }}
                            >
                                <Box></Box>
                                <Typography variant="h2" sx={{ alignSelf: "center" }}>
                                    Upload Content
                                </Typography>
                                <ButtonBase onClick={() => setOpenUpload(false)}>
                                    <Cancel color="primary"></Cancel>
                                </ButtonBase>
                            </Box>
                            <Divider sx={{ backgroundColor: "primary.main", mt: 1, mb: 1 }}></Divider>
                        </Box>
                        <Box sx={{ pb: 2, pt: 2, flex: 1 }}>{children}</Box>
                        <Box
                            sx={{
                                position: "sticky",
                                display: "flex",
                                flexDirection: "row",
                                bottom: 0,
                                pb: 2,
                                pt: 2,
                                backgroundColor: "background.default",
                                width: "100%",
                                zIndex: 5,
                            }}
                            gap={2}
                        >
                            {" "}
                            {uploadState !== UploadState.PROPERTIES ? (
                                <Button variant="contained" fullWidth={true} sx={{ marginTop: "auto", flex: 1 }} onClick={goBackPage}>
                                    back
                                </Button>
                            ) : null}
                            <Button
                                variant="contained"
                                fullWidth={true}
                                sx={{ marginTop: "auto", flex: 3 }}
                                onClick={() => (uploadState === UploadState.PREVIEW ? upload() : goToNextPage())}
                            >
                                {(() => {
                                    switch (uploadState) {
                                        case UploadState.PROPERTIES:
                                            return "next";
                                        case UploadState.COMPONENT:
                                            return "preview";
                                        case UploadState.PREVIEW:
                                            if (uploading) {
                                                return <CircularProgress></CircularProgress>;
                                            }
                                            return "upload";
                                    }
                                })()}
                            </Button>
                            <Typography sx={{ color: "red" }}>{error}</Typography>
                        </Box>
                    </Box>
                </Box>
            </ThemeProvider>
        </Dialog>
    );
};

export default ContentUploadContainerFrame;
