import { User, getIdToken, signInWithCustomToken } from "firebase/auth";
import { DocumentReference, doc, getDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import Swal from "sweetalert2";
import { privateLog } from "../dev/PrivateConsole";
import { auth, db, functions } from "../firebase";
import { BackstageTicketCodeEvent } from "./UserContext";

export const addTicketCodeToUser = async (ticketCode: string, eventId: string) => {
    const addTicketToUser = httpsCallable(functions, "ticketVerification-addTicketToUser");

    let res = await addTicketToUser({
        ticketCode: ticketCode,
        eventId: eventId,
    })
        .then((result) => {
            privateLog("log", "Result from adding Ticket: ", result);
            if (result.data as boolean) {
                if (result.data) {
                    privateLog("debug", "setting verified true");
                    if (auth.currentUser) {
                        privateLog("debug", "Refresh Token");
                        getIdToken(auth.currentUser, true);
                    }
                    return true;
                } else {
                    return false;
                }
            }
            privateLog("error", "Ticket could not be added to user unknown error");
            return false;
        })
        .catch((error) => {
            privateLog("error", error); // Handle the error
            return false;
        });
    return res;
};

export const askToAddTicketCodeToUser = async (user: User, ticketCode: string, eventId: string) => {
    //Check if user wants to connect his backstage pass
    //TODO: translation
    const result = await Swal.fire({
        title: "You are logged in with account: " + user.email,
        text: "Do you want to add your Backstage Pass to your acount?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes, add the BackstagePass!",
        cancelButtonText: "No, continue without Login.",
    });
    if (result.value) {
        //case valid
        privateLog("log", "sending code to backend: ", ticketCode);
        let success = await addTicketCodeToUser(ticketCode, eventId);
        if (success) {
            return true;
        }
    }
    if (result.dismiss) {
        privateLog("debug", "User does not want to connect TicketCode");
        return false;
    }
    return false;
};

type BackstagePassClaim = {
    eventId: string;
    ticketCode: string;
};

export const checkIfUserHasBackstagePassConnected = async (
    user: User,
    eventId: string,
    multiEvent?: boolean
): Promise<boolean | BackstageTicketCodeEvent> => {
    let token = await user.getIdTokenResult(true);
    let backstagePasses = token.claims.backstagePasses as BackstagePassClaim[];
    privateLog("info", "User BackstagePasses: ", backstagePasses);
    if (backstagePasses && backstagePasses.length > 0) {
        // Handle as BackstagePassClaim[]
        const matchingPass = (backstagePasses as BackstagePassClaim[]).find((pass) => pass.eventId === eventId);

        if (matchingPass) {
            if (multiEvent) {
                const docRef = doc(db, "events", eventId, "ticketCodes", matchingPass.ticketCode);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    let backstageTickeCodeEvent = {
                        ...data,
                        startDate: data.startDate.toDate(),
                        endDate: data.endDate.toDate(),
                    } as BackstageTicketCodeEvent;
                    if (!backstageTickeCodeEvent) {
                        privateLog("error", "Could not create BackstageTicketCodeEvent in correct format");
                        return false;
                    }
                    return backstageTickeCodeEvent;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        } else {
            return false;
        }
    }
    privateLog("info", "No Backstage Passes in the correct format");
    return false;
};

export const checkIfUserIsMember = async (user: User, members: DocumentReference[]): Promise<string | undefined> => {
    let token = await user.getIdTokenResult(true);
    let memberId = token.claims.memberId as string;
    if (memberId) {
        if (members.map((m) => m.id).includes(memberId)) {
            return memberId;
        }
        privateLog("debug", "user is not member: ", user.uid, " memberId: ", memberId, "for event");
    }
    privateLog("debug", "user is not member: ", user.uid);
    return undefined;
};

export const checkIfUserIsAdmin = async (user: User, eventId: string): Promise<boolean> => {
    let token = await user.getIdTokenResult(true);
    let admins = token.claims.admin;
    if (admins) {
        if ((admins as string[]).includes(eventId)) {
            privateLog("log", "user is admin");
            return true;
        }
    }
    privateLog("debug", "user is not admin uid: ", user.uid, " admins: ", admins);
    return false;
};

//UserContext
export const signInWithTicketCode = async (ticketCode: string, eventId: string): Promise<boolean> => {
    const ticketVerification = httpsCallable(functions, "ticketVerification-ticketVerification");
    privateLog("log", "EventId: ", eventId, " TicketCode: ", ticketCode);
    return await ticketVerification({
        ticketCode: ticketCode,
        eventId: eventId,
    })
        .then(async (result) => {
            if (result.data as string) {
                privateLog("log", "Token recevied for loggin: ", result.data);
                await signInWithCustomToken(auth, result.data as string);
                return true;
            } else {
                return false;
            }
        })
        .catch((error) => {
            privateLog("error", error); // Handle the error
            window.location.href = `/v0/${eventId}/eventRoom`;
            return false;
        });
};
