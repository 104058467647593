import React, { ReactNode } from "react";

interface FrameContextType {
    showFrame: boolean;
    setShowFrame: (show: boolean) => void;
}

// Initialize the context with undefined. It will be set by the provider.
export const FrameContext = React.createContext<FrameContextType | undefined>(undefined);

type Props = {
    children: ReactNode;
};
export const FrameContextProvider: React.FC<Props> = ({ children }: Props) => {
    const searchParams = new URLSearchParams(window.location.search);
    const directLink = searchParams.get("contentElement");
    const [showFrame, setShowFrame] = React.useState(directLink ? false : true);

    return <FrameContext.Provider value={{ showFrame, setShowFrame }}>{children}</FrameContext.Provider>;
};
