import { Box } from "@mui/material";
import React, { ReactNode } from "react";
import ReactDOM from "react-dom";

interface CustomDialogProps {
    children: ReactNode;
    sx?: any; // Accept the sx prop
}

const Dialog: React.FC<CustomDialogProps> = ({ children, sx }) => {
    return ReactDOM.createPortal(
        <Box
            sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                zIndex: 1000,
                backgroundColor: "rgba(0, 0, 0, 0.6)",
            }} // Spread the sx prop styles
        >
            <Box
                maxWidth="sm"
                sx={{
                    m: 2,
                    p: 0,
                    height: "90%",
                    width: "90%",
                    overflowY: "auto",
                    backgroundColor: "#fff",
                    borderRadius: 5,
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                }}
                onClick={(e) => e.stopPropagation()}
            >
                {children}
            </Box>
        </Box>,
        document.body
    );
};

export default Dialog;
