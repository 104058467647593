import { ConfirmationNumber } from "@mui/icons-material";
import { Box, CircularProgress, ThemeProvider, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { EventContext } from "../../../contexts/EventContextProvider";
import { getLanguageText } from "../../../languagesupport";
import { artegrityTheme } from "../../../themes/artegrityTheme";
import { useAuth } from "../../../contexts/UserContext";

const BackstagePass = () => {
    const { t } = useTranslation();
    const { event } = useContext(EventContext);
    const { darkTheme } = useContext(EventContext);
    const userContext = useAuth();

    if (userContext.backstagePassVerified === undefined) {
        return <CircularProgress></CircularProgress>;
    }

    if (userContext.backstagePassVerified === false) {
        return (
            <Box sx={{ p: 2 }}>
                <Typography>{t("connect your backstage pass")}</Typography>
            </Box>
        );
    }

    return (
        <Box sx={{ width: "100%" }} display="flex" justifyContent={"flex-start"} flexDirection={"column"}>
            <ThemeProvider theme={artegrityTheme}>
                <Box
                    sx={{
                        border: `1px solid ${darkTheme.palette.text.primary}`,
                        p: 1,
                        display: "flex",
                        alignItems: "center",
                        width: 250,
                        alignContent: "center",
                        justifyContent: "space-around",
                    }}
                >
                    <Typography sx={{ color: `${darkTheme.palette.text.primary}` }}>{t("using backstage pass")}</Typography>
                    <ConfirmationNumber color="primary" sx={{ ml: 1 }}></ConfirmationNumber>
                </Box>
            </ThemeProvider>
            <Typography mt={1} textAlign={"start"} variant="body2">
                {t("backstagepass benefits", {
                    event_title: event?.title ? getLanguageText(event.title) : "",
                })}
            </Typography>
        </Box>
    );
};

export default BackstagePass;
