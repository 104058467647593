import { createTheme, ThemeOptions } from "@mui/material/styles";

export const defaultThemeOptions: ThemeOptions = {
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1025,
            xl: 1920,
        },
    },
    typography: {
        fontFamily: "Montserrat, sans-serif",
        h1: {
            fontWeight: 600,
            fontSize: 35,
        },
        h2: {
            fontWeight: 600,
            fontSize: 20,
        },
        h3: {
            fontWeight: 600,
            fontSize: 25,
        },
        h5: {
            fontWeight: 600,
            fontSize: 25,
        },
        body1: { fontSize: 15 },
        body2: { fontSize: 12 },
    },
    components: {
        MuiTypography: {
            defaultProps: {
                color: "textPrimary",
            },
        },
    },
};

export const defaultTheme = createTheme(defaultThemeOptions);
