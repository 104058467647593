import { ThemeProvider, Box, TextField, Button, ButtonBase, Select, MenuItem } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import Dialog from "../../../../dialog/Dialog";
import { useContext, useState } from "react";
import NoFrame from "../../../../dialog/NoFrame";
import { EventContext } from "../../../../contexts/EventContextProvider";

interface StoryDialogProps {
    fileURL: string | undefined;
    onClose: () => void;
    uploadStory: (description: string, visibility: string) => void;
    closeStoriesUpload: () => void;
}

const StoriesUpload: React.FC<StoryDialogProps> = ({ fileURL, onClose, uploadStory, closeStoriesUpload }) => {
    const [description, setDescription] = useState("");
    const [selectedOption, setSelectedOption] = useState("eventRoom");

    const { darkTheme } = useContext(EventContext);

    return (
        <NoFrame>
            <Dialog sx={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
                <ThemeProvider theme={darkTheme}>
                    <Box
                        flexDirection="column"
                        position="relative"
                        sx={{ display: "flex", flex: 1, height: "100%", width: "100%", backgroundColor: "background.default" }}
                    >
                        {/* Media Container */}
                        <Box flex={7} width="100%" display="flex" overflow={"hidden"}>
                            {fileURL?.endsWith(".mp4") || fileURL?.endsWith(".webm") ? (
                                <Box component="video" controls sx={{ width: "100%", height: "100%", objectFit: "cover" }}>
                                    <source src={fileURL} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </Box>
                            ) : (
                                <Box
                                    component="img"
                                    src={fileURL}
                                    alt="Uploaded file preview"
                                    sx={{ width: "100%", height: "100%", objectFit: "cover" }}
                                />
                            )}

                            {/* Close button */}
                            <ButtonBase
                                onClick={closeStoriesUpload}
                                sx={{
                                    position: "absolute",
                                    top: 8,
                                    right: 8,
                                }}
                            >
                                <Cancel color="primary" sx={{ height: 30, width: 30 }} />
                            </ButtonBase>
                        </Box>

                        {/* Description and Upload Container */}
                        <Box flex={1} sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                            <TextField
                                label="Description"
                                variant="outlined"
                                fullWidth
                                value={description}
                                onChange={(event) => setDescription(event.target.value)}
                                sx={{ mb: 2 }}
                            />
                            <Select value={selectedOption} onChange={(event) => setSelectedOption(event.target.value)} sx={{ mb: 2 }}>
                                <MenuItem value="eventRoom">Event Room</MenuItem>
                                <MenuItem value="backstage">Backstage</MenuItem>
                            </Select>
                            <Button variant="contained" color="primary" onClick={() => uploadStory(description, selectedOption)}>
                                POST
                            </Button>
                        </Box>
                    </Box>
                </ThemeProvider>
            </Dialog>
        </NoFrame>
    );
};

export default StoriesUpload;
