import { AccountCircle, Close, ConfirmationNumber, Menu } from "@mui/icons-material";
import { Box, ButtonBase, Drawer, IconButton } from "@mui/material";
import { useContext, useState } from "react";
import { Helmet } from "react-helmet-async";
import { UserContext } from "../../../contexts/UserContext";
import { getLanguageText } from "../../../languagesupport";
import { CircularProgressArtegrity } from "../../../shared/CircularProgressArtegrity";
import { artegrityBrightTheme } from "../../../themes/artegrityBrightTheme";
import EventData from "../../../types/EventData";
import HeaderMenu from "./HeaderMenu";
import UserMenu from "./UserMenu";
import { privateLog } from "../../../dev/PrivateConsole";

type Props = {
    event: EventData;
    isBackstage: boolean | undefined;
    switchEventRoom: () => void;
    cookieValue: boolean | undefined;
    updateCookieValue: () => void;
};

const Header = ({ event, isBackstage, switchEventRoom, cookieValue, updateCookieValue }: Props) => {
    const userContext = useContext(UserContext);
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [accountOpen, setAccountOpen] = useState<boolean>(false);

    const handleMenuClick = () => {
        setAccountOpen(false);
        setMenuOpen(!menuOpen);
    };

    const handleAccountClick = () => {
        setMenuOpen(false);
        setAccountOpen(!accountOpen);
    };

    const getAccountIndicator = () => {
        let loggedIn = userContext && userContext.user && userContext.user.providerData.length > 0;

        let component;
        if (accountOpen) {
            component = <Close sx={{ width: 40, height: 40 }}></Close>;
        } else {
            privateLog("info", "Header isBackstage", isBackstage);
            if (isBackstage === undefined) {
                component = <CircularProgressArtegrity sx={{ height: 40, width: 40 }}></CircularProgressArtegrity>;
            } else if (!loggedIn && isBackstage === true) {
                component = (
                    <ConfirmationNumber
                        sx={{
                            ml: 1,
                            height: 30,
                            width: 30,
                            color: artegrityBrightTheme.palette.primary.main,
                        }}
                    />
                );
            } else if (loggedIn && isBackstage === true) {
                component = (
                    <AccountCircle
                        sx={{
                            width: 40,
                            height: 40,
                            color: "text.primary",
                            borderRadius: 20,
                            border: `2px solid ${artegrityBrightTheme.palette.primary.main}`,
                        }}
                    />
                );
            } else {
                component = (
                    <IconButton sx={{ width: 40, height: 40 }}>
                        <AccountCircle
                            sx={(theme) => ({
                                width: 40,
                                height: 40,
                                color: theme.palette.text.primary,
                            })}
                        ></AccountCircle>
                    </IconButton>
                );
            }
        }
        return (
            <ButtonBase sx={{ flex: 1, zIndex: 10000, justifyContent: "left" }} onClick={handleAccountClick}>
                {component}
            </ButtonBase>
        );
    };

    return (
        <Box sx={{ width: "100%" }}>
            <Helmet>
                <title>{event.company.name}</title>
                <meta name="description" content={getLanguageText(event.title)} />
                <link rel="icon" href={event.company.favicon} />
            </Helmet>
            <Box
                sx={(theme) => ({
                    flexDirection: "row",
                    flex: 1,
                    display: "flex",
                    p: 1,
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderBottom: `1px solid ${theme.palette.text.primary}`,
                })}
            >
                {getAccountIndicator()}
                <Box
                    sx={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Box sx={{ height: 30 }} component={"img"} src={event.company.icon}></Box>
                </Box>
                <ButtonBase sx={{ flex: 1, justifyContent: "right", zIndex: 10000 }} onClick={handleMenuClick}>
                    {menuOpen ? <Close sx={{ height: 40, width: 40 }} /> : <Menu sx={{ height: 40, width: 40 }} />}
                </ButtonBase>
            </Box>
            <Drawer
                ModalProps={{
                    keepMounted: true,
                    hideBackdrop: true,
                }}
                PaperProps={{
                    sx: {
                        position: "fixed",
                        top: "57px", // Total height of the header
                        width: {
                            xs: "100%", // Full width on extra small and small screens
                            md: "300px", // 300px width on medium and larger screens
                        },
                        height: "calc(100% - 57px)", // Subtract the height of the header from 100%
                        backgroundColor: "background.default", // Set the background color of the drawer
                        alignItems: "center",
                    },
                }}
                sx={{ width: "300px" }}
                variant="temporary"
                anchor="right"
                open={menuOpen}
                onClose={handleMenuClick}
            >
                <HeaderMenu
                    switchEventRoom={switchEventRoom}
                    isBackstage={isBackstage !== undefined ? isBackstage : false}
                    event={event}
                    handleClose={() => setMenuOpen(false)}
                ></HeaderMenu>
            </Drawer>
            <Drawer
                ModalProps={{ keepMounted: true, hideBackdrop: true }}
                PaperProps={{
                    sx: (theme) => ({
                        position: "fixed",
                        top: "57px", // Total height of the header
                        width: {
                            xs: "100%", // Full width on extra small and small screens
                            lg: "300px", // 300px width on medium and larger screens
                        },
                        height: "calc(100% - 57px)", // Subtract the height of the header from 100%
                        backgroundColor: "background.default", // Set the background color of the drawer
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }),
                }}
                anchor="left"
                open={accountOpen}
                onClose={handleAccountClick}
            >
                <UserMenu
                    cookieValue={cookieValue}
                    updateCookieValue={updateCookieValue}
                    handleClose={() => setAccountOpen(false)}
                    isBackstage={isBackstage !== undefined ? isBackstage : false}
                    event={event}
                ></UserMenu>
            </Drawer>
        </Box>
    );
};

export default Header;
