import { createTheme, ThemeOptions } from "@mui/material/styles";
import { defaultTheme } from "./defaultTheme";

export const defaultThemeOptions: ThemeOptions = {
    palette: {
        primary: {
            main: "#AD4594",
            contrastText: "#FFFFFF",
        },
        secondary: { main: "#FFFFFF" },
        background: {
            default: "#3B3B3B",
        },
        text: {
            primary: "#AD4594",
        },
    },
};

export const purpleTheme = createTheme(defaultTheme, defaultThemeOptions);
