import { IconName } from "../../../../types/bll";

export const footerColumns = [
    {
        name: "Artegrity",
        items: [
            {
                icon: IconName.LINKEDIN,
                name: "LinkedIn",
                link: "https://www.linkedin.com/company/artegrity/",
            },
            {
                icon: IconName.MAIL,
                name: "Mail",
                link: "mailto:ferdinand@artegrity.io",
            },
            {
                icon: IconName.INSTAGRAM,
                name: "Instagram",
                link: "https://www.instagram.com/artegrity.io/",
            },
        ],
    },
    {
        name: "Documents",
        items: [
            {
                icon: IconName.DOCUMENT,
                name: "Cookie Policy",
                link: "https://psychedelic-hardboard-ee4.notion.site/Cookie-Consent-fc71af5dbdcd4c9bbf0672c37668e2f6",
            },
            {
                icon: IconName.DOCUMENT,
                name: "Pricacy Policy",
                link: "https://psychedelic-hardboard-ee4.notion.site/Privacy-Policy-04dc15e23c2247e6a5fdbe985af94a8e",
            },
            {
                icon: IconName.DOCUMENT,
                name: "Terms and Contitions",
                link: "https://psychedelic-hardboard-ee4.notion.site/Terms-and-Conditions-1a7d2122d8ac41d99a0c4038bc11aa16",
            },
        ],
    },
    {
        name: "Help",
        items: [
            {
                icon: IconName.MAIL,
                name: "Mail",
                link: "mailto:ferdinand@artegrity.io",
            },
        ],
    },
];
